<template>
  <!-- Modal -->
  <div id="exampleModalCenter3" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLongTitle" class="modal-title">
            Update Branch Information:
          </h5>
        </div>
        <div class="p-12">
          <Form @submit="onSubmit">
            <span>Branch Name: </span>
            <Field
              v-model="name"
              name="name"
              rules="lol"
              placeholder="enter name"
              class="form-control"
            />
            <ErrorMessage name="name" />
            <br />
            <br />
            <span>Email: </span>
            <Field
              v-model="email"
              name="email"
              class="form-control"
              rules="lol|customEmail"
              placeholder="enter email"
            />
            <ErrorMessage name="email" />
            <br />
            <br />

            <span>Address1: </span>
            <Field
              v-model="address1"
              name="address1"
              class="form-control"
              rules="lol"
              placeholder="enter address1"
            />
            <ErrorMessage name="address1" />
            <br />
            <br />
            <span>Address2: </span>
            <Field
              v-model="address2"
              name="address2"
              class="form-control"
              placeholder="enter address2"
            />
            <ErrorMessage name="address2" />

            <br />
            <br />
            <span>City: </span>
            <Field
              v-model="city"
              name="city"
              class="form-control"
              rules="lol"
              placeholder="enter city"
            />
            <ErrorMessage name="city" />
            <br />
            <br />
            <span>Contact:</span>
            <Field
              v-model="contact"
              name="contact"
              class="form-control"
              rules="lol"
              placeholder="enter contact"
            />
            <ErrorMessage name="contact" />
            <br />
            <br />
            <input
              style="
                width: 75px;
                margin-left: 115px;
                margin-right: 10px;
                margin-top: 15px;
              "
              type="submit"
              class="btn btn-primary"
              data-dismiss="modal"
              value="Update"
            />
            <input
              style="width: 75px; margin-top: 15px"
              class="btn btn-secondary"
              value="Close"
              data-dismiss="modal"
              @click="close"
            />
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { numeric } from "@vee-validate/rules";

defineRule("lol", (value) => {
  if (value === null || value === undefined) {
    return true;
  } else if (!value || !value.length) {
    return false;
  }

  return true;
});
defineRule("customEmail", (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }

  // Check if email
  if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  ) {
    return false;
  }

  return true;
});
defineRule("numeric", numeric);

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: ["branchData"],
  data() {
    return {
      name: "",
      email: "",
      contact: "",
      address1: "",
      address2: "",
      city: "",
    };
  },
  watch: {
    branchData: function () {
      this.init();
    },
  },
  methods: {
    init() {
      this.name = this.branchData.name;
      this.email = this.branchData.email;
      this.contact = this.branchData.contact;
      this.address1 = this.branchData.address.address1;
      this.address2 = this.branchData.address.address2;
      this.city = this.branchData.address.city;
    },
    onSubmit() {
      let data = {
        name: this.name,
        email: this.email,
        contact: this.contact,
        address: {
          address1: this.address1,
          address2: this.address2,
          city: this.city,
        },
      };
      this.$emit("update-branch", data);
    },
  },
};
</script>

<style scoped>
input + span,
select + span {
  display: block;
  color: red;
}
</style>
