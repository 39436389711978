<template>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          New Patient Form
        </h5>
      </div>
      <div class="p-12">
        <Form @submit="onSubmit">
          <span>Name: </span>
          <Field
            name="name"
            v-model="name"
            rules="lol"
            placeholder="enter name"
            class="form-control"
          />
          <ErrorMessage name="name" />
          <br />
          <br />
          <label for="dob">Date of Birth: </label>
          <Litepicker
            v-model="dob"
            :options="{
              autoApply: false,
              showWeekNumbers: true,
              dropdowns: {
                minYear: 1990,
                maxYear: null,
                months: true,
                years: true
              }
            }"
            class="form-control"
          />

          <br />
          <br />

          <span>Email: </span>
          <Field
            name="email"
            v-model="email"
            class="form-control"
            rules="lol|customEmail"
            placeholder="enter email address"
          />
          <ErrorMessage name="email" />
          <br />
          <br />

          <br />
          <input
            style="width: 75px; margin-left: 115px; margin-right: 10px; margin-top: 15px"
            type="submit"
            class="btn btn-primary"
            value="Submit"
          />
          <input
            style="width: 75px; margin-top: 15px"
            class="btn btn-secondary"
            value="Close"
            @click="close"
          />
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { numeric } from "@vee-validate/rules";

defineRule("lol", value => {
  if (value === null || value === undefined) {
    return true;
  } else if (!value || !value.length) {
    return false;
  }

  return true;
});

defineRule("customEmail", value => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }

  // Check if email
  if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  ) {
    return false;
  }

  return true;
});
defineRule("numeric", numeric);

export default {
  emits: ["add-patient", "toggle"],
  data() {
    return {
      name: "",
      dob: "",
      contact: "",
      email: "",
      selected: ""
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage
  },
  methods: {
    close() {
      this.$emit("toggle");
    },
    onSubmit() {
      let data = {
        name: this.name,
        dob: this.dob
      };
      this.$emit("add-patient", data);
      this.close();
    }
  }
};
</script>

<style scoped>
input + span,
select + span {
  display: block;
  color: red;
}
</style>
