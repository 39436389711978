import _ from "lodash";
import dayjs from "dayjs";

const fakers = {
  fakeUsers() {
    const users = [
      { name: "Richard Alexander" },
      { name: "Erik Andersson" },
      { name: "Jacqueline Andrews" },
      { name: "Anthony Arrand" },
      { name: "Anna Bedwell" },
      { name: "Amanda Birks" },
      { name: "Peter Bradley" },
      { name: "David Broadhead" },
      { name: "George Buchanan" },
      { name: "Alan Cleary" },
      { name: "Trevor Collar" },
      { name: "Alan Connell" },
      { name: "David Connon" },
      { name: "Jonathan Crossley" },
      { name: "Susan Curry" },
      { name: "Madeleine Dalrymple" },
      { name: "Daniel Driscoll" },
      { name: "Jacqueline Dunn" },
      { name: "Linda Fisher" },
      { name: "Ross Fisher" },
      { name: "Timothy Franklin" },
      { name: "Sharon Graham" },
      { name: "Jennifer Graves" },
      { name: "Irene Green" },
      { name: "Sean Guy" },
      { name: "Ian Hedges" },
      { name: "Sarah Hollywood" },
      { name: "Justine Howlett" },
      { name: "Brenda Huddleston" },
      { name: "Harry Inn" },
      { name: "Ben Jackson" },
      { name: "Aaron Makowski" },
      { name: "Helen Maw" },
      { name: "John McConville" },
      { name: "John Miller" },
      { name: "Stephen Pearce" },
      { name: "Gillian Penwarden" },
      { name: "Khwaja Qureshi" },
      { name: "David Reid" },
      { name: "Christopher Riley" },
      { name: "Alen Roberts" },
      { name: "Trevor Sexton" },
      { name: "Nitesh Shah" },
      { name: "Philip Simons" },
      { name: "Barry Stewart" },
      { name: "Laraine Stokes" },
      { name: "Robert Trewin" },
      { name: "John Urquhart" },
      { name: "Graeme Wallace" },
      { name: "Justin Whittaker" }
    ];
    function randomDate() {
      var startDate = new Date(1950, 1, 1).getTime();
      var endDate = new Date(2020, 1, 1).getTime();
      var spaces = endDate - startDate;
      var timestamp = Math.round(Math.random() * spaces);
      timestamp += startDate;
      return new Date(timestamp);
    }
    function formatDate(date) {
      var month = randomDate().getMonth();
      var day = randomDate().getDate();
      month += 1;
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;

      return String(date.getFullYear()) + "-" + month + "-" + day;
    }

    return users.map((user, idx) => {
      const bloodGroups = ["A+", "A-", "AB+", "AB-", "B+", "B-", "O+", "O-"];
      const jobs = ["Category 1", "Category 2", "Category 3", "Category 4"];
      return {
        name: user.name,
        dob: formatDate(randomDate()),
        email: _.toLower(_.replace(user.name, / /g, "") + "@mazecare.com")
      };
    });
  },
  fakeUserDetails() {
    const json = require("./fakeAddress.json");
    const users = [
      { name: "Richard Alexander" },
      { name: "Erik Andersson" },
      { name: "Jacqueline Andrews" },
      { name: "Anthony Arrand" },
      { name: "Anna Bedwell" },
      { name: "Amanda Birks" },
      { name: "Peter Bradley" },
      { name: "David Broadhead" },
      { name: "George Buchanan" },
      { name: "Alan Cleary" },
      { name: "Trevor Collar" },
      { name: "Alan Connell" },
      { name: "David Connon" },
      { name: "Jonathan Crossley" },
      { name: "Susan Curry" },
      { name: "Madeleine Dalrymple" },
      { name: "Daniel Driscoll" },
      { name: "Jacqueline Dunn" },
      { name: "Linda Fisher" },
      { name: "Ross Fisher" },
      { name: "Timothy Franklin" },
      { name: "Sharon Graham" },
      { name: "Jennifer Graves" },
      { name: "Irene Green" },
      { name: "Sean Guy" },
      { name: "Ian Hedges" },
      { name: "Sarah Hollywood" },
      { name: "Justine Howlett" },
      { name: "Brenda Huddleston" },
      { name: "Harry Inn" },
      { name: "Ben Jackson" },
      { name: "Aaron Makowski" },
      { name: "Helen Maw" },
      { name: "John McConville" },
      { name: "John Miller" },
      { name: "Stephen Pearce" },
      { name: "Gillian Penwarden" },
      { name: "Khwaja Qureshi" },
      { name: "David Reid" },
      { name: "Christopher Riley" },
      { name: "Alen Roberts" },
      { name: "Trevor Sexton" },
      { name: "Nitesh Shah" },
      { name: "Philip Simons" },
      { name: "Barry Stewart" },
      { name: "Laraine Stokes" },
      { name: "Robert Trewin" },
      { name: "John Urquhart" },
      { name: "Graeme Wallace" },
      { name: "Justin Whittaker" }
    ];
    const bloodGroups = ["A+", "A-", "AB+", "AB-", "B+", "B-", "O+", "O-"];
    const gender = ["Male", "Female"];
    return users.map((user, idx) => {
      return {
        name: user.name,
        bloodGroups: _.shuffle(bloodGroups)[0],
        contact: "+852 " + Math.floor(Math.random() * 100000000),
        address: _.shuffle(json.addresses)[0],
        gender: _.shuffle(gender)[0]
      };
    });
  },
  fakeDoctors() {
    const users = [
      { name: "Richard Alexander" },
      { name: "Erik Andersson" },
      { name: "Jacqueline Andrews" },
      { name: "Anthony Arrand" },
      { name: "Anna Bedwell" },
      { name: "Amanda Birks" },
      { name: "Peter Bradley" },
      { name: "David Broadhead" },
      { name: "George Buchanan" },
      { name: "Alan Cleary" },
      { name: "Trevor Collar" },
      { name: "Alan Connell" },
      { name: "David Connon" },
      { name: "Jonathan Crossley" },
      { name: "Susan Curry" },
      { name: "Madeleine Dalrymple" },
      { name: "Daniel Driscoll" },
      { name: "Jacqueline Dunn" },
      { name: "Linda Fisher" },
      { name: "Ross Fisher" },
      { name: "Timothy Franklin" },
      { name: "Sharon Graham" },
      { name: "Jennifer Graves" },
      { name: "Irene Green" },
      { name: "Sean Guy" },
      { name: "Ian Hedges" },
      { name: "Sarah Hollywood" },
      { name: "Justine Howlett" },
      { name: "Brenda Huddleston" },
      { name: "Harry Inn" },
      { name: "Ben Jackson" },
      { name: "Aaron Makowski" },
      { name: "Helen Maw" },
      { name: "John McConville" },
      { name: "John Miller" },
      { name: "Stephen Pearce" },
      { name: "Gillian Penwarden" },
      { name: "Khwaja Qureshi" },
      { name: "David Reid" },
      { name: "Christopher Riley" },
      { name: "Alen Roberts" },
      { name: "Trevor Sexton" },
      { name: "Nitesh Shah" },
      { name: "Philip Simons" },
      { name: "Barry Stewart" },
      { name: "Laraine Stokes" },
      { name: "Robert Trewin" },
      { name: "John Urquhart" },
      { name: "Graeme Wallace" },
      { name: "Justin Whittaker" }
    ];
    function randomDate() {
      var startDate = new Date(1950, 1, 1).getTime();
      var endDate = new Date(2020, 1, 1).getTime();
      var spaces = endDate - startDate;
      var timestamp = Math.round(Math.random() * spaces);
      timestamp += startDate;
      return new Date(timestamp);
    }
    function formatDate(date) {
      var month = randomDate().getMonth();
      var day = randomDate().getDate();

      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;

      return String(date.getFullYear()) + "-" + month + "-" + day;
    }

    return users.map((user, idx) => {
      const bloodGroups = ["A+", "A-", "AB+", "AB-", "B+", "B-", "O+", "O-"];
      const jobs = ["Category 1", "Category 2", "Category 3", "Category 4"];
      return {
        name: "Dr. " + user.name,
        dob: formatDate(randomDate()),
        email: _.toLower(_.replace(user.name, / /g, "") + "@mazecare.com")
      };
    });
  },
  fakePhotos() {
    const photos = [];
    for (let i = 0; i < 15; i++) {
      photos[photos.length] = "profile-" + _.random(1, 15) + ".jpg";
    }
    return _.sampleSize(photos, 10);
  },
  fakePatientID() {
    const ID = ["M-" + _.random(10000, 15000)];
    return _.sampleSize(ID);
  },
  fakeImages() {
    const images = [];
    for (let i = 0; i < 15; i++) {
      images[images.length] = "preview-" + _.random(1, 15) + ".jpg";
    }
    return _.sampleSize(images, 10);
  },
  fakeDates() {
    const dates = [];
    for (let i = 0; i < 5; i++) {
      dates[dates.length] = dayjs
        .unix(_.random(1586584776897, 1672333200000) / 1000)
        .format("DD MMMM YYYY");
    }
    return _.sampleSize(dates, 3);
  },
  fakeTimes() {
    const times = [
      "01:10 PM",
      "05:09 AM",
      "06:05 AM",
      "03:20 PM",
      "04:50 AM",
      "07:00 PM"
    ];
    return _.sampleSize(times, 3);
  },
  fakeFormattedTimes() {
    const times = [
      _.random(10, 60) + " seconds ago",
      _.random(10, 60) + " minutes ago",
      _.random(10, 24) + " hours ago",
      _.random(10, 20) + " days ago",
      _.random(10, 12) + " months ago"
    ];
    return _.sampleSize(times, 3);
  },
  fakeTotals() {
    return _.shuffle([_.random(20, 220), _.random(20, 120), _.random(20, 50)]);
  },
  fakeTrueFalse() {
    return _.sampleSize([0, 1, 1], 1);
  },
  fakeStocks() {
    return _.shuffle([_.random(50, 220), _.random(50, 120), _.random(50, 50)]);
  },

  fakeRooms() {
    const name = [
      "Surgery",
      "Waiting Room",
      "Suite",
      "ICU",
      "X-Ray",
      "Emergency Room",
      "CT-Scan",
      "Maintenance Area",
      "Canteen",
      "Meeting Room"
    ];

    const number = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
    const capacity = [
      "10",
      "20",
      "40",
      "30",
      "60",
      "100",
      "30",
      "60",
      "100",
      "10"
    ];
    const status = [1, 1, 0, 1, 0, 1, 1, 0, 1, 0];

    const branch = [
      { id: 1, name: "Nolan and Rath" },
      { id: 1, name: "Nolan and Rath" },
      { id: 1, name: "Nolan and Rath" },
      { id: 2, name: "Kunze Inc" },
      { id: 3, name: "Collier and Sons" },
      { id: 1, name: "Nolan and Rath" },
      { id: 2, name: "Kunze Inc" },
      { id: 3, name: "Collier and Sons" },
      { id: 1, name: "Nolan and Rath" },
      { id: 2, name: "Kunze Inc" }
    ];

    return new Array(10).fill(0).map((data, idx) => {
      return {
        id: idx,
        name: `Room - ${number[idx % number.length]}`,
        image: "food-beverage-1.jpg",
        category: name[idx % name.length],
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        capacity: capacity[idx % capacity.length],
        status: status[idx % status.length],
        branch: branch[idx % branch.length]
      };
    });
  },
  fakeEquipment() {
    const name = [
      "X-Ray processor",
      "Microscope",
      "Pulse oximeter",
      "Patient Trolley",
      "Syringe pump",
      "Defibrillator",
      "Stethoscope",
      "Oxygen Cylinder",
      "Retinoscope",
      "ENT Diagnostic Set"
    ];
    const jobs = ["Category 1", "Category 2", "Category 3", "Category 4"];

    return new Array(10).fill(0).map((data, idx) => {
      return {
        id: idx + 1,
        name: name[idx % name.length],
        image: "food-beverage-1.jpg",
        category: jobs[idx % jobs.length],
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        remaining_stock: Math.floor(Math.random() * 51),
        status: Math.floor(Math.random() * 2)
      };
    });
  },
  fakeProducts() {
    const products = [
      {
        id: "1",
        name: "Altace (ramipril)",
        image: "food-beverage-1.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "2",
        name: "Amaryl (glimepiride)",
        image: "food-beverage-2.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "3",
        name: "Ambien (zolpidem)",
        image: "food-beverage-3.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "4",
        name: "Ativan (lorazepam)",
        image: "food-beverage-4.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "5",
        name: "Calan SR (verapamil SR)",
        image: "food-beverage-5.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "6",
        name: "Cardizem (diltiazem ER)",
        image: "food-beverage-6.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "7",
        name: "Celexa (citalopram)",
        image: "food-beverage-7.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "8",
        name: "Coumadin (warfarin)",
        image: "food-beverage-8.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "9",
        name: "Diabeta (glyburide)",
        image: "food-beverage-9.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "10",
        name: "Dilantin (phenytoin)",
        image: "food-beverage-10.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "11",
        name: "Effexor (venlafaxine)",
        image: "food-beverage-11.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "12",
        name: "Flonase (fluticasone)",
        image: "food-beverage-12.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "13",
        name: "Fosamax (alendronate)",
        image: "food-beverage-13.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "14",
        name: "Glucophage (metformin)",
        image: "food-beverage-14.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "15",
        name: "Glucotrol (glipizide)",
        image: "food-beverage-15.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "16",
        name: "Hytrin (terazosin)",
        image: "food-beverage-16.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "17",
        name: "Imitrex (sumatriptan)",
        image: "food-beverage-17.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "18",
        name: "Lasix (furosemide)",
        image: "food-beverage-18.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "19",
        name: "Lopid (gemfibrozil)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "20",
        name: "Mevacor (lovastatin)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "21",
        name: "Micronase (glyburide)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "22",
        name: "Norvasc (amlodipine)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "23",
        name: "Paxil (paroxetine)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "24",
        name: "Pepcid (famotidine)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "25",
        name: "Pravachol (pravastatin)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "26",
        name: "Prilosec (omeprazole)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "27",
        name: "Prinivil (lisinopril)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "28",
        name: "Procardia (nifedipine)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "29",
        name: "Procardia XL (nifedipine XL)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "30",
        name: "Proventil (albuterol)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "31",
        name: "Prozac (fluoxetine)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "32",
        name: "Retin-A (tretinoin)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "33",
        name: "Risperdal (risperidone)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "34",
        name: "Sonata (zaleplon)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "35",
        name: "Synthroid (levothyroxine)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "36",
        name: "Timoptic (timolol)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "37",
        name: "Toprol XL (metoprolol ext-release)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "38",
        name: "Tylenol with codeine (acetaminophen w/codeine)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "39",
        name: "Ultram (tramadol)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "40",
        name: "Vasotec (enalapril)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "41",
        name: "Ventolin (albuterol)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "42",
        name: "Wellbutrin (bupropion)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "43",
        name: "Wellbutrin XL (bupropion ext-release)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "44",
        name: "Xanax (alprazolam)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "45",
        name:
          "Yasmin (drospirenone/ethinyl estradiol; branded generic called Ocella)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "46",
        name: "Zantac (ranitidine)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "47",
        name: "Zestril (lisinopril)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "48",
        name: "Zocor (simvastatin)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "49",
        name: "Zoloft (sertraline)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "50",
        name: "Zovirax (acyclovir)",
        image: "food-beverage-19.jpg",
        category: "Category 1",
        remaining_stock: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      }
    ];
    return _.shuffle(products);
  },
  fakeTests() {
    const tests = [
      {
        id: "1",
        name: "Complete Blood Count",
        image: "food-beverage-1.jpg",
        category: "Category 1",
        priority: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "2",
        name: "Endocrynology",
        image: "food-beverage-2.jpg",
        category: "Category 1",
        priority: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 0
      },
      {
        id: "3",
        name: "Endocrynology",
        image: "food-beverage-3.jpg",
        category: "Category 1",
        priority: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 0
      },
      {
        id: "4",
        name: "Renal Function Test",
        image: "food-beverage-4.jpg",
        category: "Category 1",
        priority: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "5",
        name: "Complete Blood Count",
        image: "food-beverage-5.jpg",
        category: "Category 1",
        priority: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "6",
        name: "Ziehl Neelsen Bacilloscopy",
        image: "food-beverage-6.jpg",
        category: "Category 1",
        priority: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "7",
        name: "Complete Blood Count",
        image: "food-beverage-7.jpg",
        category: "Category 1",
        priority: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 0
      },
      {
        id: "8",
        name: "Haematology",
        image: "food-beverage-8.jpg",
        category: "Category 1",
        priority: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "9",
        name: "Complete Blood Count",
        image: "food-beverage-9.jpg",
        category: "Category 1",
        priority: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 0
      },
      {
        id: "10",
        name: "Complete Blood Count",
        image: "food-beverage-10.jpg",
        category: "Category 1",
        priority: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "11",
        name: "Complete Blood Count",
        image: "food-beverage-11.jpg",
        category: "Category 1",
        priority: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "12",
        name: "Complete Blood Count",
        image: "food-beverage-12.jpg",
        category: "Category 1",
        priority: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 0
      },
      {
        id: "13",
        name: "Liver Function Test",
        image: "food-beverage-13.jpg",
        category: "Category 1",
        priority: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 0
      },
      {
        id: "14",
        name: "Renal Function Test",
        image: "food-beverage-14.jpg",
        category: "Category 1",
        priority: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "15",
        name: "Chagas Xenodiagnosis",
        image: "food-beverage-15.jpg",
        category: "Category 1",
        priority: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "16",
        name: "Dengue Prnt",
        image: "food-beverage-16.jpg",
        category: "Category 1",
        priority: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 0
      },
      {
        id: "17",
        name: "Stool Examination",
        image: "food-beverage-17.jpg",
        category: "Category 1",
        priority: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 1
      },
      {
        id: "18",
        name: "Complete Blood Count",
        image: "food-beverage-18.jpg",
        category: "Category 1",
        priority: 6,
        images: ["preview-10.jpg", "preview-4.jpg", "preview-5.jpg"],
        status: 0
      }
    ];
    return _.shuffle(tests);
  },
  fakeNews() {
    const news = [
      {
        title: "Desktop publishing software like Aldus PageMaker",
        superShortContent: _.truncate(
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          {
            length: 30,
            omission: ""
          }
        ),
        shortContent: _.truncate(
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          {
            length: 150,
            omission: ""
          }
        ),
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
      },
      {
        title: "Dummy text of the printing and typesetting industry",
        superShortContent: _.truncate(
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
          {
            length: 30,
            omission: ""
          }
        ),
        shortContent: _.truncate(
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
          {
            length: 150,
            omission: ""
          }
        ),
        content:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
      },
      {
        title: "Popularised in the 1960s with the release of Letraset",
        superShortContent: _.truncate(
          'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.',
          {
            length: 30,
            omission: ""
          }
        ),
        shortContent: _.truncate(
          'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.',
          {
            length: 150,
            omission: ""
          }
        ),
        content:
          'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.'
      },
      {
        title: "200 Latin words, combined with a handful of model sentences",
        superShortContent: _.truncate(
          "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
          {
            length: 50,
            omission: ""
          }
        ),
        shortContent: _.truncate(
          "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
          {
            length: 150,
            omission: ""
          }
        ),
        content:
          "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
      }
    ];
    return _.shuffle(news);
  },
  fakeFiles() {
    const files = [
      { fileName: "Medical Report.txt", type: "TXT", size: "20 MB" },
      { fileName: "Consultations", type: "Empty Folder", size: "120 MB" },
      { fileName: fakers.fakeImages()[0], type: "Image", size: "1.2 MB" },
      { fileName: "Repository", type: "Folder", size: "20 KB" },
      { fileName: "Resources.txt", type: "TXT", size: "2.2 MB" },
      { fileName: "Notes", type: "TXT", size: "1 KB" },
      { fileName: "Prescriptions", type: "Folder", size: "112 GB" },
      { fileName: "Documentation", type: "Empty Folder", size: "4 MB" },
      { fileName: fakers.fakeImages()[0], type: "Image", size: "1.4 MB" },
      { fileName: fakers.fakeImages()[0], type: "Image", size: "1 MB" }
    ];
    return _.shuffle(files);
  },
  fakeJobs() {
    const jobs = ["Category 1", "Category 2", "Category 3", "Category 4"];
    return _.shuffle(jobs);
  },
  fakeSpecialization() {
    const specialization = [
      "Dermatologist",
      "Ophthalmologist",
      "Gynaecologist",
      "Surgeon"
    ];
    return _.shuffle(specialization);
  },
  fakeNotificationCount() {
    return _.random(1, 7);
  },
  fakeMedicalHistory() {
    const history = [
      { illness: "Anemia", diagnosed: "June 2020" },
      { illness: "Anxiety", diagnosed: "May 2005" },
      { illness: "Arthritis", diagnosed: "April 2018" },
      { illness: "Clotting", diagnosed: "March 1995" },
      { illness: "Depression", diagnosed: "April 1997" },
      { illness: "Diabetes", diagnosed: "May 2000" },
      { illness: "Hepatitis", diagnosed: "Jan 2000" },
      { illness: "Osteoporosis", diagnosed: "March 1995" },
      { illness: "Thyroid Disease", diagnosed: "Dec 2005" },
      { illness: "Ulcers", diagnosed: "June 2021" }
    ];
    return [...history].sort(() => 0.5 - Math.random()).slice(0, 5);
  },
  fakeDoctorConsulted() {
    const doctor = [
      {
        doc: "Dr. John",
        spec: "Paediatrician",
        date: "March 1995",
        reason: "fever",
        medicine: "paracetamol"
      },
      {
        doc: "Dr. Joe",
        spec: "General Practitioner",
        date: "April 1997",
        reason: "cough",
        medicine: "cough cyrup"
      },
      {
        doc: "Dr. Ben",
        spec: "Surgeon",
        date: "May 2000",
        reason: "nose bleeding",
        medicine: "pain killers"
      }
    ];
    return [...doctor].sort(() => 0.5 - Math.random()).slice(0, 3);
  },
  fakeFoodAllergy() {
    const foodAllergy = [
      {
        allergen: "Nuts",
        certainity: "Certain",
        manifestation: "Hives and Ichiness"
      },
      {
        allergen: "Wheat",
        certainity: "Suspected",
        manifestation: "High Blood Pressure"
      },
      {
        allergen: "Egg",
        certainity: "Suspected",
        manifestation: "Nausea"
      }
    ];
    return [...foodAllergy].sort(() => 0.5 - Math.random()).slice(0, 3);
  },
  fakeDrugAllergy() {
    const drugAllergy = [
      {
        allergen: "Paracetamol",
        certainity: "Certain",
        manifestation: "Dizziness"
      },
      {
        allergen: "Asprin",
        certainity: "Suspected",
        manifestation: "High fever"
      },
      {
        allergen: "Allegra",
        certainity: "Suspected",
        manifestation: "Stomach ache"
      }
    ];
    return [...drugAllergy].sort(() => 0.5 - Math.random()).slice(0, 3);
  },
  fakeImmunizationRecords() {
    const records = [
      { disease: "Flu", type: "Influenza", date: "March 1995" },
      { disease: "Measles", type: "MMR", date: "May 1998" },
      { disease: "Polio", type: "IPV", date: "Jan 2000" },
      { disease: "Tetanus", type: "DTaP", date: "March 1995" },
      { disease: "Covid", type: "Pfizer", date: "June 2021" },
      { disease: "Hepatitis", type: "Hepatitis A vaccine", date: "Dec 2005" }
    ];
    return [...records].sort(() => 0.5 - Math.random()).slice(0, 3);
  },
  fakeRegimen() {
    const regimen = [
      { name: "Digoxin", dosage: "0.125mg", frequency: "Once Daily" },
      { name: "Enalpril", dosage: "20mg", frequency: "Twice Daily" },
      { name: "Lasix", dosage: "40mg", frequency: "Every Other Day" },
      { name: "Kcl", dosage: "20mg", frequency: "Once Daily" },
      { name: "Tylenol", dosage: "2 tabs", frequency: "Twice daily" }
    ];
    return [...regimen].sort(() => 0.5 - Math.random()).slice(0, 3);
  },
  fakePatientProfile() {
    const history = [
      {
        id: "M76661",
        name: "John",
        status: "active",
        dob: "13 May 2000",
        gender: "Male",
        address: "4 Park Street, Hong Kong",
        contact: "98665410",
        email: "john@mazecare.com",
        emergency: "Billy",
        relation: "father"
      }
    ];
    return history;
  },
  fakeEmergencyContactProfile() {
    const emergency = [
      {
        name: "Bill",
        dob: "10 October 1962",
        gender: "Male",
        address: "41 Queens Road West",
        contact: "2988810",
        email: "Billy@mazecare.com",
        bloodGroup: "O+",
        relation: "father"
      },
      {
        name: "Lilly",
        dob: "04 May 1987",
        gender: "Female",
        address: "Floor 8, Midlevels",
        contact: "99987441",
        email: "lilly@mazecare.com",
        bloodGroup: "O+",
        relation: "sister"
      }
    ];
    return emergency;
  },
  fakeGeneralRecord() {
    const general = [
      {
        height: 1.62,
        height2: 5.3,
        weight: 55,
        weight2: 121.2,
        bmi: "21",
        bp: "116 mmHg/ 81 mmHg",
        eyesight: "Myopic",
        oral: "good"
      }
    ];
    return general;
  },
  fakeFamilyHistory() {
    const history = [
      {
        name: "John",
        dob: "10 October 1962",
        gender: "Male",
        relation: "Father",
        status: "Death by car accident",
        contact: "N/A",
        email: "N/A",
        conditions: ["Diabetes", "Weak vision"]
      },
      {
        name: "Kevin",
        dob: "04 May 1987",
        gender: "Male",
        relation: "Husband",
        status: "Alive",
        contact: "+85259304003",
        email: "kevin@mazecare.com",
        conditions: ["Allergy (shrimp)"]
      }
    ];
    return history;
  },
  fakePatientHistory() {
    const history = {
      "A15.0": [
        "Ms J. K. is an 83 year old retired nurse with a long history of hypertension that was previously wellcontrolled on diuretic therapy. She was first admittedto CPMC in 1995 when she presented with a complaint ofintermittent midsternal chest pain. Herelectrocardiogram at that time showed first degreeatrioventricular block, and a chest X-ray showed mildpulmonary congestion, with cardiomegaly. Myocardialinfarction was ruled out by the lack ofelectrocardiographic and cardiac enzyme abnormalities.Patient was discharged after a brief stay on a regimenof enalapril, and lasix, and digoxin, for presumedcongestive heart failure. Since then she has beenfollowed closely by her cardiologist."
      ],
      "I11.0": [
        "Aside from hypertension and her postmenopausal state,the patient denies other coronary artery disease riskfactors, such as diabetes, cigarette smoking,hypercholesterolemia or family history for heartdisease. Since her previous admission, she describes astable two pillow orthopnea, dyspnea on exertion afterwalking two blocks, and a mild chronic ankle edema whichis worse on prolonged standing. She denies syncope,paroxysmal nocturnal dyspnea, or recent chest pains."
      ],
      N19: [
        "She was well until 11pm on the night prior to admissionwhen she noted the onset of “aching pain under herbreast bone” while sitting, watching television. Thepain was described as “heavy” and “toothache” like. Itwas not noted to radiate, nor increase with exertion.She denied nausea, vomiting, diaphoresis, palpitations,dizziness, or loss of consciousness. She took 2tablespoon of antacid without relief, but did manage tofall sleep. In the morning she awoke free of pain,however upon walking to the bathroom, the pain returnedwith increased severity. At this time she called herdaughter, who gave her an aspirin and brought herimmediately to the emergency room. Her electrocardiogramon presentation showed sinus tachycardia at 110, withmarked ST elevation in leads I, AVL, V4-V6 andoccasional ventricular paroxysmal contractions. Patientimmediately received thrombolytic therapy and cardiacmedications, and was transferred to the intensive careunit."
      ]
    };
    return history;
  },
  fakePatientTreatment() {
    const treatment = {
      "A15.0": [
        " Continue aspirin, heparin, nitrates, beta blockers,nasal oxygen. Follow serial physical exams, EKGs, and labs."
      ],
      "I11.0": [
        "Obtain echocardiogram to assess post MI heart function and murmurs heard on cardiac exam. If LV ejection fraction is preserved, to start early beta blocker therapy."
      ],
      N19: [" Continue ACE inhibitor therapy, and monitor blood pressure."]
    };
    return treatment;
  },
  fakeAppointment() {
    const appointment = [
      {
        name: "Patient 1",
        time: "10:00 AM",
        doc: "Dr. Peter",
        reason: "Nose Bleeding"
      },
      {
        name: "Patient 2",
        doc: "Dr. Rose",
        time: "07:00 AM",
        reason: "Headache"
      },
      {
        name: "Patient 3",
        doc: "Dr. Parker",
        time: "11:00 AM",
        reason: "Fever"
      }
    ];
    return appointment;
  },
  fakeBranches() {
    const branches = [
      { id: 1, name: "Nolan and Rath" },
      { id: 2, name: "Kunze Inc" },
      { id: 3, name: "Collier and Sons" },
      { id: 4, name: "Rempel-Crist" },
      { id: 5, name: "Hong Kong Clinic" },
      { id: 6, name: "Kowloon Clinic" }
    ];
    return branches.map((branch, idx) => {
      return {
        id: branch.id,
        name: branch.name,
        email: _.toLower(_.replace(branch.name, / /g, "") + "@mazecare.com")
      };
    });
  },
  fakeBranchDetails() {
    const json = require("./fakeAddress.json");
    const branches = [
      {
        id: 1,
        name: "Nolan and Rath",
        hours: [
          {
            name: "Sunday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: false,
            is24: false
          },
          {
            name: "Monday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Tuesday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Wednesday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Thursday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],

            isOpen: true,
            is24: false
          },
          {
            name: "Friday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Saturday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: false,
            is24: false
          }
        ],
        specialhours: [
          {
            id: 1,
            day: "2021-12-15",
            isOpen: true,
            is24: false,
            hours: [
              {
                id: 1,
                open: "06:00",
                close: "12:00"
              },
              {
                id: 2,
                open: "13:00",
                close: "20:00"
              }
            ]
          },
          {
            id: 2,
            day: "2021-12-25",
            isOpen: false,
            is24: true,
            hours: [
              {
                id: 1,
                open: "",
                close: ""
              }
            ]
          },
          {
            id: 3,
            day: "2021-12-26",
            isOpen: false,
            is24: true,
            hours: [
              {
                id: 1,
                open: "",
                close: ""
              }
            ]
          }
        ]
      },
      {
        id: 2,
        name: "Kunze Inc",
        hours: [
          {
            name: "Sunday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: false,
            is24: false
          },
          {
            name: "Monday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Tuesday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Wednesday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Thursday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],

            isOpen: true,
            is24: false
          },
          {
            name: "Friday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Saturday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: false,
            is24: false
          }
        ],
        specialhours: [
          {
            id: 1,
            day: "2021-12-15",
            isOpen: true,
            is24: false,
            hours: [
              {
                id: 1,
                open: "06:00",
                close: "12:00"
              },
              {
                id: 2,
                open: "13:00",
                close: "20:00"
              }
            ]
          },
          {
            id: 2,
            day: "2021-12-25",
            isOpen: false,
            is24: true,
            hours: [
              {
                id: 1,
                open: "",
                close: ""
              }
            ]
          },
          {
            id: 3,
            day: "2021-12-26",
            isOpen: false,
            is24: true,
            hours: [
              {
                id: 1,
                open: "",
                close: ""
              }
            ]
          }
        ]
      },
      {
        id: 3,
        name: "Collier and Sons",
        hours: [
          {
            name: "Sunday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: false,
            is24: false
          },
          {
            name: "Monday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Tuesday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Wednesday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Thursday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],

            isOpen: true,
            is24: false
          },
          {
            name: "Friday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Saturday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: false,
            is24: false
          }
        ],
        specialhours: [
          {
            id: 1,
            day: "2021-12-15",
            isOpen: true,
            is24: false,
            hours: [
              {
                id: 1,
                open: "06:00",
                close: "12:00"
              },
              {
                id: 2,
                open: "13:00",
                close: "20:00"
              }
            ]
          },
          {
            id: 2,
            day: "2021-12-25",
            isOpen: false,
            is24: true,
            hours: [
              {
                id: 1,
                open: "",
                close: ""
              }
            ]
          },
          {
            id: 3,
            day: "2021-12-26",
            isOpen: false,
            is24: true,
            hours: [
              {
                id: 1,
                open: "",
                close: ""
              }
            ]
          }
        ]
      },
      {
        id: 4,
        name: "Rempel-Crist",
        hours: [
          {
            name: "Sunday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: false,
            is24: false
          },
          {
            name: "Monday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Tuesday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Wednesday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Thursday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],

            isOpen: true,
            is24: false
          },
          {
            name: "Friday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Saturday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: false,
            is24: false
          }
        ],
        specialhours: [
          {
            day: "2021-12-15",
            isOpen: true,
            is24: false,
            hours: [
              {
                open: "06:00",
                close: "12:00"
              },
              {
                open: "13:00",
                close: "20:00"
              }
            ]
          },
          {
            day: "2021-12-25",
            isOpen: false,
            is24: true,
            hours: [
              {
                open: "",
                close: ""
              }
            ]
          },
          {
            day: "2021-12-26",
            isOpen: false,
            is24: true,
            hours: [
              {
                open: "",
                close: ""
              }
            ]
          }
        ]
      },
      {
        id: 5,
        name: "Hong Kong Clinic",
        hours: [
          {
            name: "Sunday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: false,
            is24: false
          },
          {
            name: "Monday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Tuesday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Wednesday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Thursday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],

            isOpen: true,
            is24: false
          },
          {
            name: "Friday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Saturday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: false,
            is24: false
          }
        ],
        specialhours: [
          {
            day: "2021-12-15",
            isOpen: true,
            is24: false,
            hours: [
              {
                open: "06:00",
                close: "12:00"
              },
              {
                open: "13:00",
                close: "20:00"
              }
            ]
          },
          {
            day: "2021-12-25",
            isOpen: false,
            is24: true,
            hours: [
              {
                open: "",
                close: ""
              }
            ]
          },
          {
            day: "2021-12-26",
            isOpen: false,
            is24: true,
            hours: [
              {
                open: "",
                close: ""
              }
            ]
          }
        ]
      },
      {
        id: 6,
        name: "Kowloon Clinic",
        hours: [
          {
            name: "Sunday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: false,
            is24: false
          },
          {
            name: "Monday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Tuesday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Wednesday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Thursday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],

            isOpen: true,
            is24: false
          },
          {
            name: "Friday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: true,
            is24: false
          },
          {
            name: "Saturday",
            regularhours: [
              { open: "08:00", close: "12:00" },
              { open: "13:00", close: "17:00" }
            ],
            isOpen: false,
            is24: false
          }
        ],
        specialhours: [
          {
            day: "2021-12-15",
            isOpen: true,
            is24: false,
            hours: [
              {
                open: "06:00",
                close: "12:00"
              },
              {
                open: "13:00",
                close: "20:00"
              }
            ]
          },
          {
            day: "2021-12-25",
            isOpen: false,
            is24: true,
            hours: [
              {
                open: "",
                close: ""
              }
            ]
          },
          {
            day: "2021-12-26",
            isOpen: false,
            is24: true,
            hours: [
              {
                open: "",
                close: ""
              }
            ]
          }
        ]
      }
    ];

    return branches.map((branch, idx) => {
      return {
        id: branch.id,
        name: branch.name,
        contact: "+852 " + Math.floor(Math.random() * 100000000),
        address: _.shuffle(json.addresses)[0],
        email: _.toLower(_.replace(branch.name, / /g, "") + "@mazecare.com"),
        hours: branch.hours,
        specialhours: branch.specialhours
      };
    });
  }
};

const fakerData = [];
for (let i = 0; i < 20; i++) {
  fakerData[fakerData.length] = {
    users: fakers.fakeUsers(),
    userDetails: fakers.fakeUserDetails(),
    doctors: fakers.fakeDoctors(),
    photos: fakers.fakePhotos(),
    images: fakers.fakeImages(),
    dates: fakers.fakeDates(),
    times: fakers.fakeTimes(),
    formattedTimes: fakers.fakeFormattedTimes(),
    totals: fakers.fakeTotals(),
    trueFalse: fakers.fakeTrueFalse(),
    stocks: fakers.fakeStocks(),
    products: fakers.fakeProducts(),
    rooms: fakers.fakeRooms(),
    equipment: fakers.fakeEquipment(),
    tests: fakers.fakeTests(),
    news: fakers.fakeNews(),
    files: fakers.fakeFiles(),
    jobs: fakers.fakeJobs(),
    specialization: fakers.fakeSpecialization(),
    notificationCount: fakers.fakeNotificationCount(),
    medicalHistory: fakers.fakeMedicalHistory(),
    doctor: fakers.fakeDoctorConsulted(),
    immunization: fakers.fakeImmunizationRecords(),
    regimen: fakers.fakeRegimen(),
    patientProfile: fakers.fakePatientProfile(),
    familyHistory: fakers.fakeFamilyHistory(),
    ID: fakers.fakePatientID(),
    emergency: fakers.fakeEmergencyContactProfile(),
    general: fakers.fakeGeneralRecord(),
    foodAllergy: fakers.fakeFoodAllergy(),
    drugAllergy: fakers.fakeDrugAllergy(),
    patientHistory: fakers.fakePatientHistory(),
    patientTreatment: fakers.fakePatientTreatment(),
    appointment: fakers.fakeAppointment(),
    branches: fakers.fakeBranches(),
    branchDetails: fakers.fakeBranchDetails()
  };
}
fakerData[0].templates = [
  {
    patient: {
      dob: "1985-06-27",
      email: "jacquelineandrews@mazecare.com",
      name: "Jacqueline Andrews"
    },
    customFields: [{ label: "isDisease" }],
    description: "A template",
    name: "Template 2"
  }
];
const install = app => {
  app.config.globalProperties.$f = () => {
    return fakerData;
  };
};

export { install as default, fakerData as faker };
