<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Templates Layout</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <div
          type="button"
          class="btn btn-primary shadow-md mr-2"
          @click="addTemplate = true"
        >
          Add New Template
        </div>
        <div class="dropdown">
          <button
            class="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300"
            aria-expanded="false"
          >
            <span class="w-5 h-5 flex items-center justify-center">
              <PlusIcon class="w-4 h-4" />
            </span>
          </button>
          <div class="dropdown-menu w-40">
            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
              <a
                href=""
                class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              >
                <UsersIcon class="w-4 h-4 mr-2" /> Add Group
              </a>
              <a
                href=""
                class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              >
                <MessageCircleIcon class="w-4 h-4 mr-2" /> Send Message
              </a>
            </div>
          </div>
        </div>
        <div class="hidden md:block mx-auto text-gray-600">
          Showing 1 to 10 of 150 entries
        </div>
        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input
              type="text"
              v-model="search"
              class="form-control w-56 box pr-10 placeholder-theme-8"
              placeholder="Search..."
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
        </div>
      </div>
      <!-- BEGIN: Users Layout -->
      <div
        v-for="(faker, key) in filteredTemplates"
        :key="`${key}`"
        class="intro-y col-span-12 md:col-span-6"
      >
        <div class="box">
          <div
            class="flex flex-col lg:flex-row items-start p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <div
              class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0"
            >
              <div class="font-medium cursor-pointer">
                {{ faker.name }}
              </div>
              <div class="text-gray-600 text-xs mt-0.5">
                {{
                  faker.description
                    ? faker.description
                    : "No description provided"
                }}
              </div>
            </div>
            <div
              class="flex text-gray-600 -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0"
            >
              {{ `${total(faker)} field(s) used` }}
            </div>
          </div>
          <div
            class="flex flex-wrap lg:flex-nowrap items-center justify-center p-5"
          >
            <div class="w-full lg:w-1/2 mb-4 lg:mb-0 mr-auto">
              <div class="flex text-gray-600 text-xs">
                <div class="mr-auto">Popularity</div>
                <div>20%</div>
              </div>
              <div class="progress h-1 mt-2">
                <div
                  class="progress-bar w-1/4 bg-theme-17"
                  role="progressbar"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <a
              type="button"
              class="btn btn-primary py-1 px-2"
              @click="click(faker)"
              >Details</a
            >
          </div>
        </div>
      </div>
      <!-- END: Users Layout -->
      <!-- BEGIN: Pagination -->
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center"
      >
        <ul class="pagination">
          <li>
            <a class="pagination__link" href="">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">...</a>
          </li>
          <li>
            <a class="pagination__link" href="">1</a>
          </li>
          <li>
            <a class="pagination_link pagination_link--active" href="">2</a>
          </li>
          <li>
            <a class="pagination__link" href="">3</a>
          </li>
          <li>
            <a class="pagination__link" href="">...</a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronRightIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronsRightIcon class="w-4 h-4" />
            </a>
          </li>
        </ul>
        <select class="w-20 form-select box mt-3 sm:mt-0">
          <option>10</option>
          <option>25</option>
          <option>35</option>
          <option>50</option>
        </select>
      </div>
      <!-- END: Pagination -->
    </div>
    <ProfileModal />
    <vue-final-modal
      v-model="addTemplate"
      name="addTemplate"
      :key="addTemplateCounter"
      classes="flex justify-center items-center max-h-screen overflow-y-auto"
      content-class="p-8 border dark:border-gray-800 rounded-lg bg-gray-200 dark:bg-gray-800 max-h-9/10 overflow-y-auto"
    >
      <AddTemplateModal
        @add-profile="addProfile"
        @add-template="closeAddTemplate"
      />
    </vue-final-modal>
  </div>
</template>

<script>
import { faker } from "@/utils/faker";
import ProfileModal from "./ProfileModal.vue";
import AddTemplateModal from "./AddTemplateModal.vue";
import _ from "lodash";
import { inject } from "vue";

export default {
  data() {
    return {
      data: [],
      search: "",
      addTemplate: false,
      addTemplateCounter: 0
    };
  },
  setup() {
    const $vfm = inject("$vfm");
  },
  components: {
    ProfileModal,
    AddTemplateModal
  },
  created() {
    this.data = faker[0].templates.slice();
    console.log(faker);
  },
  computed: {
    filteredTemplates: function() {
      return this.data.filter(template => {
        return (
          template.name.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
        );
      });
    }
  },
  methods: {
    click(faker) {
      this.modalData = faker;
      cash("#exampleModalCenter2").modal("show");
    },
    closeAddTemplate(data) {
      this.$vfm.hide("addTemplate");
      this.data.push(data);
      this.addTemplateCounter += 1;
    },
    total(data) {
      if ("description" in data) {
        return Object.keys(data).length - 2;
      } else {
        return Object.keys(data).length - 1;
      }
    },
    addProfile(data) {
      //   this.data.push(data);
      //   faker[0].userDetails.push({
      //     name: data.name,
      //     bloodGroups: _.shuffle(bloodGroups)[0],
      //     contact: "+852 " + Math.floor(Math.random() * 100000000),
      //     address: _.shuffle(json.addresses)[0],
      //     gender: _.shuffle(gender)[0]
      //   });
    }
  }
};
</script>
