<template>
  <div class="modal" id="exampleModalCenter5" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            New Food Allergy Records
          </h5>
        </div>
        <div class="p-12">
          <Form @submit="submit">
            <span>Allergen/ Allergen Group: </span>
            <Field
              name="allergen"
              rules="required"
              placeholder="Enter name of allergen/ allergen group"
              class="form-control"
              style="margin-top: 10px"
            />
            <ErrorMessage name="allergen" />
            <br />
            <br />
            <span>Level of Certainity: </span>
            <Field
              name="certainUncertain"
              rules="required"
              placeholder="Enter 'certain' or 'suspected'"
              class="form-control"
              style="margin-top: 10px"
            />
            <ErrorMessage name="certainUncertain" />

            <br />
            <br />
            <span>Clinical Manifestion: </span>
            <Field
              name="manifestation"
              rules="required"
              placeholder="Enter clinical manifestation"
              class="form-control"
              style="margin-top: 10px"
            />
            <ErrorMessage name="manifestation" />
            <div class="grid grid-cols-4 gap-4 mt-3">
              <input
                type="submit"
                class="btn btn-primary col-start-2 col-span-1"
                value="Submit"
                data-dismiss="modal"
              />
              <input
                class="btn btn-secondary col-start-3 col-span-1"
                value="Close"
                data-dismiss="modal"
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { required, email, numeric } from "@vee-validate/rules";

defineRule("required", required);
defineRule("email", email);
defineRule("numeric", numeric);

export default {
  components: {
    Form,
    Field,
    ErrorMessage
  },
  methods: {
    submit(value) {
      const d = new Date(value.date);
      const data = {
        certainity: value.certainUncertain,
        manifestation: value.manifestation,
        allergen: value.allergen
      };
      this.$emit("add-food-allergy", data);
    }
  }
};
</script>

<style scoped>
input + span,
select + span {
  display: block;
  color: red;
}
</style>
