<template>
  <div>
    <div class="intro-y box flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Manage Room</h2>
    </div>
    <div class="intro-y box px-5 pt-5 mt-5">
      <div class="">
        <h5>Room Details</h5>
      </div>
      <div>
        <div>{{ roomInfo.name }} - {{ roomInfo.category }}</div>
        <div>Room Capacity: {{ roomInfo.capacity }}</div>
        <div v-if="roomInfo.name" class="p-4">
          <TinySlider
            :options="{
              mode: 'gallery',
              controls: true,
              nav: true,
              speed: 500,
            }"
          >
            <div v-for="image in roomInfo.images" :key="image">
              <div class="h-64 px-2">
                <div class="h-full image-fit rounded-md overflow-hidden">
                  <img
                    :src="require(`@/assets/images/${image}`)"
                    alt="Mazecare Admin Demo 1"
                  />
                </div>
              </div>
            </div>
          </TinySlider>
        </div>
        <div v-else>Please upload images</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="$router.go(-1)">
          Back to rooms
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { faker } from "../../utils/faker";

export default defineComponent({
  props: ["roomid", "room"],
  data: function () {
    return {
      roomInfo: [],
    };
  },
  created() {
    if (this.roomid !== undefined) {
      let obj = JSON.parse(this.room);
      this.roomInfo = faker[0].rooms.filter((data) => data.id === obj.id)[0];
      console.log(this.roomInfo);
    } else {
      this.roomInfo = undefined;
    }
  },
});
</script>
