<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Manage Room</h2>
    </div>
    <div class="box px-5 pt-5 mt-5">
      <div class="">
        <h5>Room Details</h5>
      </div>
      <div>
        <div>{{ roomInfo[0].name }} - {{ roomInfo[0].category }}</div>
        <div>Room Capacity: {{ roomInfo[0].capacity }}</div>
        <div v-if="roomInfo[0].name" class="p-4">
          <TinySlider
            :options="{
              mode: 'gallery',
              controls: true,
              nav: true,
              speed: 500,
            }"
          >
            <div v-for="image in roomInfo[0].images" :key="image">
              <div class="h-64 px-2">
                <div class="h-full image-fit rounded-md overflow-hidden">
                  <img
                    :src="require(`@/assets/images/${image}`)"
                    alt="Mazecare Admin Demo 1"
                  />
                </div>
              </div>
            </div>
          </TinySlider>
        </div>
        <div v-else>Please upload images</div>
        <div class="py-2">
          <a
            type="button"
            class="btn btn-secondary mr-2"
            data-toggle="modal"
            data-target="#editRoomModal"
          >
            Edit
          </a>
          <a type="button" class="btn btn-secondary mr-2" @click="deleteRoom()">
            Remove Room
          </a>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="redirect()">
          Back to rooms
        </button>
      </div>
    </div>
    <EditRoomModal :room-info="roomInfo" @edit-room="editRoom" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { faker } from "../../utils/faker";
import EditRoomModal from "./EditRoomModal.vue";

export default defineComponent({
  components: {
    EditRoomModal,
  },
  props: ["room", "roomid"],
  data: function () {
    return {
      roomInfo: []
    };
  },
  created() {
    if (this.roomid !== undefined) {
      let obj = JSON.parse(this.room);
      this.roomInfo = faker[0].rooms.filter((data) => data.id === obj.id);
    } else {
      this.roomInfo = undefined;
    }
  },
  methods: {
    deleteRoom() {
      let obj = JSON.parse(this.room);
      let obj1 = faker[0].rooms.findIndex((data) => data.id === obj.id);
      faker[0].rooms.splice(obj1, 1);
      this.$router.push({
        name: "side-menu-rooms",
      });
    },
    editRoom(data) {
      let obj = faker[0].rooms.findIndex(data => data.id === data.id);
      this.roomInfo[0].name = data.name;
      this.roomInfo[0].capacity = data.capacity;
      this.roomInfo[0].category = data.category;
      this.roomInfo[0].images = data.images;
      this.roomInfo[0].status = data.status;
      faker[0].rooms[obj].name = data.name;
      faker[0].rooms[obj].capacity = data.capacity;
      faker[0].rooms[obj].category = data.category;
      faker[0].rooms[obj].images = data.images;
      faker[0].rooms[obj].status = data.status;
      console.log("updated");
    },
    redirect() {
      this.$router.push({
        name: "side-menu-rooms",
      });
    },
  },
  components: { EditRoomModal },
});
</script>
