<template>
  <!-- Modal -->
  <div id="exampleModalCenter5" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLongTitle" class="modal-title">Add Rooms</h5>
        </div>
        <div class="p-6">
          <Form @submit="onSubmit" autocomplete="off">
            <span>Room Number: </span>
            <Field
              v-model="name"
              name="name"
              rules="lol"
              placeholder="enter name"
              class="form-control"
            />
            <ErrorMessage name="name" />
            <br />
            <br />
            <span>Capacity: </span>
            <Field
              v-model="capacity"
              name="capacity"
              rules="lol"
              type="number"
              placeholder="enter capacity"
              class="form-control"
            />
            <ErrorMessage name="capacity" />
            <br />
            <br />
            <span>Category: </span>
            <Field
              v-model="category"
              name="category"
              rules="lol"
              placeholder="enter category"
              class="form-control"
            />
            <ErrorMessage name="category" />
            <br />
            <br />
            <span>Branch: </span>
            <BranchAutoComplete
              :branch="branch"
              :branch-completed="branchCompleted"
              @set-doctor="setBranch"
            />
            <br />
            <span>Images: </span>
            <Field
              v-model="images"
              name="images"
              rules="lol"
              placeholder="enter images"
              class="form-control"
            />
            <ErrorMessage name="images" />
            <br />
            <br />
            <div class="flex items-center">
              <span class="mr-2">Status </span>
            <input type="checkbox" class="form-check-switch" v-model="status"/>
              </div>
            <br />
            <input
              style="
                width: 75px;
                margin-left: 115px;
                margin-right: 10px;
                margin-top: 15px;
              "
              type="submit"
              class="btn btn-primary"
              data-dismiss="modal"
              value="Submit"
            />
            <input
              style="width: 75px; margin-top: 15px"
              class="btn btn-secondary"
              value="Close"
              data-dismiss="modal"
              @click="close"
            />
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { numeric } from "@vee-validate/rules";
import { faker } from "../../utils/faker";
import BranchAutoComplete from "../../views/calendar/BranchAutoComplete.vue";

defineRule("lol", (value) => {
  if (value === null || value === undefined) {
    return true;
  } else if (!value || !value.length) {
    return false;
  }

  return true;
});
defineRule("numeric", numeric);

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    BranchAutoComplete
  },
  data() {
    return {
      name: "",
      images: "",
      category: "",
      capacity: "",
      status: "",
      branch: "",
      branchCompleted: false,
      options: []
    };
  },
  methods: {
    init(){
      this.options = faker[0].branchDetail.name;
      console.log(this.options);
    },
    setBranch(data) {
      this.branch = { id: data.id, name: data.name};
      this.branchCompleted = true;
    },
    close() {
      this.name = null;
      this.images = null;
      this.category = null;
      this.status = null;
      this.capacity = null;
      this.branch = null;
    },
    onSubmit() {
      let data = {
        name: `Room - ${this.name}`,
        images: [this.images, this.images, this.images],
        status: this.status,
        category: this.category,
        capacity: this.capacity,
        branch: this.branch
      };
      console.log(data);
      this.close();
      this.$emit("add-room", data);
    },
  },
};
</script>

<style scoped>
input + span,
select + span {
  display: block;
  color: red;
}
</style>
