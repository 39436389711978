<template>
  <!-- Modal -->
  <div id="exampleModalCenter15" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLongTitle" class="modal-title">
            Add Special Hours:
          </h5>
        </div>
        <div class="p-6">
          <Form @submit="onSubmit">
            <h2>Special Business Hours</h2>

            <div
              v-for="(item, index) in specialHours"
              :key="index"
              class="form-group flex flex-col border-b border-gray-400 mb-2"
            >
              <div>
                <div class="">
                  <div class="mr-2 flex flex-row">
                    <div
                      class="
                        absolute
                        rounded-l
                        w-10
                        h-10
                        flex
                        items-center
                        justify-center
                        bg-gray-100
                        border
                        text-gray-600
                        dark:bg-dark-1 dark:border-dark-4
                      "
                    >
                      <CalendarIcon class="w-4 h-4" />
                    </div>
                    <Litepicker
                      v-model="item.day"
                      :options="{
                        autoApply: false,
                        showWeekNumbers: true,
                        dropdowns: {
                          minYear: 1990,
                          maxYear: null,
                          months: true,
                          years: true,
                        },
                      }"
                      class="form-control pl-12 w-60 mb-2"
                    />
                  </div>

                  <div class="">
                    <div class="">
                      <input
                        id="checkbox"
                        v-model="item.isOpen"
                        class="mr-2 form-check-switch"
                        type="checkbox"
                      />
                      <label v-if="item.isOpen" for="checkbox" class="mr-2"
                        >Open</label
                      >
                      <label v-if="!item.isOpen" for="checkbox" class="mr-2"
                        >Closed</label
                      >
                    </div>
                  </div>

                  <div v-if="item.isOpen" class="md:flex md:flex-col">
                    <div class="mr-4">
                      <input
                        id="checkbox"
                        v-model="item.is24"
                        class="mr-2 form-check-switch"
                        type="checkbox"
                      />
                      <label for="checkbox">24hrs</label>
                    </div>
                    <div v-if="!item.is24" class="">
                      <div
                        v-for="(hour, k) in item.hours"
                        :key="k"
                        class="
                          form-group
                          md:flex md:flex-row md:items-center
                          mb-2
                        "
                      >
                        <div class="w-60">
                          <input
                            v-model="hour.open"
                            type="time"
                            class="form-control"
                          />
                          <input
                            v-model="hour.close"
                            type="time"
                            class="form-control"
                          />
                        </div>
                        <div class="md:flex md:flex-row">
                          <div
                            v-show="k || (!k && item.hours.length > 1)"
                            class="
                              cursor-pointer
                              btn btn-secondary
                              mt-2
                              md:ml-2
                            "
                            @click="removehours(k, index)"
                          >
                            Remove
                          </div>
                          <div
                            v-show="k == item.hours.length - 1"
                            class="cursor-pointer btn-primary btn mt-2 md:ml-2"
                            @click="addhours(k, index)"
                          >
                            Add
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span>
                <div
                  v-show="index || (!index && specialHours.length > 0)"
                  class="cursor-pointer btn btn-primary my-2 mr-2"
                  @click="remove(index)"
                >
                  Remove
                </div>
                <div
                  v-show="index == specialHours.length - 1"
                  class="cursor-pointer btn btn-primary my-2"
                  @click="add(index)"
                >
                  Add new Date
                </div>
              </span>
            </div>

            <input
              style="
                width: 75px;
                margin-left: 115px;
                margin-right: 10px;
                margin-top: 15px;
              "
              type="submit"
              class="btn btn-primary"
              data-dismiss="modal"
              value="Update"
            />
            <input
              style="width: 75px; margin-top: 15px"
              class="btn btn-secondary"
              value="Close"
              data-dismiss="modal"
              @click="close()"
            />
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { numeric } from "@vee-validate/rules";
import { faker } from "@/utils/faker";

defineRule("lol", (value) => {
  if (value === null || value === undefined) {
    return true;
  } else if (!value || !value.length) {
    return false;
  }
  return true;
});
defineRule("customEmail", (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }

  // Check if email
  if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  ) {
    return false;
  }

  return true;
});
defineRule("numeric", numeric);

export default {
  components: {
    Form
  },
  props: ["branchInfo"],
  data() {
    return {
      specialHours: []
    };
  },
  created(){
    this.specialHours = this.branchInfo.specialhours;
  },
  methods: {
    add(index) {

      this.specialHours.push({
        day: "",
        isOpen: true,
        is24: false,
        hours: [
          {
            open: "",
            close: "",
          },
        ],
      });
    },
    remove(index) {

      this.specialHours.splice(index, 1);
    },
    addhours(k, index) {

      this.specialHours[index].hours.push({
        open: "",
        close: "",
      });
    },
    removehours(k, index) {
      console.log(k, index);
      this.specialHours[index].hours.splice(k, 1);
    },
    close() {},
    onSubmit() {
      let data = this.specialHours;
      // this.$emit("update-branchspecialhours", data);
    },
  },
};
</script>

<style scoped>
input + span,
select + span {
  display: block;
  color: red;
}
</style>
