<template>
  <!-- Modal -->
  <div id="exampleModalCenter11" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLongTitle" class="modal-title">
            Product Details
          </h5>
        </div>
        <div class="modal-body">
          <div>{{ product.name }} - {{ product.category }}</div>
          <div>Remaining Stock: {{ product.remaining_stock }}</div>
          <div v-if="product.name" class="p-4">
            <TinySlider
              :options="{
                mode: 'gallery',
                controls: true,
                nav: true,
                speed: 500,
              }"
            >
              <div v-for="image in product.images" :key="image">
                <div class="h-64 px-2">
                  <div class="h-full image-fit rounded-md overflow-hidden">
                    <img
                      :src="require(`@/assets/images/${image}`)"
                      alt="Mazecare Admin Demo 1"
                    />
                  </div>
                </div>
              </div>
            </TinySlider>
          </div>
          <div v-else>Please upload images</div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["modalData"],
  data: function () {
    return {
      product: {},
    };
  },
  created() {
    this.product = this.modalData;
  },
};
</script>
