<template>
  <FullCalendar :options="options" ref="fullCalendar" />
  <vue-final-modal
    v-model="showEventDetails"
    name="example"
    :max-height="100"
    classes="flex justify-center items-center flex-wrap max-h-screen overflow-y-scroll"
    content-class="relative mx-4 max-h-9/10 overflow-y-auto p-4 border dark:border-gray-800 rounded bg-gray-200 dark:bg-gray-800 w-full lg:w-3/4 2xl:w-1/2"
  >
    <template #default="{ params }">
      <EventDetailsModal :params="params" @close="showEventDetails = false" />
    </template>
  </vue-final-modal>
  <vue-final-modal
    v-model="showNewAppointment"
    name="clickAppointment"
    :key="counter"
    classes="flex justify-center items-center max-h-screen overflow-y-scroll"
    content-class="w-500 max-h-screen"
  >
    <template #default="{ params }">
      <ModalAppointment
        @toggle="toggle"
        @add-appointment-click="addAppointmentCalender"
        :start="params.start"
        :month="params.month"
        :defaultTime="params.time"
      />
    </template>
  </vue-final-modal>
</template>

<script>
import { defineComponent } from "vue";
import "@fullcalendar/core";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";
import EventDetailsModal from "./EventDetailsModal.vue";
import ModalAppointment from "./ModalAppointment.vue";
import { inject } from "vue";

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes;
  return strTime;
}

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export default defineComponent({
  props: ["events"],
  emits: ["add-appointment"],
  setup() {
    const $vfm = inject("$vfm");
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"
    );
    document.head.appendChild(recaptchaScript);
  },
  components: {
    EventDetailsModal,
    ModalAppointment
  },
  data() {
    return {
      showEventDetails: false,
      showNewAppointment: false,
      counter: 0,

      options: {
        plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
        droppable: true,
        selectable: true,
        eventDidMount: function(info) {
          info.el.setAttribute("data-bs-toggle", "tooltip");
          info.el.setAttribute("data-bs-html", "true");
          info.el.setAttribute(
            "title",
            `<div class='bg-white dark:bg-gray-900'><div>Patient Name - ${
              info.event.extendedProps.patient
            }</div><div>Doctor Name - ${
              info.event.extendedProps.doctor
            }</div><div>Appointment Date - ${info.event.start.toDateString()}</div><div>Appointment Duration - 1 hour</div></div>`
          );
          Array.from(
            document.querySelectorAll('a[data-bs-toggle="tooltip"]')
          ).forEach(tooltipNode => new Tooltip(tooltipNode));
        },
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
        },
        initialDate: "2021-01-12",
        navLinks: true,
        editable: true,
        selectable: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        dayMaxEvents: true,
        events: this.events,
        drop: function(info) {
          if (
            cash("#checkbox-events").length &&
            cash("#checkbox-events")[0].checked
          ) {
            cash(info.draggedEl)
              .parent()
              .remove();

            if (cash("#calendar-events").children().length == 1) {
              cash("#calendar-no-events").removeClass("hidden");
            }
          }
        }
      }
    };
  },
  methods: {
    handleDateSelect(selectInfo) {
      if (selectInfo.view.type === "dayGridMonth") {
        this.$vfm.show("clickAppointment", {
          start: formatDate(selectInfo.start),
          month: true,
          time: null
        });
      } else {
        this.$vfm.show("clickAppointment", {
          start: formatDate(selectInfo.start),
          month: false,
          time: formatAMPM(selectInfo.start)
        });
      }
    },
    handleEventClick(clickInfo) {
      this.$vfm.show("example", {
        userName: clickInfo.event.title,
        date: clickInfo.event.start.toDateString(),
        patient: clickInfo.event.extendedProps.patient,
        doctor: clickInfo.event.extendedProps.doctor,
        time: clickInfo.event.extendedProps.time
      });
    },
    addAppointmentCalender(data) {
      this.toggle();
      this.$emit("add-appointment", data);
    },
    toggle() {
      this.$vfm.hide("clickAppointment");
      this.counter += 1;
    }
  }
});
</script>
