<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Queue Management</h2>
    </div>
    <div class="flex mt-4">
      <div class="p-5 min-h-screen flex overflow-x-scroll">
        <div
          v-for="column in columns"
          :key="column.title"
          class="rounded-lg px-3 py-3 column-width mr-4"
        >
          <p class="font-semibold font-sans tracking-wide text-sm">
            {{ column.title }}
          </p>

          <draggable
            v-model="column.tasks"
            item-key="id"
            :animation="200"
            ghost-class="ghost-card"
            group="tasks"
          >
            <template #item="{element}">
              <TaskCard :task="element" class="mt-3 cursor-move"></TaskCard>
            </template>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import TaskCard from "../../components/kanban/TaskCard.vue";
import { faker } from "../../utils/faker";

export default {
  name: "App",
  components: {
    TaskCard,
    draggable
  },
  data() {
    const patients = faker[0].users.slice(0, 13);

    return {
      columns: [
        {
          title: "Waiting",
          tasks: [
            {
              id: 1,
              title: patients[0].name,
              date: "Sep 14",
              type: "Type 1"
            },
            {
              id: 2,
              title: patients[1].name,
              date: "Sep 12",
              type: "Type 2"
            },
            {
              id: 3,
              title: patients[2].name,
              date: "Sep 9",
              type: "Type 4"
            },
            {
              id: 4,
              title: patients[3].name,
              date: "Sep 14",
              type: "Type 1"
            },
            {
              id: 5,
              title: patients[4].name,
              date: "Sep 15",
              type: "Type 2"
            }
          ]
        },
        {
          title: "Serving",
          tasks: [
            {
              id: 6,
              title: patients[5].name,
              date: "Sep 9",
              type: "Type 4"
            },
            {
              id: 7,
              title: patients[6].name,
              date: "Sep 14",
              type: "Type 1"
            },
            {
              id: 8,
              title: patients[7].name,
              date: "Sep 12",
              type: "Type 2"
            }
          ]
        },
        {
          title: "Served",
          tasks: [
            {
              id: 9,
              title: patients[8].name,
              date: "Type 3"
            },
            {
              id: 10,
              title: patients[9].name,
              date: "Sep 9",
              type: "Type 2"
            },
            {
              id: 11,
              title: patients[10].name,
              date: "Sep 14",
              type: "Type 1"
            },
            {
              id: 12,
              title: patients[11].name,
              date: "Sep 9",
              type: "Type 2"
            },
            {
              id: 13,
              title: patients[12].name,
              date: "Sep 14",
              type: "Type 1"
            }
          ]
        }
      ]
    };
  }
};
</script>

<style scoped>
.column-width {
  min-width: 320px;
  width: 320px;
}
/* Unfortunately @apply cannot be setup in codesandbox, 
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}
</style>
