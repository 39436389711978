<template>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header flex justify-between">
        <h5 class="modal-title">
          New Appointment
        </h5>
        <button type="button" class="close" @click="$emit('toggle')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="p-12">
        <Form @submit="submit">
          <span>Select Patient: </span>
          <PatientAutocomplete
            @set-patient="setPatient"
            @cross="patientCompleted = false"
            :patientCompleted="patientCompleted"
            :patient="patient"
          />
          <button>
            <a
              type="button"
              @click="$vfm.show('add')"
              href="javascript:void(0)"
              class="btn btn-secondary"
              >Add Patient</a
            >
          </button>
          <br />
          <br />
          <span>Select Doctor: </span>
          <DoctorAutocomplete @set-doctor="setDoctor" :doctor="doctor" />
          <br />
          <br />
          <span>Select Room: </span>
          <RoomAutocomplete @set-room="setRoom" :room="room" />
          <br />
          <br />
          <span>Select Equipment: </span>
          <EquipmentAutocomplete
            @set-equipment="setEquipment"
            :equipment="equipment"
          />
          <br />
          <br />
          <span>Select Start Date: </span>
          <Litepicker
            v-model="date"
            :options="{
              autoApply: false,
              autoRefresh: true,
              showWeekNumbers: true,
              dropdowns: {
                minYear: 1990,
                maxYear: null,
                months: true,
                years: true
              }
            }"
            class="form-control w-full block mb-5"
          />
          <span>Select Time: </span>
          <div
            class="w-full relative text-gray-700 dark:text-gray-300 sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0"
          >
            <Field
              name="time"
              class="form-control"
              type="time"
              v-model="time"
              rules="lol"
              placeholder="Time"
            />

            <ErrorMessage name="time" as="span" class="text-red-500"
              >Please enter a valid time!</ErrorMessage
            >
          </div>
          <br />
          <input type="checkbox" />
          <label for="virtual">Virtual Appointment</label>
          <br />
          <br />

          <!--EditPoint: Edit Vue Code to save details-->
          <span>Reason For Visit:</span><br />
          <input
            style="margin-right: 10px"
            class="form-control w-full"
            type="text"
            placeholder="E.g. Chest Pains, Fever, etc"
            v-model="reason"
          />

          <br />
          <br />

          <!--EditPoint: Edit Vue Code to save details-->
          <span>Additional Comments:</span><br />
          <textarea
            overflow-y:scroll
            style="margin-right: 10px"
            class="form-control w-full"
            placeholder="E.g. Chest Pains, Fever, etc"
            v-model="comment"
          ></textarea>

          <br />
          <br />
          <br />
          <input
            type="submit"
            class="btn btn-primary text-center block m-auto"
            value="Confirm"
          />
        </Form>
      </div>
    </div>
    <vue-final-modal
      v-model="patientModal"
      name="add"
      classes="flex justify-center items-center max-h-screen overflow-y-scroll"
      content-class="w-500"
    >
      <AddPatientModal
        @add-patient="addPatient"
        @toggle="patientModalToggle"
        :key="patientModalCounter"
      />
    </vue-final-modal>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { email, numeric } from "@vee-validate/rules";
import PatientAutocomplete from "../../views/calendar/PatientAutocomplete.vue";
import DoctorAutocomplete from "../../views/calendar/DoctorAutocomplete.vue";
import RoomAutocomplete from "../../views/calendar/RoomAutocomplete.vue";
import EquipmentAutocomplete from "../../views/calendar/EquipmentAutocomplete.vue";
import AddPatientModal from "../../views/calendar/AddPatientModal.vue";
import { inject } from "vue";

defineRule("lol", value => {
  if (value === null) {
    return true;
  } else if (!value || !value.length) {
    return "This field is required";
  }

  return true;
});
defineRule("email", email);
defineRule("numeric", numeric);

export default {
  props: ["start", "month", "defaultTime"],
  setup() {
    const $vfm = inject("$vfm");
  },
  data() {
    return {
      patient: "",
      doctor: "",
      date: this.start,
      room: "",
      patientModalCounter: 0,
      equipment: "",
      time: "",
      patientCompleted: false,
      reason: "",
      comment: "",
      patientModal: false
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    PatientAutocomplete,
    DoctorAutocomplete,
    AddPatientModal,
    RoomAutocomplete,
    EquipmentAutocomplete
  },
  watch: {
    start: function(newVal, oldVal) {
      this.date = newVal;
      if (this.month === false) {
        this.time = this.defaultTime;
      }
    }
  },
  methods: {
    submit(value) {
      console.log(this.month);

      let data = {
        title: this.patient.name,
        start: new Date(this.date + " " + this.time),
        extendedProps: {
          time: this.time,
          doctor: this.doctor.name.substring(4),
          room: this.room.name,
          equipment: this.equipment.name,
          patient: this.patient.name,
          reason: this.reason,
          comment: this.comment
        }
      };
      this.$emit("add-appointment-click", data);
    },
    patientModalToggle() {
      this.$vfm.hide("add");
      this.patientModalCounter += 1;
    },
    setPatient(data) {
      console.log(this.start);
      this.patient = data;
      this.patientCompleted = true;
    },
    setDoctor(data) {
      this.doctor = data;
    },
    setRoom(data) {
      this.room = data;
    },
    setEquipment(data) {
      this.equipment = data;
    },
    addPatient(data) {
      this.patient = data;
      this.patientCompleted = true;
    }
  }
};
</script>
