<template>
  <div class="dwv-image">
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Imaging</h2>
    </div>
    <dwvVue />
  </div>
</template>

<script>
import dwvVue from './dwv'

export default {
  name: 'app',
  components: {
    dwvVue
  }
}
</script>

<style>
.dwv-image {
  height: 100vh;
}
</style>
