<template>
  <div class="modal" id="exampleModalCenter6" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            New Medical History
          </h5>
        </div>
        <div class="p-12">
          <Form @submit="submit">
            <span>Medicine Name: </span>
            <Field
              name="name"
              rules="required"
              placeholder="Enter medicine name"
              class="form-control"
            />
            <ErrorMessage name="name" as="span"
              >Please enter a medicine!</ErrorMessage
            >
            <br />
            <br />
            <label for="dosage">Dosage: </label>
            <div class="flex">
              <div class="flex-1">
                <Field
                  name="dosageNumber"
                  rules="required|numeric"
                  class="form-control"
                />
                <ErrorMessage name="dosageNumber" as="span"
                  >Please enter a numeric value!</ErrorMessage
                >
              </div>
              <div class="ml-2">
                <Field
                  name="dosageUnit"
                  as="select"
                  rules="required"
                  class="form-control"
                >
                  <option>mg</option>
                  <option>g</option>
                  <option>tabs</option>
                </Field>
                <ErrorMessage name="dosageUnit" as="span"
                  >Please select a unit!</ErrorMessage
                >
              </div>
            </div>
            <br />
            <span>Frequency: </span>
            <Field
              name="frequency"
              rules="required"
              placeholder="Enter frequency eg. Once Every Day"
              class="form-control"
            />
            <ErrorMessage name="frequency" as="span"
              >Please enter frequency!</ErrorMessage
            >
            <div class="grid gap-4 grid-cols-4 mt-3">
              <input
                type="submit"
                class="btn btn-primary col-start-2 col-span-1"
                value="Submit"
                data-dismiss="modal"
              />
              <input
                class="btn btn-secondary col-start-3 col-span-1"
                value="Close"
                data-dismiss="modal"
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { required, email, numeric } from "@vee-validate/rules";

defineRule("required", required);
defineRule("email", email);
defineRule("numeric", numeric);

export default {
  components: {
    Form,
    Field,
    ErrorMessage
  },
  methods: {
    submit(value) {
      console.log(value);
      const data = {
        name: value.name,
        dosage: value.dosageNumber + value.dosageUnit,
        frequency: value.frequency
      };
      this.$emit("add-current-regimen", data);
    }
  }
};
</script>

<style scoped>
input + span,
select + span {
  display: block;
  color: red;
}
</style>
