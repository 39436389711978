<template>
  <div class="relative p-10">
    <input
      type="text"
      v-model="search"
      @input="onChange"
      class="form-control w-full"
      
    />
    <ul v-show="isOpen" class="autocomplete-results">
      <li v-if="isLoading" class="loading">
        Loading results...
      </li>
      <li
        v-else
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result)"
        class="autocomplete-result"
      >
        {{ result[0] + " - " + result[1] }}
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";

const API =
  "https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&df=code,name&terms=";

export default {
  data() {
    return {
      search: "",
      results: [],
      isOpen: false,
      isLoading: false
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    filterResults() {
      axios.get(API + this.search).then(res => {
        this.results = res.data[3];
        this.isLoading = false;
      });
    },
    onChange(event) {
      if (event.target.value == "") {
        this.isOpen = false;
        this.results = [];
        return;
      }
      this.isOpen = true;
      this.isLoading = true;
      this.filterResults();
    },
    setResult(result) {
      this.search = result[0] + " - " + result[1];
      this.isOpen = false;
      console.log(result[0]);
      this.$emit("set-icd", result[0]);
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    }
  }
};
</script>

<style>
.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  min-height: 1em;
  max-height: 6em;
  overflow: auto;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #4aae9b;
  color: white;
}

</style>
