<template>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header flex justify-between">
        <h5 class="modal-title">
          New Appointment
        </h5>
        <button
          type="button"
          class="close"
          aria-label="Close"
          @click="$emit('toggle')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="p-12 pt-0">
        <div class="flex justify-center m-5">
          <input
            class="form-check-switch block mr-5"
            type="checkbox"
            v-model="isRecurring"
          />
          <label class="block">Recurring Appointment</label>
        </div>
        <button
          class="btn btn-primary text-center block mx-auto mb-5"
          v-for="(template, key) in filteredTemplates"
          :key="key"
          @click="setTemplate(template)"
        >
          {{ template.name }}
        </button>

        <Form @submit="submit">
          <span>Select Title: </span>
          <Field
            name="title"
            class="form-control"
            v-model="title"
            placeholder="Enter Title..."
          />
          <br />
          <br />
          <span>Select Patient: </span>
          <PatientAutocomplete
            @set-patient="setPatient"
            @clear="clear"
            :patientCompleted="patientCompleted"
            :patient="patient"
          />
          <button>
            <a
              type="button"
              @click="$vfm.show('addPatient')"
              href="javascript:void(0)"
              class="btn btn-secondary"
              >Add Patient</a
            >
          </button>

          <br />
          <br />
          <span>Select Doctor: </span>
          <DoctorAutocomplete
            @set-doctor="setDoctor"
            :doctor="doctor"
            @clear="clear"
            :doctorCompleted="doctorCompleted"
          />
          <br />
          <br />
          <span>Select Room: </span>
          <RoomAutocomplete
            @set-room="setRoom"
            :room="room"
            @clear="clear"
            :roomCompleted="roomCompleted"
          />
          <br />
          <br />
          <span>Select Equipment: </span>
          <EquipmentAutocomplete
            @set-equipment="setEquipment"
            :equipment="equipment"
            @clear="clear"
            :equipmentCompleted="equipmentCompleted"
          />
          <br />
          <br />
          <div v-if="isRecurring">
            <select name="weekday" class="form-control" v-model="day">
              <option value="Monday" selected>Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <!-- <option value="Saturday">Saturday</option>
                <option value="Sunday">Sunday</option> -->
            </select>
          </div>
          <div v-else>
            <span>Select Start Date: </span>
            <Litepicker
              v-model="date"
              :options="{
                autoApply: false,
                showWeekNumbers: true,
                dropdowns: {
                  minYear: 1990,
                  maxYear: null,
                  months: true,
                  years: true
                }
              }"
              class="form-control w-full block"
            />
          </div>
          <br />
          <br />
          <span>Select Time: </span>
          <div
            class="w-full relative text-gray-700 dark:text-gray-300 sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0"
          >
            <Field
              name="time"
              class="form-control"
              type="time"
              v-model="time"
              rules="lol"
              placeholder="Time"
            />

            <ErrorMessage name="time" as="span" class="text-red-500"
              >Please enter a valid time!</ErrorMessage
            >
          </div>
          <br />
          <input type="checkbox" />
          <label for="virtual">Virtual Appointment</label>
          <br />
          <br />

          <!--EditPoint: Edit Vue Code to save details-->
          <span>Reason For Visit:</span><br />
          <input
            style="margin-right: 10px"
            class="form-control w-full"
            type="text"
            placeholder="E.g. Chest Pains, Fever, etc"
            v-model="reason"
          />
          <br />
          <br />
          <!--EditPoint: Edit Vue Code to save details-->
          <span>Additional Comments:</span><br />
          <textarea
            overflow-y:scroll
            style="margin-right: 10px"
            class="form-control w-full"
            placeholder="E.g. Chest Pains, Fever, etc"
            v-model="comment"
          ></textarea>
          <br />
          <br />
          <div v-for="(field, key) in customFields" :key="key" class="mb-5">
            <div class="form-check mt-2" v-if="field.type === 'checkbox'">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="field.value"
              />
              <label class="form-check-label">Chris Evans</label>
            </div>
            <div v-if="field.type === 'text'">
              <span>{{ field.label }}: </span>
              <Field
                :name="String(key)"
                class="form-control"
                type="text"
                rules="required"
                v-model="field.value"
              />
              <ErrorMessage :name="String(key)" as="span" class="text-red-500"
                >Please enter a name for your custom field!</ErrorMessage
              >
            </div>
          </div>
          <input
            type="submit"
            class="btn btn-primary mt-5 block m-auto"
            value="Confirm"
          />
        </Form>
      </div>
    </div>
  </div>
  <vue-final-modal
    v-model="patientModal"
    name="addPatient"
    classes="flex justify-center items-center max-h-screen overflow-y-scroll"
    content-class="w-500"
  >
    <AddPatientModal
      @add-patient="addPatient"
      @toggle="patientModalToggle"
      :key="patientModalCounter"
    />
  </vue-final-modal>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { email, numeric } from "@vee-validate/rules";
import PatientAutocomplete from "./PatientAutocomplete.vue";
import DoctorAutocomplete from "./DoctorAutocomplete.vue";
import RoomAutocomplete from "./RoomAutocomplete.vue";
import EquipmentAutocomplete from "./EquipmentAutocomplete.vue";
import AddPatientModal from "./AddPatientModal.vue";
import { inject } from "vue";
import { faker } from "@/utils/faker";

defineRule("lol", value => {
  if (value === null) {
    return true;
  } else if (!value || !value.length) {
    return "This field is required";
  }

  return true;
});
defineRule("email", email);
defineRule("numeric", numeric);

const recurringMapping = {
  Monday: "1",
  Tuesday: "2",
  Wednesday: "3",
  Thursday: "4",
  Friday: "5"
};

export default {
  emits: ["add-appointment", "toggle"],
  setup() {
    const $vfm = inject("$vfm");
  },
  data() {
    return {
      date: "",
      title: "",
      isRecurring: false,
      day: "Monday",
      patient: "",
      doctor: "",
      room: "",
      patientModalCounter: 0,
      equipment: "",
      time: "",
      patientCompleted: false,
      doctorCompleted: false,
      equipmentCompleted: false,
      roomCompleted: false,
      customFields: [],
      reason: "",
      comment: "",
      patientModal: false
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    PatientAutocomplete,
    DoctorAutocomplete,
    AddPatientModal,
    RoomAutocomplete,
    EquipmentAutocomplete
  },
  computed: {
    filteredTemplates: function() {
      return faker[0].templates;
    }
  },
  methods: {
    submit(value) {
      const finalTitle =
        this.title === ""
          ? `${this.patient.name}/${this.doctor.name}`
          : this.title;
      let data = {
        title: finalTitle,
        start: new Date(this.date + " " + this.time),
        extendedProps: {
          time: this.time,
          doctor: this.doctor.name.substring(4),
          room: this.room.name,
          equipment: this.equipment.name,
          patient: this.patient.name,
          reason: this.reason,
          comment: this.comment
        }
      };
      if (this.customFields.length > 0) {
        this.customFields.forEach(field => {
          data.extendedProps[field.label] = field.value;
        });
      }
      if (this.isRecurring === true) {
        delete data.start;
        data.daysOfWeek = [recurringMapping[this.day]];
        data.startTime = data.extendedProps.time;
        data.color = "red";
      }
      console.log(data);
      this.$emit("add-appointment", data);
    },
    patientModalToggle() {
      this.$vfm.hide("addPatient");
      this.patientModalCounter += 1;
    },
    setPatient(data) {
      this.patient = data;
      this.patientCompleted = true;
    },
    setDoctor(data) {
      this.doctor = data;
      this.doctorCompleted = true;
    },
    setRoom(data) {
      this.room = data;
      this.roomCompleted = true;
    },
    setEquipment(data) {
      this.equipment = data;
      this.equipmentCompleted = true;
    },
    addPatient(data) {
      console.log("lol");
      this.patient = data;
      this.patientCompleted = true;
    },
    clear(data) {
      switch (data) {
        case "patient":
          this.patientCompleted = false;
          this.patient = "";
          break;
        case "doctor":
          this.doctorCompleted = false;
          this.doctor = "";
          break;
        case "equipment":
          this.equipmentCompleted = false;
          this.equipment = "";
          break;
        case "room":
          this.roomCompleted = false;
          this.room = "";
          break;
        default:
          break;
      }
    },
    addCustomField(array) {
      this.customFields = array.slice();
      this.customFields.forEach(data => {
        if (data.type === "checkbox") {
          data.value = false;
        } else {
          data.value = "";
        }
      });
    },
    setTemplate(data) {
      Object.keys(data).forEach(key => {
        switch (key) {
          case "title":
            this.title = data[key];
            break;
          case "patient":
            this.setPatient(data[key]);
            break;
          case "doctor":
            this.setDoctor(data[key]);
            break;
          case "date":
            this.date = data[key];
            break;
          case "equipment":
            this.setEquipment(data[key]);
            break;
          case "room":
            this.setRoom(data[key]);
            break;
          case "time":
            this.time = data[key];
            break;
          case "reason":
            this.reason = data[key];
            break;
          case "comment":
            this.comment = data[key];
            break;
          case "customFields":
            this.addCustomField(data[key]);
            break;
          default:
            break;
        }
      });
    }
  }
};
</script>
