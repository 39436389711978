<template>
  <div class="flex justify-between items-center mb-5">
    <h1 class="font-bold text-4xl">Details</h1>
    <XIcon class="cursor-pointer" @click="$emit('close')" />
  </div>
  <br />

  <ul
    class="nav nav-pills mb-12 flex justify-evenly items-center"
    id="pills-tab"
    role="tablist"
  >
    <li class="nav-item" role="presentation">
      <button
        class="nav-link active py-4 px-8 rounded-lg"
        id="pills-home-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-home"
        type="button"
        role="tab"
        aria-controls="pills-home"
        aria-selected="true"
      >
        Appointment details
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link py-4 px-8 rounded-lg"
        id="pills-profile-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-profile"
        type="button"
        role="tab"
        aria-controls="pills-profile"
        aria-selected="false"
      >
        Personal Information
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link py-4 px-8 rounded-lg"
        id="pills-contact-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-contact"
        type="button"
        role="tab"
        aria-controls="pills-contact"
        aria-selected="false"
      >
        Appointment History
      </button>
    </li>
  </ul>
  <div class="tab-content" id="pills-tabContent">
    <div
      class="tab-pane fade show active text-center bg-white p-4 rounded-lg"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div class="pb-5 border-b-2">
        <div class="font-bold text-1xl">Doctor</div>
        <div>{{ params.doctor }}</div>
      </div>
      <div class="border-b-2 py-5">
        <div class="font-bold text-1xl">Patient</div>
        <div>{{ params.patient }}</div>
      </div>
      <div class="border-b-2 py-5">
        <div class="font-bold text-1xl">Date of Appointment</div>
        <div>{{ params.date }}</div>
      </div>
      <div class="border-b-2 py-5">
        <div class="font-bold text-1xl">Appointment Time</div>
        <div>{{ params.time }}</div>
      </div>
      <div class="border-b-2 py-5">
        <div class="font-bold text-1xl">Reason for Visit</div>
        <div>Consultation</div>
      </div>
      <div class="pt-5">
        <div class="font-bold text-1xl">Additional Comments</div>
        <div>Pay by FPS</div>
      </div>
    </div>
    <div
      class="tab-pane fade bg-white p-4 rounded-lg"
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <div class="md:flex mb-10">
        <div class="flex-1 md:mr-20">
          <div class="font-bold text-1xl mb-5 ">
            Name *.
          </div>
          <div>
            <input
              type="text"
              readonly
              :value="params.patient"
              class="form-control w-full mb-5 md:mb-0"
            />
          </div>
        </div>
        <div class="flex-1 md:mr-20">
          <div class="font-bold text-1xl mb-5">
            Birth Date
          </div>
          <div>
            <input
              type="text"
              readonly
              :value="patientData.dob"
              class="form-control w-full mb-5 md:mb-0"
            />
          </div>
        </div>
        <div class="flex-1 mr-20">
          <div class="font-bold text-1xl mb-5">
            Gender *
          </div>
          <div>
            <input
              type="radio"
              id="male"
              name="gender"
              value="Male"
              :checked="patientData.gender === 'Male'"
            />
            <label for="Male" class="pl-5">Male</label>
          </div>

          <div>
            <input
              type="radio"
              id="female"
              name="gender"
              value="female"
              :checked="patientData.gender === 'Female'"
            />
            <label for="Female" class="pl-5">Female</label>
          </div>
        </div>
      </div>
      <div class="mb-10">
        <div class="font-bold text-1xl mb-5">
          Address
        </div>
        <div>
          <input
            type="text"
            :value="
              patientData.address !== undefined && patientData.address.address1
            "
            readonly
            class="form-control"
          />
        </div>
      </div>
      <div class="md:flex mb-10">
        <div class="flex-1 md:mr-20">
          <div class="font-bold text-1xl mb-5">
            City
          </div>
          <div>
            <input
              type="text"
              readonly
              :value="
                patientData.address !== undefined && patientData.address.city
              "
              class="form-control mb-5 md:mb-0"
            />
          </div>
        </div>
        <div class="flex-1 md:mr-20">
          <div class="font-bold text-1xl mb-5">
            State
          </div>
          <div>
            <input
              type="text"
              readonly
              :value="
                patientData.address !== undefined && patientData.address.state
              "
              class="form-control mb-5 md:mb-0"
            />
          </div>
        </div>
        <div class="flex-1 ">
          <div class="font-bold text-1xl mb-5">
            Zip
          </div>
          <div>
            <input
              type="text"
              readonly
              :value="
                patientData.address !== undefined &&
                  patientData.address.postalCode
              "
              class="form-control mb-5 md:mb-0"
            />
          </div>
        </div>
      </div>
      <div class="md:flex mb-10">
        <div class="flex-1 md:mr-20">
          <div class="font-bold text-1xl mb-5">
            Email *
          </div>
          <div>
            <input
              type="text"
              readonly
              :value="patientData.email"
              class="form-control mb-5 md:mb-0"
            />
          </div>
        </div>
        <div class="flex-1 md:mr-20">
          <div class="font-bold text-1xl mb-5">
            Home Phone
          </div>
          <div>
            <input type="text" readonly class="form-control mb-5 md:mb-0" />
          </div>
        </div>
        <div class="flex-1 ">
          <div class="font-bold text-1xl mb-5">
            Mobile Phone
          </div>
          <div>
            <input
              type="text"
              readonly
              :value="patientData.contact"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <!-- <div class="mb-10">
        <div class="font-bold text-1xl mb-5">
          Preferred Contact Method
        </div>
        <input type="radio" class="mr-5" />Email
        <input type="radio" class="mr-5 ml-5" />Home Phone
        <input type="radio" class="mr-5 ml-5" checked />Mobile Phone
      </div>
      <div class="mb-10">
        <div class="font-bold text-1xl mb-5">
          Occupation
        </div>
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          class="form-control"
        ></textarea>
      </div>
      <div class="mb-10">
        <div class="font-bold text-1xl mb-5">
          Current Employment
        </div>
        <input type="radio" class="mr-5" />Full Time
        <input type="radio" class="mr-5 ml-5" />Part Time
        <input type="radio" class="mr-5 ml-5" />Unemployed
        <input type="radio" class="mr-5 ml-5" />Retired
        <input type="radio" class="mr-5 ml-5" />Disabled
      </div>
      <div class="mb-10">
        <div class="font-bold text-1xl mb-5">
          Emergency Contact
        </div>
        <input
          type="text"
          readonly
          value="Name, Relationship, Phone"
          class="form-control"
        />
      </div>
      <div class="mb-10">
        <div class="font-bold text-1xl mb-5">
          Do you grant permission for your personal health information to be
          shared with your emergency contact, if required for treatment?
        </div>
        <input type="radio" class="mr-5" />Yes
        <input type="radio" class="mr-5 ml-5" />No
      </div>
      <div class="mb-10">
        <div class="font-bold text-1xl mb-5">
          Were you referred?
        </div>
        <input type="radio" class="mr-5" />Yes
        <input type="radio" class="mr-5 ml-5" />No
      </div>
      <div class="font-bold text-3xl text-blue-600 mb-5">
        Demographic Information
      </div>
      <div class="flex mb-10">
        <div class="mr-10">
          <div class="font-bold text-1xl mb-5">
            Marital Status
          </div>
          <div>
            <input type="select" class="mr-5 form-control" readonly />
          </div>
        </div>
        <div class="mr-10">
          <div class="font-bold text-1xl mb-5">
            Living Situation
          </div>
          <div>
            <input type="select" class="mr-5 form-control" readonly />
          </div>
        </div>
      </div>
      <div class="font-bold text-3xl text-blue-600 mb-5">
        General Information
      </div>
      <div class="flex mb-10">
        <div class="mr-10 flex-1">
          <div class="font-bold text-1xl mb-5">
            Primary Medical Insurance Name
          </div>
          <div>
            <input type="text" class="mr-5 form-control" readonly />
          </div>
        </div>
        <div class="mr-10 flex-1">
          <div class="font-bold text-1xl mb-5">
            Policy Number
          </div>
          <div>
            <input type="text" class="mr-5 form-control" readonly />
          </div>
        </div>
        <div class="flex-1">
          <div class="font-bold text-1xl mb-5">
            Group Number
          </div>
          <div>
            <input type="text" class="mr-5 form-control" readonly />
          </div>
        </div>
      </div>
      <div class="mb-10">
        <div class="font-bold text-1xl mb-5">
          Do you have secondary medical insurance?
        </div>
        <input type="radio" class="mr-5" />Yes
        <input type="radio" class="mr-5 ml-5" />No
      </div>
      <div class="font-bold text-1xl mb-5">
        If yes, please provide the name, policy number and group number:
      </div>
      <div class="flex mb-10">
        <div class="mr-10 flex-1">
          <input type="text" value="Name" class="mr-5 form-control" readonly />
        </div>
        <div class="mr-10 flex-1">
          <input
            type="text"
            value="Policy Hunter"
            class="mr-5 form-control"
            readonly
          />
        </div>
        <div class="flex-1">
          <input
            type="text"
            value="Group Number"
            class="mr-5 form-control"
            readonly
          />
        </div>
      </div>
      <div class="font-bold text-3xl text-blue-600 mb-5">
        Current Medication Information
      </div>
      <div class="flex mb-10">
        <div class="flex-1 mr-20">
          <div class="font-bold text-1xl mb-5">
            Medication
          </div>
          <div>
            <input type="text" readonly class="form-control" />
          </div>
        </div>
        <div class="flex-1 mr-20">
          <div class="font-bold text-1xl mb-5">
            Dosage
          </div>
          <div>
            <input type="text" readonly class="form-control" />
          </div>
        </div>
        <div class="flex-1 mr-20">
          <div class="font-bold text-1xl mb-5">
            Frequency
          </div>
          <div>
            <input type="text" readonly class="form-control" />
          </div>
        </div>
        <div class="flex-1">
          <div class="font-bold text-1xl mb-5">
            Duaration
          </div>
          <div>
            <input type="text" readonly class="form-control" />
          </div>
        </div>
      </div>
      <div class="flex mb-10">
        <div class="flex-1 mr-20">
          <div class="font-bold text-1xl mb-5">
            Medication
          </div>
          <div>
            <input type="text" readonly class="form-control" />
          </div>
        </div>
        <div class="flex-1 mr-20">
          <div class="font-bold text-1xl mb-5">
            Dosage
          </div>
          <div>
            <input type="text" readonly class="form-control" />
          </div>
        </div>
        <div class="flex-1 mr-20">
          <div class="font-bold text-1xl mb-5">
            Frequency
          </div>
          <div>
            <input type="text" readonly class="form-control" />
          </div>
        </div>
        <div class="flex-1">
          <div class="font-bold text-1xl mb-5">
            Duaration
          </div>
          <div>
            <input type="text" readonly class="form-control" />
          </div>
        </div>
      </div>
      <div class="flex mb-10">
        <div class="flex-1 mr-20">
          <div class="font-bold text-1xl mb-5">
            Medication
          </div>
          <div>
            <input type="text" readonly class="form-control" />
          </div>
        </div>
        <div class="flex-1 mr-20">
          <div class="font-bold text-1xl mb-5">
            Dosage
          </div>
          <div>
            <input type="text" readonly class="form-control" />
          </div>
        </div>
        <div class="flex-1 mr-20">
          <div class="font-bold text-1xl mb-5">
            Frequency
          </div>
          <div>
            <input type="text" readonly class="form-control" />
          </div>
        </div>
        <div class="flex-1">
          <div class="font-bold text-1xl mb-5">
            Duaration
          </div>
          <div>
            <input type="text" readonly class="form-control" />
          </div>
        </div>
      </div>
      <div class="font-bold text-3xl text-blue-600 mb-5">
        Allergies
      </div>
      <div class="mb-10">
        <div class="font-bold text-1xl mb-5">
          Medical Intolerance
        </div>
        <input type="radio" class="mr-5" />Yes
        <input type="radio" class="mr-5 ml-5" />No
      </div>
      <div class="mb-10">
        <div class="font-bold text-1xl mb-5">
          Food Allergies
        </div>
        <input type="radio" class="mr-5" />Yes
        <input type="radio" class="mr-5 ml-5" />No
      </div>
      <div class="font-bold text-3xl text-blue-600 mb-5">
        Other Drug Use
      </div>
      <div class="mb-10">
        <div class="font-bold text-1xl mb-5">
          Tobacco
        </div>
        <input type="radio" class="mr-5" />Yes
        <input type="radio" class="mr-5 ml-5" />No
      </div>
      <div class="mb-10">
        <div class="font-bold text-1xl mb-5">
          Alcohol
        </div>
        <input type="radio" class="mr-5" />Yes
        <input type="radio" class="mr-5 ml-5" />No
      </div>
      <div class="mb-10">
        <div class="font-bold text-1xl mb-5">
          Caffeine
        </div>
        <input type="radio" class="mr-5" />Yes
        <input type="radio" class="mr-5 ml-5" />No
      </div>
      <div class="mb-10">
        <div class="font-bold text-1xl mb-5">
          Hallucinogens / Cocaine / Other drugs
        </div>
        <input type="radio" class="mr-5" />Yes
        <input type="radio" class="mr-5 ml-5" />No
      </div>
      <div class="mb-10">
        <div class="font-bold text-1xl mb-5">
          All Fields Completed
        </div>
        <input type="checkbox" class="mr-5" />
      </div>
    </div> -->
      <div
        @click="
          $router.push({
            name: 'side-menu-profile-overview-1',
            params: { repository: JSON.stringify(patientData) }
          })
        "
        class="text-blue-500 underline cursor-pointer"
      >
        Click Here for more info!
      </div>
    </div>
    <div
      class="tab-pane fade bg-white p-4 rounded-lg  p-5"
      id="pills-contact"
      role="tabpanel"
      aria-labelledby="pills-contact-tab"
    >
      <div class="overflow-x-auto">
        <table class="table ">
          <thead>
            <tr>
              <th
                class="border border-b-2 bg-blue-500 dark:border-dark-5 whitespace-nowrap"
              >
                Date of appointment
              </th>
              <th
                class="border border-b-2 dark:border-dark-5 bg-blue-500 whitespace-nowrap"
              >
                Appointment Time
              </th>
              <th
                class="border border-b-2 dark:border-dark-5 bg-blue-500 whitespace-nowrap"
              >
                Doctor
              </th>
              <th
                class="border border-b-2 dark:border-dark-5 bg-blue-500 whitespace-nowrap"
              >
                Patient
              </th>
              <th
                class="border border-b-2 dark:border-dark-5  bg-blue-500 whitespace-nowrap"
              >
                Reason for visit
              </th>
              <th
                class="border border-b-2 dark:border-dark-5 bg-blue-500 whitespace-nowrap"
              >
                Additional Comments
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              v-for="(d, key) in history"
              :key="key"
            >
              <td
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                {{ d.date }}
              </td>
              <td
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                {{ d.time }}
              </td>
              <td
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                {{ d.doctor }}
              </td>
              <td
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                {{ d.patient }}
              </td>
              <td
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                {{ d.reason }}
              </td>
              <td
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                {{ d.comments }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { faker } from "../../utils/faker";
export default defineComponent({
  props: ["params"],
  emits: ["close"],
  data() {
    return {
      patientData: {},
      history: [
        {
          date: "7/28/2021",
          time: "01:08 PM",
          doctor: "Brian Mendoza",
          patient: "Ken Adams",
          reason: "Consultation",
          comments: "FPS payment"
        },
        {
          date: "7/28/2021",
          time: "01:08 PM",
          doctor: "Brian Mendoza",
          patient: "Ken Adams",
          reason: "Consultation",
          comments: "FPS payment"
        },
        {
          date: "7/28/2021",
          time: "01:08 PM",
          doctor: "Brian Mendoza",
          patient: "Ken Adams",
          reason: "Consultation",
          comments: "FPS payment"
        }
      ],
      activeItem: "appointment"
    };
  },
  watch: {
    params: function(newVal, oldVal) {
      let obj1 = faker[0].users.filter(
        data => data.name === this.params.patient
      )[0];
      let obj2 = faker[0].userDetails.filter(
        data => data.name === this.params.patient
      )[0];
      this.patientData = { ...obj1, ...obj2 };
    }
  },
  methods: {
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    }
  }
});
</script>

<style scoped>
button[aria-selected="true"] {
  background-color: #0d6efd;
  color: white;
  border: none;
  outline: 0;
}
button[aria-selected="true"]:hover {
  border: none;
  outline: 0;
}
</style>
