<template>
  <!-- Modal -->
  <div id="exampleModalCenter1" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLongTitle" class="modal-title">
            Update Branch Hours:
          </h5>
        </div>
        <div class="p-6">
          <Form @submit="onSubmit">
            <h2>Business Hours</h2>
            <div
              v-for="(work, index) in workinghours"
              :key="index"
              class="flex flex-row items-start form-group pb-2"
            >
              <div class="mr-2 w-20">{{ work.name }}</div>
              <input
                id="checkbox"
                v-model="work.isOpen"
                class="mr-2 form-check-switch"
                type="checkbox"
              />
              <label v-if="work.isOpen" for="checkbox" class="mr-2">Open</label>
              <label v-if="!work.isOpen" for="checkbox" class="mr-2"
                >Closed</label
              >

              <div v-if="work.isOpen" class="flex flex-row">
                <div>
                  <input
                  id="checkbox"
                  v-model="work.is24"
                  class="mr-2 form-check-switch"
                  type="checkbox"
                />
                <label for="checkbox" class="mr-2">24 hrs</label>
                </div>
                <div v-if="!work.is24">
                  <div
                    v-for="(regular, k) in work.regularhours"
                    :key="k"
                    class="form-group grid grid-cols-4"
                  >
                    <input
                      v-model="regular.open"
                      type="time"
                      class="form-control intro-y"
                      rules="required"
                    />
                    <input
                      v-model="regular.close"
                      class="form-control intro-y"
                      type="time"
                      rules="required"
                    />
                    <div class="flex flex-row">
                      <div
                        v-show="k || (!k && work.regularhours.length > 1)"
                        class="cursor-pointer btn btn-secondary"
                        @click="remove(k, index)"
                      >
                        Remove
                      </div>
                      <span
                        v-show="k == work.regularhours.length - 1"
                        class="cursor-pointer flex btn btn-secondary"
                        @click="add(k, index)"
                        >Add
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <input
              style="
                width: 75px;
                margin-left: 115px;
                margin-right: 10px
                margin-top: 15px;
              "
              type="submit"
              class="btn btn-primary"
              data-dismiss="modal"
              value="Update"
            />
            <input
              style="width: 75px; margin-top: 15px"
              class="btn btn-secondary"
              value="Close"
              data-dismiss="modal"
              @click="close"
            />
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { numeric } from "@vee-validate/rules";

defineRule("lol", (value) => {
  if (value === null || value === undefined) {
    return true;
  } else if (!value || !value.length) {
    return false;
  }

  return true;
});
defineRule("customEmail", (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }

  // Check if email
  if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  ) {
    return false;
  }

  return true;
});
defineRule("numeric", numeric);

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: ["branchInfo"],
  data() {
    return {
      workinghours: { ...this.branchInfo.hours },
      holidays: [],
    };
  },
  watch: {
    branchData: function () {
      this.init();
    },
  },
  methods: {
    add(k, index) {
      this.workinghours[index].regularhours.push({
        open: "",
        close: "",
      });
    },

    remove(k, index) {
      this.workinghours[index].regularhours.splice(k, 1);
    },
    onSubmit() {
      let data = this.workinghours;
      this.$emit("update-branchhours", data);
    },
  },
};
</script>

<style scoped>
input + span,
select + span {
  display: block;
  color: red;
}
</style>
