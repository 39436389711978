<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Profile Layout</h2>
    </div>
    <!-- BEGIN: Profile Info -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5"
      >
        <div
          class="flex flex-1 px-5 items-center justify-center lg:justify-start"
        >
          <div
            class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative"
          >
            <img
              alt="Mazecare Admin Demo 1"
              class="rounded-full"
              :src="require(`@/assets/images/${$f()[0].photos[0]}`)"
            />
            <div
              class="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-theme-17 rounded-full p-2"
            >
              <CameraIcon class="w-4 h-4 text-white" />
            </div>
          </div>
          <div class="ml-5">
            <div
              class="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg"
            >
              {{
                patientInfo !== undefined
                  ? patientInfo.name
                  : $f()[0].users[0].name
              }}
            </div>
            <div class="text-gray-600">Patient ID: {{ $f()[0].ID }}</div>
          </div>
        </div>
        <div
          class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
        >
          <div class="font-medium text-center lg:text-left lg:mt-3">
            Contact Details
          </div>
          <div
            class="flex flex-col justify-center items-center lg:items-start mt-4"
          >
            <div class="truncate sm:whitespace-normal flex items-center">
              <MailIcon class="w-4 h-4 mr-2" />
              {{
                patientInfo !== undefined
                  ? patientInfo.email
                  : $f()[0].users[0].email
              }}
            </div>
            <div class="truncate sm:whitespace-normal flex items-center">
              <MailIcon class="w-4 h-4 mr-2" />
              {{
                patientInfo !== undefined
                  ? patientInfo.address.address1
                  : $f()[0].patientProfile[0].address
              }}
            </div>
            <div class="truncate sm:whitespace-normal flex items-center">
              <VoicemailIcon class="w-4 h-4 mr-2" />
              {{
                patientInfo !== undefined
                  ? patientInfo.contact
                  : $f()[0].patientProfile[0].contact
              }}
            </div>
          </div>
        </div>
        <div
          class="mt-6 lg:mt-0 flex-1 px-5 border-t lg:border-0 border-gray-200 dark:border-dark-5 pt-5 lg:pt-0 text-center"
        >
          <div class="font-medium lg:text-left lg:mt-3">
            Patient Information
          </div>
          <br />
          <div class="truncate sm:whitespace-normal flex items-center">
            Name:
            {{
              patientInfo !== undefined
                ? patientInfo.name
                : $f()[0].users[0].name
            }}
          </div>
          <div class="truncate sm:whitespace-normal flex items-center">
            Date of Birth:
            {{
              patientInfo !== undefined
                ? patientInfo.dob
                : $f()[0].patientProfile[0].dob
            }}
          </div>
          <div class="truncate sm:whitespace-normal flex items-center">
            Gender:
            {{
              patientInfo !== undefined
                ? patientInfo.gender
                : $f()[0].patientProfile[0].gender
            }}
          </div>
          <div class="truncate sm:whitespace-normal flex items-center">
            Blood Group:
            {{
              patientInfo !== undefined
                ? patientInfo.bloodGroups
                : $f()[0].userDetails[0].bloodGroups
            }}
          </div>
        </div>
      </div>
      <div
        class="nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start"
      >
        <div
          class="py-4 sm:mr-8 cursor-pointer nav-item nav-link"
          :class="{ active: anchor === 'dashboard' }"
          @click="setAnchor('dashboard')"
        >
          Dashboard
        </div>
        <div
          class="py-4 sm:mr-8 cursor-pointer nav-item nav-link"
          :class="{ active: anchor === 'info' }"
          @click="setAnchor('info')"
        >
          Infos
        </div>
        <div
          class="py-4 sm:mr-8 cursor-pointer nav-item nav-link"
          :class="{ active: anchor === 'records' }"
          @click="setAnchor('records')"
        >
          Medical & Health Records
        </div>
        <div
          class="py-4 sm:mr-8 cursor-pointer nav-item nav-links"
          :class="{ active: anchor === 'diagnosis' }"
          @click="setAnchor('diagnosis')"
        >
          Diagnosis
        </div>
      </div>
    </div>
    <!-- END: Profile Info -->
    <div class="intro-y tab-content mt-5">
      <div v-if="anchor === 'dashboard'">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Top Categories -->
          <div class="intro-y box col-span-12 lg:col-span-6">
            <div
              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">Top Visualizations</h2>
              <div class="dropdown ml-auto">
                <a
                  class="dropdown-toggle w-5 h-5 block"
                  href="javascript:;"
                  aria-expanded="false"
                >
                  <MoreHorizontalIcon
                    class="w-5 h-5 text-gray-600 dark:text-gray-300"
                  />
                </a>
                <div class="dropdown-menu w-40">
                  <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <PlusIcon class="w-4 h-4 mr-2" /> Add Visualization
                    </a>
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <SettingsIcon class="w-4 h-4 mr-2" />
                      Settings
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-5">
              <div class="flex flex-col sm:flex-row">
                <div class="mr-auto">
                  <a href="" class="font-medium">Visualization 1</a>
                  <div class="text-gray-600 mt-1">Viz Category 1</div>
                </div>
                <div class="flex">
                  <div class="w-32 -ml-2 sm:ml-0 mt-5 mr-auto sm:mr-5">
                    <SimpleLineChart :height="50" />
                  </div>
                  <div class="text-center">
                    <div class="font-medium">6.5k</div>
                    <div class="bg-theme-29 text-theme-10 rounded px-2 mt-1.5">
                      +150
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-col sm:flex-row mt-5">
                <div class="mr-auto">
                  <a href="" class="font-medium">Visualization 2</a>
                  <div class="text-gray-600 mt-1">Viz Category 1</div>
                </div>
                <div class="flex">
                  <div class="w-32 -ml-2 sm:ml-0 mt-5 mr-auto sm:mr-5">
                    <SimpleLineChart :height="50" />
                  </div>
                  <div class="text-center">
                    <div class="font-medium">2.5k</div>
                    <div class="bg-theme-30 text-theme-24 rounded px-2 mt-1.5">
                      +150
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-col sm:flex-row mt-5">
                <div class="mr-auto">
                  <a href="" class="font-medium">Visualization 3</a>
                  <div class="text-gray-600 mt-1">Viz Category 1</div>
                </div>
                <div class="flex">
                  <div class="w-32 -ml-2 sm:ml-0 mt-5 mr-auto sm:mr-5">
                    <SimpleLineChart :height="50" />
                  </div>
                  <div class="text-center">
                    <div class="font-medium">3.4k</div>
                    <div class="bg-theme-31 text-theme-26 rounded px-2 mt-1.5">
                      +150
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Top Categories -->
          <!-- BEGIN: Work In Progress -->
          <div class="intro-y box col-span-12 lg:col-span-6">
            <div
              class="flex items-center px-5 py-5 sm:py-0 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">Work In Progress</h2>
              <div class="dropdown ml-auto sm:hidden">
                <a
                  class="dropdown-toggle w-5 h-5 block"
                  href="javascript:;"
                  aria-expanded="false"
                >
                  <MoreHorizontalIcon
                    class="w-5 h-5 text-gray-600 dark:text-gray-300"
                  />
                </a>
                <div class="nav nav-tabs dropdown-menu w-40" role="tablist">
                  <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                    <a
                      id="work-in-progress-new-tab"
                      href="javascript:;"
                      data-toggle="tab"
                      data-target="#work-in-progress-new"
                      class="block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      role="tab"
                      aria-controls="work-in-progress-new"
                      aria-selected="true"
                      >New</a
                    >
                    <a
                      id="work-in-progress-last-week-tab"
                      href="javascript:;"
                      data-toggle="tab"
                      data-target="#work-in-progress-last-week"
                      class="block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      role="tab"
                      aria-selected="false"
                      >Last Week</a
                    >
                  </div>
                </div>
              </div>
              <div class="nav nav-tabs ml-auto hidden sm:flex" role="tablist">
                <a
                  id="work-in-progress-mobile-new-tab"
                  data-toggle="tab"
                  data-target="#work-in-progress-new"
                  href="javascript:;"
                  class="py-5 ml-6 active"
                  role="tab"
                  aria-selected="true"
                  >New</a
                >
                <a
                  id="week-work-in-progress-mobile-last-week-tab"
                  data-toggle="tab"
                  data-target="#work-in-progress-last-week"
                  href="javascript:;"
                  class="py-5 ml-6"
                  role="tab"
                  aria-selected="false"
                  >Last Week</a
                >
              </div>
            </div>
            <div class="p-5">
              <div class="tab-content">
                <div
                  id="work-in-progress-new"
                  class="tab-pane active"
                  role="tabpanel"
                  aria-labelledby="work-in-progress-new-tab"
                >
                  <div>
                    <div class="flex">
                      <div class="mr-auto">Pending Tasks</div>
                      <div>20%</div>
                    </div>
                    <div class="progress h-1 mt-2">
                      <div
                        class="progress-bar w-1/2 bg-theme-17"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div class="mt-5">
                    <div class="flex">
                      <div class="mr-auto">Completed Tasks</div>
                      <div>2 / 20</div>
                    </div>
                    <div class="progress h-1 mt-2">
                      <div
                        class="progress-bar w-1/4 bg-theme-17"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div class="mt-5">
                    <div class="flex">
                      <div class="mr-auto">Tasks In Progress</div>
                      <div>42</div>
                    </div>
                    <div class="progress h-1 mt-2">
                      <div
                        class="progress-bar w-3/4 bg-theme-17"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <a href="" class="btn btn-secondary block w-40 mx-auto mt-5"
                    >View More Details</a
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- END: Work In Progress -->
          <!-- BEGIN: Daily Sales -->
          <div class="intro-y box col-span-12 lg:col-span-6">
            <div
              class="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">
                Latest Medical & Records
              </h2>
              <div class="dropdown ml-auto sm:hidden">
                <a
                  class="dropdown-toggle w-5 h-5 block"
                  href="javascript:;"
                  aria-expanded="false"
                >
                  <MoreHorizontalIcon
                    class="w-5 h-5 text-gray-600 dark:text-gray-300"
                  />
                </a>
                <div class="dropdown-menu w-40">
                  <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                    <a
                      href="javascript:;"
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <FileIcon class="w-4 h-4 mr-2" /> Download Excel
                    </a>
                  </div>
                </div>
              </div>
              <button class="btn btn-outline-secondary hidden sm:flex">
                <FileIcon class="w-4 h-4 mr-2" /> Download Excel
              </button>
            </div>
            <div class="p-5">
              <div class="relative flex items-center">
                <div class="w-12 h-12 flex-none image-fit">
                  <img
                    alt="Mazecare Admin Demo 1"
                    class="rounded-full"
                    :src="require(`@/assets/images/${$f()[0].photos[0]}`)"
                  />
                </div>
                <div class="ml-4 mr-auto">
                  <a href="" class="font-medium">{{ $f()[0].users[0].name }}</a>
                  <div class="text-gray-600 mr-5 sm:mr-5">
                    Bootstrap 4 HTML Admin Template
                  </div>
                </div>
                <div class="font-medium text-gray-700 dark:text-gray-600">
                  +$19
                </div>
              </div>
              <div class="relative flex items-center mt-5">
                <div class="w-12 h-12 flex-none image-fit">
                  <img
                    alt="Mazecare Admin Demo 1"
                    class="rounded-full"
                    :src="require(`@/assets/images/${$f()[1].photos[0]}`)"
                  />
                </div>
                <div class="ml-4 mr-auto">
                  <a href="" class="font-medium">{{ $f()[1].users[0].name }}</a>
                  <div class="text-gray-600 mr-5 sm:mr-5">
                    Tailwind HTML Admin Template
                  </div>
                </div>
                <div class="font-medium text-gray-700 dark:text-gray-600">
                  +$25
                </div>
              </div>
              <div class="relative flex items-center mt-5">
                <div class="w-12 h-12 flex-none image-fit">
                  <img
                    alt="Mazecare Admin Demo 1"
                    class="rounded-full"
                    :src="require(`@/assets/images/${$f()[2].photos[0]}`)"
                  />
                </div>
                <div class="ml-4 mr-auto">
                  <a href="" class="font-medium">{{ $f()[2].users[0].name }}</a>
                  <div class="text-gray-600 mr-5 sm:mr-5">
                    Vuejs HTML Admin Template
                  </div>
                </div>
                <div class="font-medium text-gray-700 dark:text-gray-600">
                  +$21
                </div>
              </div>
            </div>
          </div>
          <!-- END: Daily Sales -->
          <!-- BEGIN: Latest Tasks -->
          <div class="intro-y box col-span-12 lg:col-span-6">
            <div
              class="flex items-center px-5 py-5 sm:py-0 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">Latest Tasks</h2>
              <div class="dropdown ml-auto sm:hidden">
                <a
                  class="dropdown-toggle w-5 h-5 block"
                  href="javascript:;"
                  aria-expanded="false"
                >
                  <MoreHorizontalIcon
                    class="w-5 h-5 text-gray-600 dark:text-gray-300"
                  />
                </a>
                <div class="nav nav-tabs dropdown-menu w-40" role="tablist">
                  <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                    <a
                      id="latest-tasks-new-tab"
                      href="javascript:;"
                      data-toggle="tab"
                      data-target="#latest-tasks-new"
                      class="block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      role="tab"
                      aria-controls="latest-tasks-new"
                      aria-selected="true"
                      >New</a
                    >
                    <a
                      id="latest-tasks-last-week-tab"
                      href="javascript:;"
                      data-toggle="tab"
                      data-target="#latest-tasks-last-week"
                      class="block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      role="tab"
                      aria-selected="false"
                      >Last Week</a
                    >
                  </div>
                </div>
              </div>
              <div class="nav nav-tabs ml-auto hidden sm:flex" role="tablist">
                <a
                  id="latest-tasks-mobile-new-tab"
                  data-toggle="tab"
                  data-target="#latest-tasks-new"
                  href="javascript:;"
                  class="py-5 ml-6 active"
                  role="tab"
                  aria-selected="true"
                  >New</a
                >
                <a
                  id="latest-tasks-mobile-last-week-tab"
                  data-toggle="tab"
                  data-target="#latest-tasks-last-week"
                  href="javascript:;"
                  class="py-5 ml-6"
                  role="tab"
                  aria-selected="false"
                  >Last Week</a
                >
              </div>
            </div>
            <div class="p-5">
              <div class="tab-content">
                <div
                  id="latest-tasks-new"
                  class="tab-pane active"
                  role="tabpanel"
                  aria-labelledby="latest-tasks-new-tab"
                >
                  <div class="flex items-center">
                    <div
                      class="border-l-2 border-theme-17 dark:border-theme-26 pl-4"
                    >
                      <a href="" class="font-medium">Request Test</a>
                      <div class="text-gray-600">10:00 AM</div>
                    </div>
                    <input class="form-check-switch ml-auto" type="checkbox" />
                  </div>
                  <div class="flex items-center mt-5">
                    <div
                      class="border-l-2 border-theme-17 dark:border-theme-26 pl-4"
                    >
                      <a href="" class="font-medium">Meeting With Doctor</a>
                      <div class="text-gray-600">02:00 PM</div>
                    </div>
                    <input class="form-check-switch ml-auto" type="checkbox" />
                  </div>
                  <div class="flex items-center mt-5">
                    <div
                      class="border-l-2 border-theme-17 dark:border-theme-26 pl-4"
                    >
                      <a href="" class="font-medium">Prescribe</a>
                      <div class="text-gray-600">04:00 PM</div>
                    </div>
                    <input class="form-check-switch ml-auto" type="checkbox" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Latest Tasks -->
          <!-- BEGIN: General Statistic -->
          <div class="intro-y box col-span-12">
            <div
              class="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">General Statistics</h2>
              <div class="dropdown ml-auto sm:hidden">
                <a
                  class="dropdown-toggle w-5 h-5 block"
                  href="javascript:;"
                  aria-expanded="false"
                >
                  <MoreHorizontalIcon
                    class="w-5 h-5 text-gray-600 dark:text-gray-300"
                  />
                </a>
                <div class="dropdown-menu w-40">
                  <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                    <a
                      href="javascript:;"
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <FileIcon class="w-4 h-4 mr-2" /> Download XML
                    </a>
                  </div>
                </div>
              </div>
              <button class="btn btn-outline-secondary hidden sm:flex">
                <FileIcon class="w-4 h-4 mr-2" /> Download XML
              </button>
            </div>
            <div class="grid grid-cols-1 xxl:grid-cols-7 gap-6 p-5">
              <div class="xxl:col-span-2">
                <div class="grid grid-cols-2 gap-6">
                  <div
                    class="col-span-2 sm:col-span-1 xxl:col-span-2 box dark:bg-dark-5 p-5"
                  >
                    <div class="font-medium">Net Worth</div>
                    <div class="flex items-center mt-1 sm:mt-0">
                      <div class="mr-4 w-20 flex">
                        USP:
                        <span class="ml-3 font-medium text-theme-10">+23%</span>
                      </div>
                      <div class="w-5/6 overflow-auto">
                        <SimpleLineChart :height="60" />
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-span-2 sm:col-span-1 xxl:col-span-2 box dark:bg-dark-5 p-5"
                  >
                    <div class="font-medium">Sales</div>
                    <div class="flex items-center mt-1 sm:mt-0">
                      <div class="mr-4 w-20 flex">
                        USP:
                        <span class="ml-3 font-medium text-theme-24">-5%</span>
                      </div>
                      <div class="w-5/6 overflow-auto">
                        <SimpleLineChart :height="60" />
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-span-2 sm:col-span-1 xxl:col-span-2 box dark:bg-dark-5 p-5"
                  >
                    <div class="font-medium">Profit</div>
                    <div class="flex items-center mt-1 sm:mt-0">
                      <div class="mr-4 w-20 flex">
                        USP:
                        <span class="ml-3 font-medium text-theme-24">-10%</span>
                      </div>
                      <div class="w-5/6 overflow-auto">
                        <SimpleLineChart :height="60" />
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-span-2 sm:col-span-1 xxl:col-span-2 box dark:bg-dark-5 p-5"
                  >
                    <div class="font-medium">Products</div>
                    <div class="flex items-center mt-1 sm:mt-0">
                      <div class="mr-4 w-20 flex">
                        USP:
                        <span class="ml-3 font-medium text-theme-10">+55%</span>
                      </div>
                      <div class="w-5/6 overflow-auto">
                        <SimpleLineChart :height="60" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="xxl:col-span-5 w-full">
                <div class="flex justify-center mt-8">
                  <div class="flex items-center mr-5">
                    <div class="w-2 h-2 bg-theme-17 rounded-full mr-3"></div>
                    <span>Product Profit</span>
                  </div>
                  <div class="flex items-center">
                    <div class="w-2 h-2 bg-gray-400 rounded-full mr-3"></div>
                    <span>Author Sales</span>
                  </div>
                </div>
                <div class="report-chart mt-8">
                  <StackedBarChart1 :height="130" />
                </div>
              </div>
            </div>
          </div>
          <!-- END: General Statistic -->
        </div>
      </div>
      <div v-if="anchor === 'records'">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: General Record -->
          <div class="intro-y box col-span-12 lg:col-span-6">
            <div
              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">General Record</h2>
              <div class="dropdown ml-auto">
                <a
                  class="dropdown-toggle w-5 h-5 block"
                  href="javascript:;"
                  aria-expanded="false"
                >
                  <MoreHorizontalIcon
                    class="w-5 h-5 text-gray-600 dark:text-gray-300"
                  />
                </a>
                <div class="dropdown-menu w-40">
                  <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <PlusIcon class="w-4 h-4 mr-2" /> Add General Record
                    </a>
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <SettingsIcon class="w-4 h-4 mr-2" />
                      Settings
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-5">
              <div class="overflow-x-auto">
                <table class="table">
                  <tbody>
                    <tr>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Height
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        <ul>
                          <li>
                            Metric: {{ $f()[0].general[0].height }} meters
                          </li>
                          <li>
                            Standard: {{ $f()[0].general[0].height2 }} feet
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Weight
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        <ul>
                          <li>
                            Metric: {{ $f()[0].general[0].weight }} kilograms
                          </li>
                          <li>
                            Standard: {{ $f()[0].general[0].weight2 }} pounds
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        BMI
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ $f()[0].general[0].bmi }}
                      </td>
                      <p id="demo"></p>
                    </tr>
                    <tr>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Blood Pressure
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ $f()[0].general[0].bp }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Eye Sight
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ $f()[0].general[0].eyesight }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Oral Hygiene
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ $f()[0].general[0].oral }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- END: General Records -->
          <!-- BEGIN: Top Categories -->
          <div class="intro-y box col-span-12 lg:col-span-6">
            <div
              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">Medical History</h2>
              <div class="dropdown ml-auto">
                <a
                  class="dropdown-toggle w-5 h-5 block"
                  href="javascript:;"
                  aria-expanded="false"
                >
                  <MoreHorizontalIcon
                    class="w-5 h-5 text-gray-600 dark:text-gray-300"
                  />
                </a>
                <div class="dropdown-menu w-40">
                  <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <PlusIcon class="w-4 h-4 mr-2" />
                      <a
                        type="button"
                        href="javascript:void(0)"
                        data-toggle="modal"
                        data-target="#exampleModalCenter3"
                        >Add Medical History</a
                      >
                    </a>
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <SettingsIcon class="w-4 h-4 mr-2" />
                      Settings
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-5">
              <div
                class="flex items-center mt-5 sm:flex-row"
                v-for="(history, key) in data[0].medicalHistory"
                :key="key"
              >
                <div class="mr-auto">
                  <div href="" class="font-medium">{{ history.illness }}</div>
                </div>
                <div class="w-32 -ml-2 sm:ml-0  mr-auto sm:mr-5">
                  {{ history.diagnosed }}
                </div>
              </div>
            </div>
          </div>
          <!-- END: Top Categories -->

          <!-- BEGIN: Doctors Consulted -->
          <div class="intro-y box col-span-12 lg:col-span-6">
            <div
              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">
                Doctors Consultations
              </h2>
              <div class="dropdown ml-auto">
                <a
                  class="dropdown-toggle w-5 h-5 block"
                  href="javascript:;"
                  aria-expanded="false"
                >
                  <MoreHorizontalIcon
                    class="w-5 h-5 text-gray-600 dark:text-gray-300"
                  />
                </a>
                <div class="dropdown-menu w-40">
                  <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <PlusIcon class="w-4 h-4 mr-2" />
                      <a
                        type="button"
                        href="javascript:void(0)"
                        data-toggle="modal"
                        data-target="#exampleModalCenter4"
                        >Add Doctor Consultation</a
                      >
                    </a>
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <SettingsIcon class="w-4 h-4 mr-2" />
                      Settings
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-5">
              <div class="overflow-x-auto">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        #
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Name of doctor consulted
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Specialization of doctor
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Date of consultation
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Reason for consultation
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Medicine Prescribed
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      v-for="(d, key) in data[0].doctor"
                      :key="key"
                    >
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ key + 1 }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ d.doc }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ d.spec }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ d.date }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ d.reason }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ d.medicine }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- END: Doctors Consulted -->

          <!-- BEGIN: Work In Progress -->
          <div class="intro-y box col-span-12 lg:col-span-6">
            <div
              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">Current Regimen</h2>
              <div class="dropdown ml-auto">
                <a
                  class="dropdown-toggle w-5 h-5 block"
                  href="javascript:;"
                  aria-expanded="false"
                >
                  <MoreHorizontalIcon
                    class="w-5 h-5 text-gray-600 dark:text-gray-300"
                  />
                </a>
                <div class="dropdown-menu w-40">
                  <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <PlusIcon class="w-4 h-4 mr-2" />
                      <a
                        type="button"
                        href="javascript:void(0)"
                        data-toggle="modal"
                        data-target="#exampleModalCenter6"
                        >Add Medicine</a
                      >
                    </a>
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <SettingsIcon class="w-4 h-4 mr-2" />
                      Settings
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-5">
              <div class="overflow-x-auto">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        #
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Medicine
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Dosage
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Frequency
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(med, key) in data[0].regimen" :key="key">
                      <td class="border">{{ key + 1 }}</td>
                      <td class="border">{{ med.name }}</td>
                      <td class="border">{{ med.dosage }}</td>
                      <td class="border">
                        {{ med.frequency }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- END: Work In Progress -->
          <!-- BEGIN: Daily Sales -->
          <div class="intro-y box col-span-12 lg:col-span-6">
            <div
              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">
                Immunization Records
              </h2>
              <div class="dropdown ml-auto">
                <a
                  class="dropdown-toggle w-5 h-5 block"
                  href="javascript:;"
                  aria-expanded="false"
                >
                  <MoreHorizontalIcon
                    class="w-5 h-5 text-gray-600 dark:text-gray-300"
                  />
                </a>
                <div class="dropdown-menu w-40">
                  <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <PlusIcon class="w-4 h-4 mr-2" />
                      <a
                        type="button"
                        href="javascript:void(0)"
                        data-toggle="modal"
                        data-target="#exampleModalCenter7"
                        >Add Immunization</a
                      >
                    </a>
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <SettingsIcon class="w-4 h-4 mr-2" />
                      Settings
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-5">
              <div class="overflow-x-auto">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        #
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Disease
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Type
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(vaccine, key) in data[0].immunization"
                      :key="key"
                    >
                      <td class="border">{{ key + 1 }}</td>
                      <td class="border">{{ vaccine.disease }}</td>
                      <td class="border">{{ vaccine.type }}</td>
                      <td class="border">{{ vaccine.date }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- END: Daily Sales -->
          <!-- BEGIN: Drug allergy -->
          <div class="intro-y box col-span-12 lg:col-span-6">
            <div
              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">
                Drug Allergy Records
              </h2>
              <div class="dropdown ml-auto">
                <a
                  class="dropdown-toggle w-5 h-5 block"
                  href="javascript:;"
                  aria-expanded="false"
                >
                  <MoreHorizontalIcon
                    class="w-5 h-5 text-gray-600 dark:text-gray-300"
                  />
                </a>
                <div class="dropdown-menu w-40">
                  <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <PlusIcon class="w-4 h-4 mr-2" />
                      <a
                        type="button"
                        href="javascript:void(0)"
                        data-toggle="modal"
                        data-target="#exampleModalCenter8"
                        >Add Drug Allergy Records</a
                      >
                    </a>
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <SettingsIcon class="w-4 h-4 mr-2" />
                      Settings
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-5">
              <div class="overflow-x-auto">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        #
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Allergen/ Allergen Group
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Level of certainity
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Clinical Manifestation
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      v-for="(d, key) in data[0].drugAllergy"
                      :key="key"
                    >
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ key + 1 }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ d.allergen }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ d.certainity }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ d.manifestation }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- END: Drug allergy -->
          <!-- BEGIN: Food allergy -->
          <div class="intro-y box col-span-12 lg:col-span-6">
            <div
              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">
                Food Allergy Records
              </h2>
              <div class="dropdown ml-auto">
                <a
                  class="dropdown-toggle w-5 h-5 block"
                  href="javascript:;"
                  aria-expanded="false"
                >
                  <MoreHorizontalIcon
                    class="w-5 h-5 text-gray-600 dark:text-gray-300"
                  />
                </a>
                <div class="dropdown-menu w-40">
                  <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <PlusIcon class="w-4 h-4 mr-2" />
                      <a
                        type="button"
                        href="javascript:void(0)"
                        data-toggle="modal"
                        data-target="#exampleModalCenter5"
                        >Add Food Allergy Records</a
                      >
                    </a>
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <SettingsIcon class="w-4 h-4 mr-2" />
                      Settings
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-5">
              <div class="overflow-x-auto">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        #
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Allergen/ Allergen Group
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Level of certainity
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Clinical Manifestation
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      v-for="(f, key) in data[0].foodAllergy"
                      :key="key"
                    >
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ key + 1 }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ f.allergen }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ f.certainity }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ f.manifestation }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- END: Food allergy -->
        </div>
      </div>
      <div v-if="anchor === 'info'">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Patient Info -->
          <div class="intro-y box col-span-full">
            <div
              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">General Record</h2>
              <div class="dropdown ml-auto">
                <a
                  class="dropdown-toggle w-5 h-5 block"
                  href="javascript:;"
                  aria-expanded="false"
                >
                  <MoreHorizontalIcon
                    class="w-5 h-5 text-gray-600 dark:text-gray-300"
                  />
                </a>
                <div class="dropdown-menu w-40">
                  <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <PlusIcon class="w-4 h-4 mr-2" /> Add General Record
                    </a>
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <SettingsIcon class="w-4 h-4 mr-2" />
                      Settings
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-5">
              <div class="overflow-x-auto">
                <table class="table">
                  <tbody>
                    <tr>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Preferred Contact Method
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Mobile Phone
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Occupation
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Software Engineer
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Current Employment
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Full Time
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Consent to share personal health information with
                        emergency contact?
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Yes
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Referral?
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Yes
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Marital Status
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Married
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Living Situation
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        With Spouse
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Primary Medical<br />Insurance Policy<br />Number Group
                        Number
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        SparkLife Limited<br />P-5603<br />12
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Drug Use? (N/A if none)
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Tobacco
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="intro-y box col-span-full">
            <div class="intro-y box">
              <div
                class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
              >
                <h2 class="font-medium text-base mr-auto">
                  Personal History
                </h2>
              </div>
              <div id="single-item-slider" class="p-5">
                <div class="preview">
                  <div class="mx-6">
                    <TinySlider
                      :options="{
                        autoplay: false,
                        controls: true
                      }"
                    >
                      <h3 class="h-full font-medium text-center">
                        Mrs. Johnson is widowed and lives with one of her
                        daughters.
                      </h3>
                      <h3 class="h-full font-medium text-center">
                        Occupation: she worked as a nurse to age 67, is now
                        retired.
                      </h3>
                      <h3 class="h-full font-medium text-center">
                        Habits: No cigarettes or alcohol. Does not follow any
                        special diet.
                      </h3>
                      <h3 class="h-full font-medium text-center">
                        Born in South Carolina, came to New York in 1931. she
                        has never been outside of the United States.
                      </h3>
                      <h3 class="h-full font-medium text-center">
                        Present environment: lives in a one bedroom apartment on
                        the third floor of a building with and elevator. She has
                        a home helper who comes 3 hours a day.
                      </h3>
                      <h3 class="h-full font-medium text-center">
                        Financial: Receives social security and Medicare, and is
                        supported by her children.
                      </h3>
                      <h3 class="h-full font-medium text-center">
                        Psychosocial: The patient is generally an alert and
                        active woman despite her arthritic symptoms. She
                        understands that she is having a “heart attack” at the
                        present time and she appears to be extremely anxious.
                      </h3>
                    </TinySlider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="intro-y box col-span-full">
            <div
              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">
                Emergency Contact Records
              </h2>
              <div class="dropdown ml-auto">
                <a
                  class="dropdown-toggle w-5 h-5 block"
                  href="javascript:;"
                  aria-expanded="false"
                >
                  <MoreHorizontalIcon
                    class="w-5 h-5 text-gray-600 dark:text-gray-300"
                  />
                </a>
                <div class="dropdown-menu w-40">
                  <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <PlusIcon class="w-4 h-4 mr-2" />
                      <a
                        type="button"
                        href="javascript:void(0)"
                        data-toggle="modal"
                        data-target="#exampleModalCenter2"
                        >Add Emergency Contact</a
                      >
                    </a>

                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <SettingsIcon class="w-4 h-4 mr-2" />
                      Settings
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-5">
              <div class="overflow-x-auto">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        #
                      </th>

                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Name
                      </th>

                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        DOB
                      </th>

                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Gender
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Address
                      </th>

                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Phone Number
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Email Address
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Blood Group
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Relationship with Patient
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      v-for="(e, key) in data[0].emergency"
                      :key="key"
                    >
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ key + 1 }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ e.name }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ e.dob }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ e.gender }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ e.address }}
                      </td>
                      <td class="border">{{ e.contact }}</td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ e.email }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ e.bloodGroup }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ e.relation }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- END: Patient Profile -->

          <div class="intro-y box col-span-full">
            <div
              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">
                Family History
              </h2>
              <div class="dropdown ml-auto">
                <a
                  class="dropdown-toggle w-5 h-5 block"
                  href="javascript:;"
                  aria-expanded="false"
                >
                  <MoreHorizontalIcon
                    class="w-5 h-5 text-gray-600 dark:text-gray-300"
                  />
                </a>
                <div class="dropdown-menu w-40">
                  <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <PlusIcon class="w-4 h-4 mr-2" />
                      <a
                        type="button"
                        href="javascript:void(0)"
                        data-toggle="modal"
                        data-target="#exampleModalCenter1"
                        >Add Patient Family History</a
                      >
                    </a>
                    <a
                      href=""
                      class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <SettingsIcon class="w-4 h-4 mr-2" />
                      Settings
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-5">
              <div class="overflow-x-auto">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        #
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Name
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Date of Birth
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Gender
                      </th>

                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Relationship
                      </th>

                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Status
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Contact number
                      </th>

                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Email Address
                      </th>
                      <th
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Conditions (if any)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      v-for="(f, key) in data[0].familyHistory"
                      :key="key"
                    >
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ key + 1 }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ f.name }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ f.dob }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ f.gender }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ f.relation }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ f.status }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ f.contact }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        {{ f.email }}
                      </td>
                      <td
                        class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        <span v-if="f.conditions[0] === ''">N/A</span>
                        <ul v-else>
                          <li v-for="(c, ckey) in f.conditions" :key="ckey">
                            {{ c }}
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="anchor === 'diagnosis'">
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y box col-span-full">
            <div class="intro-y box">
              <div
                class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
              >
                <h2 class="font-medium text-base mr-auto">
                  Filter Conditions
                </h2>
              </div>
              <SearchAutoComplete @set-icd="setICD" />
            </div>
          </div>

          <div class="intro-y col-span-12 lg:col-span-6">
            <!-- BEGIN: History of current problem -->
            <div class="intro-y box">
              <div
                class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
              >
                <h2 class="font-medium text-base mr-auto">
                  History and details of current problem
                </h2>
              </div>
              <div id="single-item-slider" class="p-5">
                <ul>
                  <li
                    class="h-full font-medium text-center"
                    v-for="history in defaultICD"
                    :key="history"
                  >
                    {{ history }}
                  </li>
                </ul>
              </div>
            </div>
            <!-- END: History of current problem -->
            <br />
            <!-- BEGIN: Treatment Plan -->
            <div class="intro-y box">
              <div
                class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
              >
                <h2 class="font-medium text-base mr-auto">
                  Plan of Treatment
                </h2>
              </div>
              <div id="single-item-slider" class="p-5">
                <ul>
                  <li
                    class="h-full font-medium text-center"
                    v-for="history in defaultICDTreatment"
                    :key="history"
                  >
                    {{ history }}
                  </li>
                </ul>
              </div>
            </div>
            <!-- END: Treatment Plan-->
          </div>

          <div class="intro-y col-span-12 lg:col-span-6">
            <!-- BEGIN: Test-->
            <div class="intro-y box mt-5 w-full h-full">
              <div
                class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
              >
                <h2 class="font-medium text-base mr-auto">
                  Tests conducted
                </h2>
              </div>
              <div id="single-item-slider" class="p-5">
                <div class="preview">
                  <div class="mx-6">
                    <TinySlider
                      :options="{
                        autoplay: false,
                        controls: true
                      }"
                    >
                      <h3 class="h-full font-medium text-center">
                        <b>Physical Tests</b>
                        <br />
                        <div class="text-left">
                          <br />
                          <ul>
                            <li>
                              General Appearance: Pale face
                            </li>
                            <br />
                            <li>
                              Eyes: Conjunctiva and lids normal
                            </li>
                            <br />
                            <li>
                              Ears, Nose, Mouth, Throat: All clear
                            </li>
                            <br />
                            <li>
                              Heart Rate: 83 beats per minute
                            </li>
                            <br />
                            <li>
                              Blood Pressure: 146 mmHg/ 88mmHg
                            </li>
                            <br />
                          </ul>
                        </div>
                      </h3>
                      <h3 class="h-full font-medium text-center">
                        <b>Laboratory Tests</b>
                        <br />
                        <div class="text-left">
                          <br />
                          <table class="table">
                            <thead>
                              <tr>
                                <th
                                  class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                                >
                                  Test
                                </th>
                                <th
                                  class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                                >
                                  Result
                                </th>
                                <th
                                  class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                                >
                                  Normal Range
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                                >
                                  Calcium
                                </td>
                                <td
                                  class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                                >
                                  9.6 mg/dl
                                </td>
                                <td
                                  class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                                >
                                  8.2-10.2
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                                >
                                  Chloride
                                </td>
                                <td
                                  class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                                >
                                  101 mmol/l
                                </td>
                                <td
                                  class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                                >
                                  96-109
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                                >
                                  Uric Acid
                                </td>
                                <td
                                  class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                                >
                                  4.8 mg/dl
                                </td>
                                <td
                                  class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                                >
                                  3.4-7.0
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                                >
                                  Sodium
                                </td>
                                <td
                                  class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                                >
                                  135 mmol/l
                                </td>
                                <td
                                  class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                                >
                                  135-145
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                                >
                                  Potassium
                                </td>
                                <td
                                  class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                                >
                                  4.5 mmol/l
                                </td>
                                <td
                                  class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                                >
                                  3.5-5.3
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </h3>
                    </TinySlider>
                  </div>
                </div>
              </div>
            </div>
            <!-- END: Test -->
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal"
      id="exampleModalCenter1"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              New Family Member
            </h5>
          </div>
          <div class="p-12">
            <Form @submit="checkForm">
              <span>Name: </span>
              <Field
                name="name"
                rules="required"
                placeholder="enter name"
                class="form-control"
              />
              <ErrorMessage name="name" />
              <br />
              <br />
              <label for="dob">Date of Birth: </label>
              <Litepicker
                v-model="dob"
                :options="{
                  autoApply: false,
                  showWeekNumbers: true,
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true
                  }
                }"
                class="form-control"
              />
              <br />
              <br />
              <span>Gender: </span>
              <Field
                name="gender"
                as="select"
                class="form-control"
                rules="required"
              >
                <option>Male</option>
                <option>Female</option>
                <option>Non-Binary</option>
              </Field>
              <ErrorMessage name="gender" />
              <br />
              <br />
              <span>Relationship: </span>
              <Field
                name="relation"
                rules="required"
                placeholder="enter relationship with patient"
                class="form-control"
              />
              <ErrorMessage name="relation" />
              <br />
              <br />
              <span>Status: </span>
              <Field
                name="status"
                rules="required"
                placeholder="Alive or Deceased (with reason if any)"
                class="form-control"
              />
              <ErrorMessage name="status" />
              <br />
              <br />
              <span>Phone number: </span>
              <br />
              <!-- country codes (ISO 3166) and Dial codes. -->
              <div class="grid grid-cols-5 gap-3">
                <Field
                  name="countryCode"
                  as="select"
                  rules="required"
                  class="form-control col-span-1 col-start-1"
                >
                  <option data-countryCode="GB" value="44" Selected
                    >UK (+44)</option
                  >
                  <option data-countryCode="US" value="1">USA (+1)</option>
                  <optgroup label="Other countries">
                    <option data-countryCode="DZ" value="213"
                      >Algeria (+213)</option
                    >
                    <option data-countryCode="AD" value="376"
                      >Andorra (+376)</option
                    >
                    <option data-countryCode="AO" value="244"
                      >Angola (+244)</option
                    >
                    <option data-countryCode="AI" value="1264"
                      >Anguilla (+1264)</option
                    >
                    <option data-countryCode="AG" value="1268"
                      >Antigua &amp; Barbuda (+1268)</option
                    >
                    <option data-countryCode="AR" value="54"
                      >Argentina (+54)</option
                    >
                    <option data-countryCode="AM" value="374"
                      >Armenia (+374)</option
                    >
                    <option data-countryCode="AW" value="297"
                      >Aruba (+297)</option
                    >
                    <option data-countryCode="AU" value="61"
                      >Australia (+61)</option
                    >
                    <option data-countryCode="AT" value="43"
                      >Austria (+43)</option
                    >
                    <option data-countryCode="AZ" value="994"
                      >Azerbaijan (+994)</option
                    >
                    <option data-countryCode="BS" value="1242"
                      >Bahamas (+1242)</option
                    >
                    <option data-countryCode="BH" value="973"
                      >Bahrain (+973)</option
                    >
                    <option data-countryCode="BD" value="880"
                      >Bangladesh (+880)</option
                    >
                    <option data-countryCode="BB" value="1246"
                      >Barbados (+1246)</option
                    >
                    <option data-countryCode="BY" value="375"
                      >Belarus (+375)</option
                    >
                    <option data-countryCode="BE" value="32"
                      >Belgium (+32)</option
                    >
                    <option data-countryCode="BZ" value="501"
                      >Belize (+501)</option
                    >
                    <option data-countryCode="BJ" value="229"
                      >Benin (+229)</option
                    >
                    <option data-countryCode="BM" value="1441"
                      >Bermuda (+1441)</option
                    >
                    <option data-countryCode="BT" value="975"
                      >Bhutan (+975)</option
                    >
                    <option data-countryCode="BO" value="591"
                      >Bolivia (+591)</option
                    >
                    <option data-countryCode="BA" value="387"
                      >Bosnia Herzegovina (+387)</option
                    >
                    <option data-countryCode="BW" value="267"
                      >Botswana (+267)</option
                    >
                    <option data-countryCode="BR" value="55"
                      >Brazil (+55)</option
                    >
                    <option data-countryCode="BN" value="673"
                      >Brunei (+673)</option
                    >
                    <option data-countryCode="BG" value="359"
                      >Bulgaria (+359)</option
                    >
                    <option data-countryCode="BF" value="226"
                      >Burkina Faso (+226)</option
                    >
                    <option data-countryCode="BI" value="257"
                      >Burundi (+257)</option
                    >
                    <option data-countryCode="KH" value="855"
                      >Cambodia (+855)</option
                    >
                    <option data-countryCode="CM" value="237"
                      >Cameroon (+237)</option
                    >
                    <option data-countryCode="CA" value="1">Canada (+1)</option>
                    <option data-countryCode="CV" value="238"
                      >Cape Verde Islands (+238)</option
                    >
                    <option data-countryCode="KY" value="1345"
                      >Cayman Islands (+1345)</option
                    >
                    <option data-countryCode="CF" value="236"
                      >Central African Republic (+236)</option
                    >
                    <option data-countryCode="CL" value="56"
                      >Chile (+56)</option
                    >
                    <option data-countryCode="CN" value="86"
                      >China (+86)</option
                    >
                    <option data-countryCode="CO" value="57"
                      >Colombia (+57)</option
                    >
                    <option data-countryCode="KM" value="269"
                      >Comoros (+269)</option
                    >
                    <option data-countryCode="CG" value="242"
                      >Congo (+242)</option
                    >
                    <option data-countryCode="CK" value="682"
                      >Cook Islands (+682)</option
                    >
                    <option data-countryCode="CR" value="506"
                      >Costa Rica (+506)</option
                    >
                    <option data-countryCode="HR" value="385"
                      >Croatia (+385)</option
                    >
                    <option data-countryCode="CU" value="53">Cuba (+53)</option>
                    <option data-countryCode="CY" value="90392"
                      >Cyprus North (+90392)</option
                    >
                    <option data-countryCode="CY" value="357"
                      >Cyprus South (+357)</option
                    >
                    <option data-countryCode="CZ" value="42"
                      >Czech Republic (+42)</option
                    >
                    <option data-countryCode="DK" value="45"
                      >Denmark (+45)</option
                    >
                    <option data-countryCode="DJ" value="253"
                      >Djibouti (+253)</option
                    >
                    <option data-countryCode="DM" value="1809"
                      >Dominica (+1809)</option
                    >
                    <option data-countryCode="DO" value="1809"
                      >Dominican Republic (+1809)</option
                    >
                    <option data-countryCode="EC" value="593"
                      >Ecuador (+593)</option
                    >
                    <option data-countryCode="EG" value="20"
                      >Egypt (+20)</option
                    >
                    <option data-countryCode="SV" value="503"
                      >El Salvador (+503)</option
                    >
                    <option data-countryCode="GQ" value="240"
                      >Equatorial Guinea (+240)</option
                    >
                    <option data-countryCode="ER" value="291"
                      >Eritrea (+291)</option
                    >
                    <option data-countryCode="EE" value="372"
                      >Estonia (+372)</option
                    >
                    <option data-countryCode="ET" value="251"
                      >Ethiopia (+251)</option
                    >
                    <option data-countryCode="FK" value="500"
                      >Falkland Islands (+500)</option
                    >
                    <option data-countryCode="FO" value="298"
                      >Faroe Islands (+298)</option
                    >
                    <option data-countryCode="FJ" value="679"
                      >Fiji (+679)</option
                    >
                    <option data-countryCode="FI" value="358"
                      >Finland (+358)</option
                    >
                    <option data-countryCode="FR" value="33"
                      >France (+33)</option
                    >
                    <option data-countryCode="GF" value="594"
                      >French Guiana (+594)</option
                    >
                    <option data-countryCode="PF" value="689"
                      >French Polynesia (+689)</option
                    >
                    <option data-countryCode="GA" value="241"
                      >Gabon (+241)</option
                    >
                    <option data-countryCode="GM" value="220"
                      >Gambia (+220)</option
                    >
                    <option data-countryCode="GE" value="7880"
                      >Georgia (+7880)</option
                    >
                    <option data-countryCode="DE" value="49"
                      >Germany (+49)</option
                    >
                    <option data-countryCode="GH" value="233"
                      >Ghana (+233)</option
                    >
                    <option data-countryCode="GI" value="350"
                      >Gibraltar (+350)</option
                    >
                    <option data-countryCode="GR" value="30"
                      >Greece (+30)</option
                    >
                    <option data-countryCode="GL" value="299"
                      >Greenland (+299)</option
                    >
                    <option data-countryCode="GD" value="1473"
                      >Grenada (+1473)</option
                    >
                    <option data-countryCode="GP" value="590"
                      >Guadeloupe (+590)</option
                    >
                    <option data-countryCode="GU" value="671"
                      >Guam (+671)</option
                    >
                    <option data-countryCode="GT" value="502"
                      >Guatemala (+502)</option
                    >
                    <option data-countryCode="GN" value="224"
                      >Guinea (+224)</option
                    >
                    <option data-countryCode="GW" value="245"
                      >Guinea - Bissau (+245)</option
                    >
                    <option data-countryCode="GY" value="592"
                      >Guyana (+592)</option
                    >
                    <option data-countryCode="HT" value="509"
                      >Haiti (+509)</option
                    >
                    <option data-countryCode="HN" value="504"
                      >Honduras (+504)</option
                    >
                    <option data-countryCode="HK" value="852"
                      >Hong Kong (+852)</option
                    >
                    <option data-countryCode="HU" value="36"
                      >Hungary (+36)</option
                    >
                    <option data-countryCode="IS" value="354"
                      >Iceland (+354)</option
                    >
                    <option data-countryCode="IN" value="91"
                      >India (+91)</option
                    >
                    <option data-countryCode="ID" value="62"
                      >Indonesia (+62)</option
                    >
                    <option data-countryCode="IR" value="98">Iran (+98)</option>
                    <option data-countryCode="IQ" value="964"
                      >Iraq (+964)</option
                    >
                    <option data-countryCode="IE" value="353"
                      >Ireland (+353)</option
                    >
                    <option data-countryCode="IL" value="972"
                      >Israel (+972)</option
                    >
                    <option data-countryCode="IT" value="39"
                      >Italy (+39)</option
                    >
                    <option data-countryCode="JM" value="1876"
                      >Jamaica (+1876)</option
                    >
                    <option data-countryCode="JP" value="81"
                      >Japan (+81)</option
                    >
                    <option data-countryCode="JO" value="962"
                      >Jordan (+962)</option
                    >
                    <option data-countryCode="KZ" value="7"
                      >Kazakhstan (+7)</option
                    >
                    <option data-countryCode="KE" value="254"
                      >Kenya (+254)</option
                    >
                    <option data-countryCode="KI" value="686"
                      >Kiribati (+686)</option
                    >
                    <option data-countryCode="KP" value="850"
                      >Korea North (+850)</option
                    >
                    <option data-countryCode="KR" value="82"
                      >Korea South (+82)</option
                    >
                    <option data-countryCode="KW" value="965"
                      >Kuwait (+965)</option
                    >
                    <option data-countryCode="KG" value="996"
                      >Kyrgyzstan (+996)</option
                    >
                    <option data-countryCode="LA" value="856"
                      >Laos (+856)</option
                    >
                    <option data-countryCode="LV" value="371"
                      >Latvia (+371)</option
                    >
                    <option data-countryCode="LB" value="961"
                      >Lebanon (+961)</option
                    >
                    <option data-countryCode="LS" value="266"
                      >Lesotho (+266)</option
                    >
                    <option data-countryCode="LR" value="231"
                      >Liberia (+231)</option
                    >
                    <option data-countryCode="LY" value="218"
                      >Libya (+218)</option
                    >
                    <option data-countryCode="LI" value="417"
                      >Liechtenstein (+417)</option
                    >
                    <option data-countryCode="LT" value="370"
                      >Lithuania (+370)</option
                    >
                    <option data-countryCode="LU" value="352"
                      >Luxembourg (+352)</option
                    >
                    <option data-countryCode="MO" value="853"
                      >Macao (+853)</option
                    >
                    <option data-countryCode="MK" value="389"
                      >Macedonia (+389)</option
                    >
                    <option data-countryCode="MG" value="261"
                      >Madagascar (+261)</option
                    >
                    <option data-countryCode="MW" value="265"
                      >Malawi (+265)</option
                    >
                    <option data-countryCode="MY" value="60"
                      >Malaysia (+60)</option
                    >
                    <option data-countryCode="MV" value="960"
                      >Maldives (+960)</option
                    >
                    <option data-countryCode="ML" value="223"
                      >Mali (+223)</option
                    >
                    <option data-countryCode="MT" value="356"
                      >Malta (+356)</option
                    >
                    <option data-countryCode="MH" value="692"
                      >Marshall Islands (+692)</option
                    >
                    <option data-countryCode="MQ" value="596"
                      >Martinique (+596)</option
                    >
                    <option data-countryCode="MR" value="222"
                      >Mauritania (+222)</option
                    >
                    <option data-countryCode="YT" value="269"
                      >Mayotte (+269)</option
                    >
                    <option data-countryCode="MX" value="52"
                      >Mexico (+52)</option
                    >
                    <option data-countryCode="FM" value="691"
                      >Micronesia (+691)</option
                    >
                    <option data-countryCode="MD" value="373"
                      >Moldova (+373)</option
                    >
                    <option data-countryCode="MC" value="377"
                      >Monaco (+377)</option
                    >
                    <option data-countryCode="MN" value="976"
                      >Mongolia (+976)</option
                    >
                    <option data-countryCode="MS" value="1664"
                      >Montserrat (+1664)</option
                    >
                    <option data-countryCode="MA" value="212"
                      >Morocco (+212)</option
                    >
                    <option data-countryCode="MZ" value="258"
                      >Mozambique (+258)</option
                    >
                    <option data-countryCode="MN" value="95"
                      >Myanmar (+95)</option
                    >
                    <option data-countryCode="NA" value="264"
                      >Namibia (+264)</option
                    >
                    <option data-countryCode="NR" value="674"
                      >Nauru (+674)</option
                    >
                    <option data-countryCode="NP" value="977"
                      >Nepal (+977)</option
                    >
                    <option data-countryCode="NL" value="31"
                      >Netherlands (+31)</option
                    >
                    <option data-countryCode="NC" value="687"
                      >New Caledonia (+687)</option
                    >
                    <option data-countryCode="NZ" value="64"
                      >New Zealand (+64)</option
                    >
                    <option data-countryCode="NI" value="505"
                      >Nicaragua (+505)</option
                    >
                    <option data-countryCode="NE" value="227"
                      >Niger (+227)</option
                    >
                    <option data-countryCode="NG" value="234"
                      >Nigeria (+234)</option
                    >
                    <option data-countryCode="NU" value="683"
                      >Niue (+683)</option
                    >
                    <option data-countryCode="NF" value="672"
                      >Norfolk Islands (+672)</option
                    >
                    <option data-countryCode="NP" value="670"
                      >Northern Marianas (+670)</option
                    >
                    <option data-countryCode="NO" value="47"
                      >Norway (+47)</option
                    >
                    <option data-countryCode="OM" value="968"
                      >Oman (+968)</option
                    >
                    <option data-countryCode="PW" value="680"
                      >Palau (+680)</option
                    >
                    <option data-countryCode="PA" value="507"
                      >Panama (+507)</option
                    >
                    <option data-countryCode="PG" value="675"
                      >Papua New Guinea (+675)</option
                    >
                    <option data-countryCode="PY" value="595"
                      >Paraguay (+595)</option
                    >
                    <option data-countryCode="PE" value="51">Peru (+51)</option>
                    <option data-countryCode="PH" value="63"
                      >Philippines (+63)</option
                    >
                    <option data-countryCode="PL" value="48"
                      >Poland (+48)</option
                    >
                    <option data-countryCode="PT" value="351"
                      >Portugal (+351)</option
                    >
                    <option data-countryCode="PR" value="1787"
                      >Puerto Rico (+1787)</option
                    >
                    <option data-countryCode="QA" value="974"
                      >Qatar (+974)</option
                    >
                    <option data-countryCode="RE" value="262"
                      >Reunion (+262)</option
                    >
                    <option data-countryCode="RO" value="40"
                      >Romania (+40)</option
                    >
                    <option data-countryCode="RU" value="7">Russia (+7)</option>
                    <option data-countryCode="RW" value="250"
                      >Rwanda (+250)</option
                    >
                    <option data-countryCode="SM" value="378"
                      >San Marino (+378)</option
                    >
                    <option data-countryCode="ST" value="239"
                      >Sao Tome &amp; Principe (+239)</option
                    >
                    <option data-countryCode="SA" value="966"
                      >Saudi Arabia (+966)</option
                    >
                    <option data-countryCode="SN" value="221"
                      >Senegal (+221)</option
                    >
                    <option data-countryCode="CS" value="381"
                      >Serbia (+381)</option
                    >
                    <option data-countryCode="SC" value="248"
                      >Seychelles (+248)</option
                    >
                    <option data-countryCode="SL" value="232"
                      >Sierra Leone (+232)</option
                    >
                    <option data-countryCode="SG" value="65"
                      >Singapore (+65)</option
                    >
                    <option data-countryCode="SK" value="421"
                      >Slovak Republic (+421)</option
                    >
                    <option data-countryCode="SI" value="386"
                      >Slovenia (+386)</option
                    >
                    <option data-countryCode="SB" value="677"
                      >Solomon Islands (+677)</option
                    >
                    <option data-countryCode="SO" value="252"
                      >Somalia (+252)</option
                    >
                    <option data-countryCode="ZA" value="27"
                      >South Africa (+27)</option
                    >
                    <option data-countryCode="ES" value="34"
                      >Spain (+34)</option
                    >
                    <option data-countryCode="LK" value="94"
                      >Sri Lanka (+94)</option
                    >
                    <option data-countryCode="SH" value="290"
                      >St. Helena (+290)</option
                    >
                    <option data-countryCode="KN" value="1869"
                      >St. Kitts (+1869)</option
                    >
                    <option data-countryCode="SC" value="1758"
                      >St. Lucia (+1758)</option
                    >
                    <option data-countryCode="SD" value="249"
                      >Sudan (+249)</option
                    >
                    <option data-countryCode="SR" value="597"
                      >Suriname (+597)</option
                    >
                    <option data-countryCode="SZ" value="268"
                      >Swaziland (+268)</option
                    >
                    <option data-countryCode="SE" value="46"
                      >Sweden (+46)</option
                    >
                    <option data-countryCode="CH" value="41"
                      >Switzerland (+41)</option
                    >
                    <option data-countryCode="SI" value="963"
                      >Syria (+963)</option
                    >
                    <option data-countryCode="TW" value="886"
                      >Taiwan (+886)</option
                    >
                    <option data-countryCode="TJ" value="7"
                      >Tajikstan (+7)</option
                    >
                    <option data-countryCode="TH" value="66"
                      >Thailand (+66)</option
                    >
                    <option data-countryCode="TG" value="228"
                      >Togo (+228)</option
                    >
                    <option data-countryCode="TO" value="676"
                      >Tonga (+676)</option
                    >
                    <option data-countryCode="TT" value="1868"
                      >Trinidad &amp; Tobago (+1868)</option
                    >
                    <option data-countryCode="TN" value="216"
                      >Tunisia (+216)</option
                    >
                    <option data-countryCode="TR" value="90"
                      >Turkey (+90)</option
                    >
                    <option data-countryCode="TM" value="7"
                      >Turkmenistan (+7)</option
                    >
                    <option data-countryCode="TM" value="993"
                      >Turkmenistan (+993)</option
                    >
                    <option data-countryCode="TC" value="1649"
                      >Turks &amp; Caicos Islands (+1649)</option
                    >
                    <option data-countryCode="TV" value="688"
                      >Tuvalu (+688)</option
                    >
                    <option data-countryCode="UG" value="256"
                      >Uganda (+256)</option
                    >
                    <!-- <option data-countryCode="GB" value="44">UK (+44)</option> -->
                    <option data-countryCode="UA" value="380"
                      >Ukraine (+380)</option
                    >
                    <option data-countryCode="AE" value="971"
                      >United Arab Emirates (+971)</option
                    >
                    <option data-countryCode="UY" value="598"
                      >Uruguay (+598)</option
                    >
                    <!-- <option data-countryCode="US" value="1">USA (+1)</option> -->
                    <option data-countryCode="UZ" value="7"
                      >Uzbekistan (+7)</option
                    >
                    <option data-countryCode="VU" value="678"
                      >Vanuatu (+678)</option
                    >
                    <option data-countryCode="VA" value="379"
                      >Vatican City (+379)</option
                    >
                    <option data-countryCode="VE" value="58"
                      >Venezuela (+58)</option
                    >
                    <option data-countryCode="VN" value="84"
                      >Vietnam (+84)</option
                    >
                    <option data-countryCode="VG" value="84"
                      >Virgin Islands - British (+1284)</option
                    >
                    <option data-countryCode="VI" value="84"
                      >Virgin Islands - US (+1340)</option
                    >
                    <option data-countryCode="WF" value="681"
                      >Wallis &amp; Futuna (+681)</option
                    >
                    <option data-countryCode="YE" value="969"
                      >Yemen (North)(+969)</option
                    >
                    <option data-countryCode="YE" value="967"
                      >Yemen (South)(+967)</option
                    >
                    <option data-countryCode="ZM" value="260"
                      >Zambia (+260)</option
                    >
                    <option data-countryCode="ZW" value="263"
                      >Zimbabwe (+263)</option
                    >
                  </optgroup>
                </Field>

                <Field
                  name="contact"
                  class="form-control col-start-2 col-span-4"
                  placeholder="enter phone number"
                  rules="required|numeric"
                />
                <ErrorMessage class="col-start-1 col-span-2" name="contact" />
              </div>
              <br />
              <br />

              <span>Email: </span>
              <Field
                name="email"
                class="form-control"
                rules="required|email"
                placeholder="enter email address"
              />
              <ErrorMessage name="email" />
              <br />
              <br />
              <label for="specialization">Conditions (if any): </label
              ><PlusIcon @click="addCondition" />
              <br />
              <br />

              <ul>
                <li v-for="(item, key) in numCondition" :key="key">
                  <input
                    :id="key"
                    placeholder="Enter condition"
                    v-on:input="setCondition"
                    type="text"
                    class="form-control"
                    name="condition"
                  />
                </li>
              </ul>
              <br />
              <div class="grid grid-cols-4 gap-4">
                <input
                  type="submit"
                  class="btn btn-primary col-span-1 col-start-2"
                  value="Submit"
                />
                <input
                  class="btn btn-secondary col-span-1 col-start-3"
                  value="Close"
                  data-dismiss="modal"
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal"
      id="exampleModalCenter2"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              New Emergency Contact
            </h5>
          </div>
          <div class="p-12">
            <Form @submit="checkForm2">
              <span>Name: </span>
              <Field
                name="name"
                rules="required"
                placeholder="enter name"
                class="form-control"
                style="margin-top: 10px"
              />
              <ErrorMessage name="name" />
              <br />
              <br />
              <label for="dob">Date of Birth: </label>
              <Litepicker
                v-model="dob2"
                :options="{
                  autoApply: false,
                  showWeekNumbers: true,
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true
                  }
                }"
                class="form-control"
              />
              <br />
              <br />
              <span>Gender: </span>
              <Field
                name="gender"
                as="select"
                rules="required"
                class="form-control"
                style="margin-top: 10px"
              >
                <option>Male</option>
                <option>Female</option>
                <option>Non-Binary</option>
              </Field>
              <ErrorMessage name="gender" />
              <br />
              <br />
              <span>Address: </span>
              <Field
                name="address"
                rules="required"
                placeholder="enter home address"
                class="form-control"
                style="margin-top: 10px"
              />
              <ErrorMessage name="address" />
              <br />
              <br />

              <span>Phone number: </span>
              <br />
              <!-- country codes (ISO 3166) and Dial codes. -->
              <div class="grid grid-cols-5 gap-3">
                <Field
                  name="countryCode"
                  as="select"
                  rules="required"
                  class="form-control col-span-1 col-start-1"
                >
                  <option data-countryCode="GB" value="44" Selected
                    >UK (+44)</option
                  >
                  <option data-countryCode="US" value="1">USA (+1)</option>
                  <optgroup label="Other countries">
                    <option data-countryCode="DZ" value="213"
                      >Algeria (+213)</option
                    >
                    <option data-countryCode="AD" value="376"
                      >Andorra (+376)</option
                    >
                    <option data-countryCode="AO" value="244"
                      >Angola (+244)</option
                    >
                    <option data-countryCode="AI" value="1264"
                      >Anguilla (+1264)</option
                    >
                    <option data-countryCode="AG" value="1268"
                      >Antigua &amp; Barbuda (+1268)</option
                    >
                    <option data-countryCode="AR" value="54"
                      >Argentina (+54)</option
                    >
                    <option data-countryCode="AM" value="374"
                      >Armenia (+374)</option
                    >
                    <option data-countryCode="AW" value="297"
                      >Aruba (+297)</option
                    >
                    <option data-countryCode="AU" value="61"
                      >Australia (+61)</option
                    >
                    <option data-countryCode="AT" value="43"
                      >Austria (+43)</option
                    >
                    <option data-countryCode="AZ" value="994"
                      >Azerbaijan (+994)</option
                    >
                    <option data-countryCode="BS" value="1242"
                      >Bahamas (+1242)</option
                    >
                    <option data-countryCode="BH" value="973"
                      >Bahrain (+973)</option
                    >
                    <option data-countryCode="BD" value="880"
                      >Bangladesh (+880)</option
                    >
                    <option data-countryCode="BB" value="1246"
                      >Barbados (+1246)</option
                    >
                    <option data-countryCode="BY" value="375"
                      >Belarus (+375)</option
                    >
                    <option data-countryCode="BE" value="32"
                      >Belgium (+32)</option
                    >
                    <option data-countryCode="BZ" value="501"
                      >Belize (+501)</option
                    >
                    <option data-countryCode="BJ" value="229"
                      >Benin (+229)</option
                    >
                    <option data-countryCode="BM" value="1441"
                      >Bermuda (+1441)</option
                    >
                    <option data-countryCode="BT" value="975"
                      >Bhutan (+975)</option
                    >
                    <option data-countryCode="BO" value="591"
                      >Bolivia (+591)</option
                    >
                    <option data-countryCode="BA" value="387"
                      >Bosnia Herzegovina (+387)</option
                    >
                    <option data-countryCode="BW" value="267"
                      >Botswana (+267)</option
                    >
                    <option data-countryCode="BR" value="55"
                      >Brazil (+55)</option
                    >
                    <option data-countryCode="BN" value="673"
                      >Brunei (+673)</option
                    >
                    <option data-countryCode="BG" value="359"
                      >Bulgaria (+359)</option
                    >
                    <option data-countryCode="BF" value="226"
                      >Burkina Faso (+226)</option
                    >
                    <option data-countryCode="BI" value="257"
                      >Burundi (+257)</option
                    >
                    <option data-countryCode="KH" value="855"
                      >Cambodia (+855)</option
                    >
                    <option data-countryCode="CM" value="237"
                      >Cameroon (+237)</option
                    >
                    <option data-countryCode="CA" value="1">Canada (+1)</option>
                    <option data-countryCode="CV" value="238"
                      >Cape Verde Islands (+238)</option
                    >
                    <option data-countryCode="KY" value="1345"
                      >Cayman Islands (+1345)</option
                    >
                    <option data-countryCode="CF" value="236"
                      >Central African Republic (+236)</option
                    >
                    <option data-countryCode="CL" value="56"
                      >Chile (+56)</option
                    >
                    <option data-countryCode="CN" value="86"
                      >China (+86)</option
                    >
                    <option data-countryCode="CO" value="57"
                      >Colombia (+57)</option
                    >
                    <option data-countryCode="KM" value="269"
                      >Comoros (+269)</option
                    >
                    <option data-countryCode="CG" value="242"
                      >Congo (+242)</option
                    >
                    <option data-countryCode="CK" value="682"
                      >Cook Islands (+682)</option
                    >
                    <option data-countryCode="CR" value="506"
                      >Costa Rica (+506)</option
                    >
                    <option data-countryCode="HR" value="385"
                      >Croatia (+385)</option
                    >
                    <option data-countryCode="CU" value="53">Cuba (+53)</option>
                    <option data-countryCode="CY" value="90392"
                      >Cyprus North (+90392)</option
                    >
                    <option data-countryCode="CY" value="357"
                      >Cyprus South (+357)</option
                    >
                    <option data-countryCode="CZ" value="42"
                      >Czech Republic (+42)</option
                    >
                    <option data-countryCode="DK" value="45"
                      >Denmark (+45)</option
                    >
                    <option data-countryCode="DJ" value="253"
                      >Djibouti (+253)</option
                    >
                    <option data-countryCode="DM" value="1809"
                      >Dominica (+1809)</option
                    >
                    <option data-countryCode="DO" value="1809"
                      >Dominican Republic (+1809)</option
                    >
                    <option data-countryCode="EC" value="593"
                      >Ecuador (+593)</option
                    >
                    <option data-countryCode="EG" value="20"
                      >Egypt (+20)</option
                    >
                    <option data-countryCode="SV" value="503"
                      >El Salvador (+503)</option
                    >
                    <option data-countryCode="GQ" value="240"
                      >Equatorial Guinea (+240)</option
                    >
                    <option data-countryCode="ER" value="291"
                      >Eritrea (+291)</option
                    >
                    <option data-countryCode="EE" value="372"
                      >Estonia (+372)</option
                    >
                    <option data-countryCode="ET" value="251"
                      >Ethiopia (+251)</option
                    >
                    <option data-countryCode="FK" value="500"
                      >Falkland Islands (+500)</option
                    >
                    <option data-countryCode="FO" value="298"
                      >Faroe Islands (+298)</option
                    >
                    <option data-countryCode="FJ" value="679"
                      >Fiji (+679)</option
                    >
                    <option data-countryCode="FI" value="358"
                      >Finland (+358)</option
                    >
                    <option data-countryCode="FR" value="33"
                      >France (+33)</option
                    >
                    <option data-countryCode="GF" value="594"
                      >French Guiana (+594)</option
                    >
                    <option data-countryCode="PF" value="689"
                      >French Polynesia (+689)</option
                    >
                    <option data-countryCode="GA" value="241"
                      >Gabon (+241)</option
                    >
                    <option data-countryCode="GM" value="220"
                      >Gambia (+220)</option
                    >
                    <option data-countryCode="GE" value="7880"
                      >Georgia (+7880)</option
                    >
                    <option data-countryCode="DE" value="49"
                      >Germany (+49)</option
                    >
                    <option data-countryCode="GH" value="233"
                      >Ghana (+233)</option
                    >
                    <option data-countryCode="GI" value="350"
                      >Gibraltar (+350)</option
                    >
                    <option data-countryCode="GR" value="30"
                      >Greece (+30)</option
                    >
                    <option data-countryCode="GL" value="299"
                      >Greenland (+299)</option
                    >
                    <option data-countryCode="GD" value="1473"
                      >Grenada (+1473)</option
                    >
                    <option data-countryCode="GP" value="590"
                      >Guadeloupe (+590)</option
                    >
                    <option data-countryCode="GU" value="671"
                      >Guam (+671)</option
                    >
                    <option data-countryCode="GT" value="502"
                      >Guatemala (+502)</option
                    >
                    <option data-countryCode="GN" value="224"
                      >Guinea (+224)</option
                    >
                    <option data-countryCode="GW" value="245"
                      >Guinea - Bissau (+245)</option
                    >
                    <option data-countryCode="GY" value="592"
                      >Guyana (+592)</option
                    >
                    <option data-countryCode="HT" value="509"
                      >Haiti (+509)</option
                    >
                    <option data-countryCode="HN" value="504"
                      >Honduras (+504)</option
                    >
                    <option data-countryCode="HK" value="852"
                      >Hong Kong (+852)</option
                    >
                    <option data-countryCode="HU" value="36"
                      >Hungary (+36)</option
                    >
                    <option data-countryCode="IS" value="354"
                      >Iceland (+354)</option
                    >
                    <option data-countryCode="IN" value="91"
                      >India (+91)</option
                    >
                    <option data-countryCode="ID" value="62"
                      >Indonesia (+62)</option
                    >
                    <option data-countryCode="IR" value="98">Iran (+98)</option>
                    <option data-countryCode="IQ" value="964"
                      >Iraq (+964)</option
                    >
                    <option data-countryCode="IE" value="353"
                      >Ireland (+353)</option
                    >
                    <option data-countryCode="IL" value="972"
                      >Israel (+972)</option
                    >
                    <option data-countryCode="IT" value="39"
                      >Italy (+39)</option
                    >
                    <option data-countryCode="JM" value="1876"
                      >Jamaica (+1876)</option
                    >
                    <option data-countryCode="JP" value="81"
                      >Japan (+81)</option
                    >
                    <option data-countryCode="JO" value="962"
                      >Jordan (+962)</option
                    >
                    <option data-countryCode="KZ" value="7"
                      >Kazakhstan (+7)</option
                    >
                    <option data-countryCode="KE" value="254"
                      >Kenya (+254)</option
                    >
                    <option data-countryCode="KI" value="686"
                      >Kiribati (+686)</option
                    >
                    <option data-countryCode="KP" value="850"
                      >Korea North (+850)</option
                    >
                    <option data-countryCode="KR" value="82"
                      >Korea South (+82)</option
                    >
                    <option data-countryCode="KW" value="965"
                      >Kuwait (+965)</option
                    >
                    <option data-countryCode="KG" value="996"
                      >Kyrgyzstan (+996)</option
                    >
                    <option data-countryCode="LA" value="856"
                      >Laos (+856)</option
                    >
                    <option data-countryCode="LV" value="371"
                      >Latvia (+371)</option
                    >
                    <option data-countryCode="LB" value="961"
                      >Lebanon (+961)</option
                    >
                    <option data-countryCode="LS" value="266"
                      >Lesotho (+266)</option
                    >
                    <option data-countryCode="LR" value="231"
                      >Liberia (+231)</option
                    >
                    <option data-countryCode="LY" value="218"
                      >Libya (+218)</option
                    >
                    <option data-countryCode="LI" value="417"
                      >Liechtenstein (+417)</option
                    >
                    <option data-countryCode="LT" value="370"
                      >Lithuania (+370)</option
                    >
                    <option data-countryCode="LU" value="352"
                      >Luxembourg (+352)</option
                    >
                    <option data-countryCode="MO" value="853"
                      >Macao (+853)</option
                    >
                    <option data-countryCode="MK" value="389"
                      >Macedonia (+389)</option
                    >
                    <option data-countryCode="MG" value="261"
                      >Madagascar (+261)</option
                    >
                    <option data-countryCode="MW" value="265"
                      >Malawi (+265)</option
                    >
                    <option data-countryCode="MY" value="60"
                      >Malaysia (+60)</option
                    >
                    <option data-countryCode="MV" value="960"
                      >Maldives (+960)</option
                    >
                    <option data-countryCode="ML" value="223"
                      >Mali (+223)</option
                    >
                    <option data-countryCode="MT" value="356"
                      >Malta (+356)</option
                    >
                    <option data-countryCode="MH" value="692"
                      >Marshall Islands (+692)</option
                    >
                    <option data-countryCode="MQ" value="596"
                      >Martinique (+596)</option
                    >
                    <option data-countryCode="MR" value="222"
                      >Mauritania (+222)</option
                    >
                    <option data-countryCode="YT" value="269"
                      >Mayotte (+269)</option
                    >
                    <option data-countryCode="MX" value="52"
                      >Mexico (+52)</option
                    >
                    <option data-countryCode="FM" value="691"
                      >Micronesia (+691)</option
                    >
                    <option data-countryCode="MD" value="373"
                      >Moldova (+373)</option
                    >
                    <option data-countryCode="MC" value="377"
                      >Monaco (+377)</option
                    >
                    <option data-countryCode="MN" value="976"
                      >Mongolia (+976)</option
                    >
                    <option data-countryCode="MS" value="1664"
                      >Montserrat (+1664)</option
                    >
                    <option data-countryCode="MA" value="212"
                      >Morocco (+212)</option
                    >
                    <option data-countryCode="MZ" value="258"
                      >Mozambique (+258)</option
                    >
                    <option data-countryCode="MN" value="95"
                      >Myanmar (+95)</option
                    >
                    <option data-countryCode="NA" value="264"
                      >Namibia (+264)</option
                    >
                    <option data-countryCode="NR" value="674"
                      >Nauru (+674)</option
                    >
                    <option data-countryCode="NP" value="977"
                      >Nepal (+977)</option
                    >
                    <option data-countryCode="NL" value="31"
                      >Netherlands (+31)</option
                    >
                    <option data-countryCode="NC" value="687"
                      >New Caledonia (+687)</option
                    >
                    <option data-countryCode="NZ" value="64"
                      >New Zealand (+64)</option
                    >
                    <option data-countryCode="NI" value="505"
                      >Nicaragua (+505)</option
                    >
                    <option data-countryCode="NE" value="227"
                      >Niger (+227)</option
                    >
                    <option data-countryCode="NG" value="234"
                      >Nigeria (+234)</option
                    >
                    <option data-countryCode="NU" value="683"
                      >Niue (+683)</option
                    >
                    <option data-countryCode="NF" value="672"
                      >Norfolk Islands (+672)</option
                    >
                    <option data-countryCode="NP" value="670"
                      >Northern Marianas (+670)</option
                    >
                    <option data-countryCode="NO" value="47"
                      >Norway (+47)</option
                    >
                    <option data-countryCode="OM" value="968"
                      >Oman (+968)</option
                    >
                    <option data-countryCode="PW" value="680"
                      >Palau (+680)</option
                    >
                    <option data-countryCode="PA" value="507"
                      >Panama (+507)</option
                    >
                    <option data-countryCode="PG" value="675"
                      >Papua New Guinea (+675)</option
                    >
                    <option data-countryCode="PY" value="595"
                      >Paraguay (+595)</option
                    >
                    <option data-countryCode="PE" value="51">Peru (+51)</option>
                    <option data-countryCode="PH" value="63"
                      >Philippines (+63)</option
                    >
                    <option data-countryCode="PL" value="48"
                      >Poland (+48)</option
                    >
                    <option data-countryCode="PT" value="351"
                      >Portugal (+351)</option
                    >
                    <option data-countryCode="PR" value="1787"
                      >Puerto Rico (+1787)</option
                    >
                    <option data-countryCode="QA" value="974"
                      >Qatar (+974)</option
                    >
                    <option data-countryCode="RE" value="262"
                      >Reunion (+262)</option
                    >
                    <option data-countryCode="RO" value="40"
                      >Romania (+40)</option
                    >
                    <option data-countryCode="RU" value="7">Russia (+7)</option>
                    <option data-countryCode="RW" value="250"
                      >Rwanda (+250)</option
                    >
                    <option data-countryCode="SM" value="378"
                      >San Marino (+378)</option
                    >
                    <option data-countryCode="ST" value="239"
                      >Sao Tome &amp; Principe (+239)</option
                    >
                    <option data-countryCode="SA" value="966"
                      >Saudi Arabia (+966)</option
                    >
                    <option data-countryCode="SN" value="221"
                      >Senegal (+221)</option
                    >
                    <option data-countryCode="CS" value="381"
                      >Serbia (+381)</option
                    >
                    <option data-countryCode="SC" value="248"
                      >Seychelles (+248)</option
                    >
                    <option data-countryCode="SL" value="232"
                      >Sierra Leone (+232)</option
                    >
                    <option data-countryCode="SG" value="65"
                      >Singapore (+65)</option
                    >
                    <option data-countryCode="SK" value="421"
                      >Slovak Republic (+421)</option
                    >
                    <option data-countryCode="SI" value="386"
                      >Slovenia (+386)</option
                    >
                    <option data-countryCode="SB" value="677"
                      >Solomon Islands (+677)</option
                    >
                    <option data-countryCode="SO" value="252"
                      >Somalia (+252)</option
                    >
                    <option data-countryCode="ZA" value="27"
                      >South Africa (+27)</option
                    >
                    <option data-countryCode="ES" value="34"
                      >Spain (+34)</option
                    >
                    <option data-countryCode="LK" value="94"
                      >Sri Lanka (+94)</option
                    >
                    <option data-countryCode="SH" value="290"
                      >St. Helena (+290)</option
                    >
                    <option data-countryCode="KN" value="1869"
                      >St. Kitts (+1869)</option
                    >
                    <option data-countryCode="SC" value="1758"
                      >St. Lucia (+1758)</option
                    >
                    <option data-countryCode="SD" value="249"
                      >Sudan (+249)</option
                    >
                    <option data-countryCode="SR" value="597"
                      >Suriname (+597)</option
                    >
                    <option data-countryCode="SZ" value="268"
                      >Swaziland (+268)</option
                    >
                    <option data-countryCode="SE" value="46"
                      >Sweden (+46)</option
                    >
                    <option data-countryCode="CH" value="41"
                      >Switzerland (+41)</option
                    >
                    <option data-countryCode="SI" value="963"
                      >Syria (+963)</option
                    >
                    <option data-countryCode="TW" value="886"
                      >Taiwan (+886)</option
                    >
                    <option data-countryCode="TJ" value="7"
                      >Tajikstan (+7)</option
                    >
                    <option data-countryCode="TH" value="66"
                      >Thailand (+66)</option
                    >
                    <option data-countryCode="TG" value="228"
                      >Togo (+228)</option
                    >
                    <option data-countryCode="TO" value="676"
                      >Tonga (+676)</option
                    >
                    <option data-countryCode="TT" value="1868"
                      >Trinidad &amp; Tobago (+1868)</option
                    >
                    <option data-countryCode="TN" value="216"
                      >Tunisia (+216)</option
                    >
                    <option data-countryCode="TR" value="90"
                      >Turkey (+90)</option
                    >
                    <option data-countryCode="TM" value="7"
                      >Turkmenistan (+7)</option
                    >
                    <option data-countryCode="TM" value="993"
                      >Turkmenistan (+993)</option
                    >
                    <option data-countryCode="TC" value="1649"
                      >Turks &amp; Caicos Islands (+1649)</option
                    >
                    <option data-countryCode="TV" value="688"
                      >Tuvalu (+688)</option
                    >
                    <option data-countryCode="UG" value="256"
                      >Uganda (+256)</option
                    >
                    <!-- <option data-countryCode="GB" value="44">UK (+44)</option> -->
                    <option data-countryCode="UA" value="380"
                      >Ukraine (+380)</option
                    >
                    <option data-countryCode="AE" value="971"
                      >United Arab Emirates (+971)</option
                    >
                    <option data-countryCode="UY" value="598"
                      >Uruguay (+598)</option
                    >
                    <!-- <option data-countryCode="US" value="1">USA (+1)</option> -->
                    <option data-countryCode="UZ" value="7"
                      >Uzbekistan (+7)</option
                    >
                    <option data-countryCode="VU" value="678"
                      >Vanuatu (+678)</option
                    >
                    <option data-countryCode="VA" value="379"
                      >Vatican City (+379)</option
                    >
                    <option data-countryCode="VE" value="58"
                      >Venezuela (+58)</option
                    >
                    <option data-countryCode="VN" value="84"
                      >Vietnam (+84)</option
                    >
                    <option data-countryCode="VG" value="84"
                      >Virgin Islands - British (+1284)</option
                    >
                    <option data-countryCode="VI" value="84"
                      >Virgin Islands - US (+1340)</option
                    >
                    <option data-countryCode="WF" value="681"
                      >Wallis &amp; Futuna (+681)</option
                    >
                    <option data-countryCode="YE" value="969"
                      >Yemen (North)(+969)</option
                    >
                    <option data-countryCode="YE" value="967"
                      >Yemen (South)(+967)</option
                    >
                    <option data-countryCode="ZM" value="260"
                      >Zambia (+260)</option
                    >
                    <option data-countryCode="ZW" value="263"
                      >Zimbabwe (+263)</option
                    >
                  </optgroup>
                </Field>

                <Field
                  name="contact"
                  rules="required|numeric"
                  placeholder="enter phone number"
                  class="form-control col-start-2 col-span-4"
                />
                <ErrorMessage class="col-start-1 col-span-2" name="contact" />
              </div>
              <br />
              <br />

              <span>Email: </span>
              <Field
                name="email"
                rules="required|email"
                placeholder="enter email address"
                class="form-control"
                style="margin-top: 10px"
              />
              <ErrorMessage name="email" />
              <br />
              <br />
              <span>Blood Group: </span>
              <Field
                name="bloodGroup"
                as="select"
                rules="required"
                class="form-control"
                style="margin-top: 10px"
              >
                <option>A+</option>
                <option>A-</option>
                <option>B+</option>
                <option>B-</option>
                <option>O+</option>
                <option>O-</option>
                <option>AB+</option>
                <option>AB-</option>
              </Field>
              <ErrorMessage name="bloodGroup" />
              <br />
              <br />
              <span>Relationship: </span>
              <Field
                name="relation"
                rules="required"
                placeholder="enter relationship with patient"
                class="form-control"
                style="margin-top: 10px"
              />
              <ErrorMessage name="relation" />
              <br />
              <br />

              <div class="grid grid-cols-4 gap-4">
                <input
                  type="submit"
                  class="btn btn-primary col-start-2 col-span-1"
                  value="Submit"
                  data-dismiss="modal"
                />
                <input
                  class="btn btn-secondary col-start-3 col-span-1"
                  value="Close"
                  data-dismiss="modal"
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <ModalMedicalHistory @add-medical-history="addMedicalHistory" />
    <ModalDoctorConsultations
      @add-doctor-consultations="addDoctorConsultations"
    />
    <ModalFoodAllergy @add-food-allergy="addFoodAllergy" />
    <ModalDrugAllergy @add-drug-allergy="addDrugAllergy" />
    <ModalCurrentRegimen @add-current-regimen="addCurrentRegimen" />
    <ModalImmunizationRecords
      @add-immunization-record="addImmunizationRecord"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import StackedBarChart1 from "@/components/stacked-bar-chart-1/Main.vue";
import SimpleLineChart from "@/components/simple-line-chart/Main.vue";
import SimpleLineChart1 from "@/components/simple-line-chart-1/Main.vue";
import SimpleLineChart2 from "@/components/simple-line-chart-2/Main.vue";
import ModalMedicalHistory from "./ModalMedicalHistory.vue";
import ModalCurrentRegimen from "./ModalCurrentRegimen.vue";
import ModalImmunizationRecords from "./ModalImmunizationRecords.vue";
import ModalDoctorConsultations from "./ModalDoctorConsultations.vue";
import ModalFoodAllergy from "./ModalFoodAllergy.vue";
import ModalDrugAllergy from "./ModalDrugAllergy.vue";
import SearchAutoComplete from "./SearchAutocomplete.vue";
import { faker } from "@/utils/faker";
import { Form, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { required, email, numeric } from "@vee-validate/rules";

defineRule("required", required);
defineRule("email", email);
defineRule("numeric", numeric);

export default defineComponent({
  props: ["repository"],
  data() {
    return {
      anchor: "records",
      patientInfo: {},
      dob: "",
      dob2: "",
      bmi: "",
      height: [],
      data: [],
      numCondition: [""],
      defaultICD: [],
      defaultICDTreatment: []
    };
  },
  components: {
    StackedBarChart1,
    SimpleLineChart,
    SimpleLineChart1,
    SimpleLineChart2,
    Form,
    Field,
    ErrorMessage,
    ModalMedicalHistory,
    ModalDoctorConsultations,
    ModalFoodAllergy,
    ModalDrugAllergy,
    ModalCurrentRegimen,
    ModalImmunizationRecords,
    SearchAutoComplete
  },

  created() {
    this.data = faker;
    this.defaultICD =
      faker[0].patientHistory[Object.keys(faker[0].patientHistory)[0]];
    this.defaultICDTreatment =
      faker[0].patientTreatment[Object.keys(faker[0].patientTreatment)[0]];
    if (this.repository !== undefined) {
      this.patientInfo = JSON.parse(this.repository);
    } else {
      this.patientInfo = undefined;
    }
  },
  methods: {
    setAnchor(value) {
      this.anchor = value;
    },
    getHeight(h) {
      this.height = 3.2 * h;
      document.getElementById("height here").innerHTML = this.height;
    },
    setCondition(event) {
      const index = event.target.id;
      this.numCondition[index] = event.target.value;
    },
    addCondition(event) {
      this.numCondition.push("");
    },
    checkForm(values) {
      let json = values;
      json["contact"] = json["countryCode"] + json["contact"];
      json["dob"] = this.dob;
      delete json["countryCode"];
      json["conditions"] = this.numCondition;
      console.log(json);
      this.data[0].familyHistory.push(json);
      this.numCondition = [""];
    },
    checkForm2(values) {
      let json = values;
      json["contact"] = json["countryCode"] + json["contact"];
      json["dob"] = this.dob2;

      delete json["countryCode"];
      console.log(json);
      this.data[0].emergency.push(json);
    },
    addMedicalHistory(data) {
      this.data[0].medicalHistory.push(data);
    },
    addDoctorConsultations(data) {
      this.data[0].doctor.push(data);
    },
    addFoodAllergy(data) {
      this.data[0].foodAllergy.push(data);
    },
    addDrugAllergy(data) {
      this.data[0].drugAllergy.push(data);
    },
    addCurrentRegimen(data) {
      this.data[0].regimen.push(data);
    },
    addImmunizationRecord(data) {
      this.data[0].immunization.push(data);
    },
    setICD(data) {
      this.defaultICD = [];
      this.defaultICD = this.data[0].patientHistory[data];
      this.defaultICDTreatment = [];
      this.defaultICDTreatment = this.data[0].patientTreatment[data];
    }
  }
});
</script>

<style scoped>
input + span,
select + span {
  display: block;
  color: red;
}
</style>
