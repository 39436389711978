<template>
  <div class="flex h-full">
    <div class="h-1/2 mr-5 flex flex-col justify-center">
      <draggable
        class="dragArea list-group p-5 border-b-2 border-black dark:border-white"
        :list="list1"
        item-key="id"
        :group="{ name: 'people', pull: 'clone', put: false }"
        @change="log"
        :clone="cloneList"
      >
        <template #item="{element}">
          <h1 class="mt-5 text-4xl">{{ element.name }}</h1>
        </template>
      </draggable>
      <div
        class="p-5 mt-5 text-2xl cursor-pointer"
        v-for="(label, key) in Object.keys(items)"
        :key="key"
      >
        <span @click="expanded[label] = !expanded[label]"
          >{{ expanded[label] === true ? "&#9660;" : "&#9658;" }}
          {{ label }}</span
        >
        <div v-show="expanded[label] === true" class="ml-5">
          <div
            v-for="(item, key2) in items[label]"
            :key="key2"
            @click="focus = item.id"
          >
            {{ item.name }} - {{ item.id }}
          </div>
        </div>
      </div>
      <div class="btn btn-primary mt-5" @click="focus = ''">Deselect All</div>
    </div>
    <div class="flex-1 flex flex-col">
      <h3
        class="text-3xl text-center bg-gray-400 dark:bg-gray-800 mt-5 rounded-t-lg border-b-2 border-black py-5"
      >
        Drag components to create templates
      </h3>
      <draggable
        class="dragArea list-group flex-1 bg-gray-400 dark:bg-gray-800 p-8"
        :list="list2"
        item-key="id"
        group="people"
        @change="log"
      >
        <template #item="{element}">
          <div class="mt-5">
            <div
              v-if="element.name === 'Checkbox'"
              class="flex items-center w-full"
              :class="element.id === focus && 'border-2 border-black'"
              :style="element.style"
              @click="focus = element.id"
            >
              <input
                class="form-check-input mr-5"
                type="checkbox"
                v-model="element.value"
              />
              <input
                type="text"
                v-model="element.label"
                class="bg-transparent border-none text-xl focus:outline-none"
              />
            </div>
            <input
              v-else-if="element.name === 'TextField'"
              type="text"
              @click="focus = element.id"
              class="form-control"
              :class="element.id === focus && 'border-2 border-black'"
              :style="element.style"
            />
            <input
              type="text"
              v-else-if="element.name === 'Text'"
              v-model="element.value"
              @click="focus = element.id"
              class="bg-transparent text-xl w-full"
              :class="element.id === focus && 'border-2 border-black'"
              :style="element.style"
            />
            <draggable
              class="dragArea list-group w-full min-h-24 bg-gray-200 p-8"
              :style="element.style"
              :class="element.id === focus && 'border-2 border-black'"
              :list="element.children"
              @click="focus = element.id"
              v-else
              item-key="id"
              group="people"
              @change="log"
              ><template #item="{element}">
                <div class="mt-5">
                  <div
                    v-if="element.name === 'Checkbox'"
                    class="flex items-center w-full"
                    :class="element.id === focus && 'border-2 border-black'"
                    :style="element.style"
                    @click="focus = element.id"
                  >
                    <input
                      class="form-check-input mr-5"
                      type="checkbox"
                      v-model="element.value"
                    />
                    <input
                      type="text"
                      v-model="element.label"
                      class="bg-transparent border-none text-xl focus:outline-none"
                    />
                  </div>
                  <input
                    v-else-if="element.name === 'TextField'"
                    type="text"
                    @click="focus = element.id"
                    class="form-control"
                    :class="element.id === focus && 'border-2 border-black'"
                    :style="element.style"
                  />
                  <input
                    type="text"
                    v-else-if="element.name === 'Text'"
                    v-model="element.value"
                    @click="focus = element.id"
                    class="bg-transparent text-xl w-full"
                    :class="element.id === focus && 'border-2 border-black'"
                    :style="element.style"
                  />
                </div>
              </template>
            </draggable>
          </div>
        </template>
      </draggable>
    </div>
    <div class="ml-5 flex flex-col mt-5">
      <div class="text-4xl">Styles</div>
      <div class="text-2xl mt-3">Background Color</div>
      <ColorPicker
        theme="light"
        :color="backgroundColor"
        :sucker-canvas="suckerCanvas"
        :sucker-area="suckerArea"
        @changeColor="changebackgroundColor"
      />
      <div class="text-2xl mt-3">Text</div>
      <ColorPicker
        theme="light"
        :color="color"
        :sucker-canvas="suckerCanvas"
        :sucker-area="suckerArea"
        @changeColor="changeColor"
      />
      <div v-show="typeExtractor(list2, focus) === 'Text'">
        <div class="text-2xl mt-3">Text Alignment</div>
        <select
          class="form-select form-select-lg sm:mt-2 sm:mr-2"
          @change="textAlignment"
        >
          <option value="left">Left</option>
          <option value="center">Center</option>
          <option value="right">Right</option>
        </select>
      </div>
      <div v-show="typeExtractor(list2, focus) === 'TextField'">
        <div class="text-2xl mt-3">Width</div>
        <input
          type="range"
          class="form-control"
          @change="widthChange"
          v-model="width"
          min="0"
          max="100"
          step="10"
        />
        <label>{{ width }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { ColorPicker } from "vue-color-kit";
import "vue-color-kit/dist/vue-color-kit.css";
let idGlobal = 0;

export default {
  components: {
    draggable,
    ColorPicker
  },
  data() {
    return {
      color: "#59c7f9",
      backgroundColor: "#59c7f9",
      width: "100",
      suckerCanvas: null,
      focus: "",
      suckerArea: [],
      isSucking: false,
      list1: [
        {
          name: "TextField",
          id: 1,
          style: {}
        },
        {
          name: "Checkbox",
          id: 2,
          value: false,
          label: "Default Checkbox",
          style: {}
        },
        {
          name: "Text",
          id: 3,
          value: "Text",
          style: {}
        },
        {
          name: "Container",
          id: 4,
          style: {}
        }
      ],
      list2: [],
      items: {},
      expanded: {}
    };
  },
  methods: {
    changeStyle(array, property, payload) {
      array.forEach(element => {
        if (this.focus === element.id) {
          element.style[property] = payload;
        } else if (element.name === "Container") {
          this.changeStyle(element.children, property, payload);
        }
      });
    },
    changebackgroundColor(color) {
      const { r, g, b, a } = color.rgba;
      this.backgroundColor = `rgba(${r}, ${g}, ${b}, ${a})`;
      this.changeStyle(this.list2, "background-color", this.backgroundColor);
    },
    changeColor(color) {
      const { r, g, b, a } = color.rgba;
      this.color = `rgba(${r}, ${g}, ${b}, ${a})`;
      this.changeStyle(this.list2, "color", this.color);
    },
    widthChange(event) {
      this.changeStyle(this.list2, "width", event.target.value + "%");
    },
    textAlignment(event) {
      this.changeStyle(this.list2, "text-align", event.target.value);
    },
    typeExtractor(array, id) {
      for (const element of array) {
        if (element.id === id) {
          return element.name;
        } else if (element.name === "Container") {
          if (this.typeExtractor(element.children, id) !== null) {
            return this.typeExtractor(element.children, id);
          }
        }
      }
      return null;
    },
    log: function(evt) {
      console.log(this.list2);
      switch (Object.keys(evt)[0]) {
        case "added":
          if (!(evt.added.element.name in this.items)) {
            this.expanded[evt.added.element.name] = false;
            this.items[evt.added.element.name] = [];
          }
          this.items[evt.added.element.name].push(evt.added.element);
          break;
        default:
          break;
      }
    },
    cloneList(data) {
      let newData = JSON.parse(JSON.stringify(data));
      newData.id = idGlobal++;
      if (newData.name === "Container") {
        newData.children = [];
      }
      return newData;
    }
  }
};
</script>
<style scoped></style>
