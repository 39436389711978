<template>
  <!-- Modal -->
  <div id="editRoomModal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLongTitle" class="modal-title">Edit Room</h5>
        </div>
        <div class="p-6">
          <Form autocomplete="off" @submit="onSubmit">
            <span>Room Number: </span>
            <Field
              v-model="name"
              name="name"
              rules="lol"
              placeholder="enter name"
              class="form-control"
            />
            <ErrorMessage name="name" />
            <br />
            <br />
            <span>Capacity: </span>
            <Field
              v-model="capacity"
              name="capacity"
              rules="lol"
              type="number"
              placeholder="enter capacity"
              class="form-control"
            />
            <ErrorMessage name="capacity" />
            <br />
            <br />
            <span>Category: </span>
            <Field
              v-model="category"
              name="category"
              rules="lol"
              placeholder="enter category"
              class="form-control"
            />
            <ErrorMessage name="category" />
            <br />
            <br />
            <span>Images: </span>
            <Field
              v-model="images"
              name="images"
              rules="lol"
              placeholder="enter images"
              class="form-control"
            />
            <ErrorMessage name="images" />
            <br />
            <br />
            <div class="flex items-center">
              <span class="mr-2">Status </span>
              <input
                v-model="status"
                type="checkbox"
                class="form-check-switch"
              />
            </div>
            <br />
            <input
              style="
                width: 75px;
                margin-left: 115px;
                margin-right: 10px;
                margin-top: 15px;
              "
              type="submit"
              class="btn btn-primary"
              data-dismiss="modal"
              value="Submit"
            />
            <input
              style="width: 75px; margin-top: 15px"
              class="btn btn-secondary"
              value="Close"
              data-dismiss="modal"
              @click="close"
            />
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { numeric } from "@vee-validate/rules";

defineRule("lol", (value) => {
  if (value === null || value === undefined) {
    return true;
  } else if (!value || !value.length) {
    return false;
  }

  return true;
});
defineRule("numeric", numeric);

export default {
  components: {
    Form,
    Field,
    ErrorMessage
  },
  props: ["roomInfo"],
  data() {
    return {
      name: this.roomInfo[0].name,
      images: this.roomInfo[0].images[0],
      category: this.roomInfo[0].category,
      capacity: this.roomInfo[0].capacity,
      status: this.roomInfo[0].status
    };
  },
  methods: {
    onSubmit() {
      let data = {
        name: `Room - ${this.name}`,
        images: [this.images, this.images, this.images],
        status: this.status,
        category: this.category,
        capacity: this.capacity,
        id: this.roomInfo[0].id
      };
      console.log(data);
      this.$emit("edit-room", data);
    },
  },
};
</script>

<style scoped>
input + span,
select + span {
  display: block;
  color: red;
}
</style>
