<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Rooms</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="flex mt-5 sm:mt-0">
          <button
            id="tabulator-print"
            class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2" /> Print
          </button>
          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-csv"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onExportCsv"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                </a>
                <a
                  id="tabulator-export-json"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onExportJson()"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                </a>
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                </a>
                <a
                  id="tabulator-export-html"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onExportHtml"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export HTML
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="table"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <RoomModal :modal-data="modalData" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Tabulator from "tabulator-tables";
import { faker } from "../../utils/faker";
import xlsx from "xlsx";
import RoomModal from "./RoomModal.vue";

export default defineComponent({
  components: { RoomModal },
  props: ["branchData"],

  data: function () {
    return {
      tabulator: null, //variable to hold your table
      tableData: [], //data for table to display
      modalData: {},
    };
  },
  created() {
    let obj1 = faker[0].rooms.filter(
      (data) => data.branch.id === this.branchData
    );
    this.tableData = obj1;
  },
  mounted() {
    var self = this;
    //instantiate Tabulator when element is mounted
    this.tabulator = new Tabulator(this.$refs.table, {
      data: this.tableData, //link data to table
      reactiveData: true, //enable data reactivity
      printAsHtml: true,
      printStyled: true,
      // pagination: "remote",
      pagination: "local",
      paginationSize: 20,
      paginationSizeSelector: [10, 20, 30, 40],
      layout: "fitColumns",
      responsiveLayout: "collapse",
      placeholder: "No matching records found",
      columns: [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false,
        },
        // For HTML table
        {
          title: "Room Number",
          minWidth: 100,
          responsive: 0,
          field: "name",
          vertAlign: "middle",
          print: false,
          download: false,
          headerFilter: true,
          editor: true,
          formatter(cell) {
            return `<a ><div>
                <div class="font-medium whitespace-nowrap" >${
                  cell.getData().name
                }</div>
                <div class="text-gray-600 text-xs whitespace-nowrap">${
                  cell.getData().category
                }</div>
              </div></a>`;
          },
        },
        {
          title: "Images",
          minWidth: 200,
          field: "images",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            return `<div class="flex lg:justify-center">
                  <div class="intro-x w-10 h-10 image-fit">
                    <img alt="Mazecare Admin Demo 1" class="rounded-full" src="${require("@/assets/images/" +
                      cell.getData().images[0])}">
                  </div>
                  <div class="intro-x w-10 h-10 image-fit -ml-5">
                    <img alt="Mazecare Admin Demo 1" class="rounded-full" src="${require("@/assets/images/" +
                      cell.getData().images[1])}">
                  </div>
                  <div class="intro-x w-10 h-10 image-fit -ml-5">
                    <img alt="Mazecare Admin Demo 1" class="rounded-full" src="${require("@/assets/images/" +
                      cell.getData().images[2])}">
                  </div>
              </div>`;
          },
        },
        {
          title: "Capactiy",
          minWidth: 200,
          field: "capacity",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          editor: true,
        },
        {
          title: "Status",
          minWidth: 200,
          field: "status",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            return `<div class="flex items-center lg:justify-center ${
              cell.getData().status ? "text-theme-10" : "text-theme-24"
            }">
                <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                  cell.getData().status ? "Active" : "Inactive"
                }
              </div>`;
          },
        },
        // {
        //   title: "Actions",
        //   minWidth: 200,
        //   field: "actions",
        //   responsive: 1,
        //   hozAlign: "center",
        //   vertAlign: "middle",
        //   print: false,
        //   download: false,
        //   formatter: "buttonCross",
        //   width: 40,
        //   cellClick: function (e, cell) {
        //     cell.getRow().delete();
        //   },
        // },
        // For print format
        {
          title: "ROOM NUMBER",
          field: "name",
          visible: false,
          print: true,
          download: true,
        },
        {
          title: "CATEGORY",
          field: "category",
          visible: false,
          print: true,
          download: true,
        },
        {
          title: "CAPACITY",
          field: "capacity",
          visible: false,
          print: true,
          download: true,
        },
        {
          title: "STATUS",
          field: "status",
          visible: false,
          print: true,
          download: true,
          formatterPrint(cell) {
            return cell.getValue() ? "Active" : "Inactive";
          },
        },
        {
          title: "IMAGE 1",
          field: "images",
          visible: false,
          print: true,
          download: true,
          formatterPrint(cell) {
            return cell.getValue()[0];
          },
        },
        {
          title: "IMAGE 2",
          field: "images",
          visible: false,
          print: true,
          download: true,
          formatterPrint(cell) {
            return cell.getValue()[1];
          },
        },
        {
          title: "IMAGE 3",
          field: "images",
          visible: false,
          print: true,
          download: true,
          formatterPrint(cell) {
            return cell.getValue()[2];
          },
        },
      ],
      rowClick: function (e, row) {
        let data = row.getData();
        console.log(data);
        self.$router.push({
          name: "side-menu-branch-room-overview",
          params: { room: JSON.stringify(data), roomid: data.id },
        });
      },
    });
  },
  methods: {
    addBranchRoom(data) {
      const room = faker[0].rooms.filter(
        (data) => data.branch.id === this.branchData
      );
      const rooms = faker[0].rooms.branch.filter(
        (data) => data.id === this.branchData
      );
    },
    onExportCsv() {
      this.tabulator.download("csv", "data.csv");
    },

    onExportJson() {
      this.tabulator.download("json", "data.json");
    },

    onExportXlsx() {
      const win = window;
      win.XLSX = xlsx;
      this.tabulator.download("xlsx", "data.xlsx", {
        sheetName: "Products",
      });
    },

    onExportHtml() {
      this.tabulator.download("html", "data.html", {
        style: true,
      });
    },

    // Print
    onPrint() {
      this.tabulator.print();
    },
  },
});
</script>