<template>
  <div
    v-if="!equipmentCompleted"
    class="w-full relative text-gray-700 dark:text-gray-300 sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0"
  >
    <SearchIcon
      style="color: grey; margin-left: 10px"
      class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
    />
    <Field
      name="equipment"
      autocomplete="off"
      class="form-control"
      v-model="equipmentName"
      @input="onChange"
      placeholder="Full Name"
      :rules="error === true && lol"
    />
    <ErrorMessage
      name="equipment"
      as="span"
      class="text-red-500"
      v-if="error === true"
      >Please enter a valid equipment!</ErrorMessage
    >
    <ul v-show="isOpen" class="autocomplete-results">
      <li
        v-for="(equipment, i) in equipments"
        :key="i"
        @click="setequipment(equipment)"
        class="autocomplete-result"
      >
        {{ equipment.name }}
      </li>
    </ul>
  </div>
  <div v-else class="border-2 border-black dark:border-white relative">
    <XCircleIcon
      class="block right-0 absolute pb-1 cursor-pointer"
      @click="$emit('clear', 'equipment')"
    />
    <div class="p-4">
      <div class="flex justify-between">
        <div>Equipment Name</div>
        <div>{{ equipment.name }}</div>
      </div>
      <div class="flex justify-between">
        <div>Equipment Type</div>
        <div>{{ equipment.category }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { faker } from "@/utils/faker";

defineRule("lol", value => {
  if (value === null || value === undefined) {
    return true;
  } else if (!value || !value.length) {
    return "This field is required";
  }

  return true;
});

export default {
  props: ["equipment", "error", "equipmentCompleted"],

  data() {
    return {
      equipments: [],
      equipmentName: "",
      isOpen: false
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  components: {
    Field,
    ErrorMessage
  },
  methods: {
    filterResults() {
      this.equipments = faker[0].equipment.filter(
        item =>
          item.name.toLowerCase().indexOf(this.equipmentName.toLowerCase()) > -1
      );
    },
    onChange(event) {
      if (event.target.value == "") {
        this.isOpen = false;
        this.equipments = [];
        return;
      }
      this.filterResults();
      if (this.equipments.length === 0) {
        this.isOpen = false;
      } else {
        this.isOpen = true;
      }
    },
    setequipment(equipment) {
      this.equipmentName = equipment.name;
      this.isOpen = false;
      this.$emit("set-equipment", equipment);
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    }
  }
};
</script>

<style>
.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  min-height: 1em;
  max-height: 6em;
  overflow: auto;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #4aae9b;
  color: white;
}
</style>
