<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Manage Locations</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="
          intro-y
          col-span-12
          flex flex-wrap
          sm:flex-nowrap
          items-center
          mt-2
        "
      >
        <a
          type="button"
          class="btn btn-primary shadow-md mr-2"
          data-toggle="modal"
          data-target="#exampleModalCenter1"
          >Add New Branch</a
        >
        <div class="hidden md:block mx-auto text-gray-600">
          Showing {{ filteredBranches.length }} of
          {{ filteredBranches.length }} entries
        </div>
        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input
              v-model="search"
              type="text"
              class="form-control w-56 box pr-10 placeholder-theme-8"
              placeholder="Search..."
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
        </div>
      </div>
      <!-- BEGIN: Users Layout -->
      <div
        v-for="(eachbranch, index) in filteredBranches"
        :key="index"
        class="intro-y col-span-12 md:col-span-6"
        @remove="removeTask(index)"
      >
        <div class="box">
          <div
            class="
              flex flex-col
              lg:flex-row
              items-center
              p-5
              border-b border-gray-200
              dark:border-dark-5
            "
          >
            <div class="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
              <img
                alt="Mazecare Admin Demo 1"
                class="rounded-full"
                :src="require(`@/assets/images/profile-1.jpg`)"
              />
            </div>
            <div
              class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0"
            >
              <div
                class="font-medium cursor-pointer"
                @click="redirect(eachbranch.id)"
              >
                {{ eachbranch.name }}
              </div>
              <div class="text-gray-600 text-xs mt-0.5">
                {{ eachbranch.email }}
              </div>
            </div>
          </div>
          <div
            class="flex flex-wrap lg:flex-nowrap items-center justify-end p-5"
          >
            <button class="btn btn-primary py-1 px-2 mr-2">Message</button>
            <!-- <button
              class="btn btn-primary py-1 px-2 mr-2"
              @click="removeBranch(branch)"
            >
              Delete
            </button>
            <a
              class="btn btn-primary py-1 px-2"
              @click="editBranch(branch, index)"
            >
              Edit
            </a> -->
          </div>
        </div>
      </div>
      <!-- END: Users Layout -->
      <!-- BEGIN: Pagination -->
      <div
        class="
          intro-y
          col-span-12
          flex flex-wrap
          sm:flex-row sm:flex-nowrap
          items-center
        "
      >
        <ul class="pagination">
          <li>
            <a class="pagination__link" href="">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link pagination_link--active" href="">1</a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronRightIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronsRightIcon class="w-4 h-4" />
            </a>
          </li>
        </ul>
        <select class="w-20 form-select box mt-3 sm:mt-0">
          <option>10</option>
          <option>25</option>
          <option>35</option>
          <option>50</option>
        </select>
      </div>
      <!-- END: Pagination -->
    </div>
    <AddBranchModal @add-branch="addBranch" />
  </div>
</template>

<script>
import { faker } from "@/utils/faker";
import AddBranchModal from "./AddBranchModal.vue";
import _ from "lodash";

export default {
  components: {
    AddBranchModal,
  },
  data() {
    return {
      branches: [],
      branch: {
        name: null,
        email: null,
        address: {
          address1: null,
          address2: null,
          city: null,
        },
        contact: null,
        hours: null,
      },
      search: "",
      selectedBranch: null,
      isEditing: false,
      initialHours: [
        {
          name: "Sunday",
          regularhours: [{ open: "", close: "" }],
          specialhours: [{ open: "", close: "" }],
          isOpen: false,
        },
        {
          name: "Monday",
          regularhours: [{ open: "", close: "" }],
          specialhours: [{ open: "", close: "" }],
          isOpen: false,
        },
        {
          name: "Tuesday",
          regularhours: [{ open: "", close: "" }],
          specialhours: [{ open: "", close: "" }],
          isOpen: false,
        },
        {
          name: "Wednesday",
          regularhours: [{ open: "", close: "" }],
          specialhours: [{ open: "", close: "" }],
          isOpen: false,
        },
        {
          name: "Thursday",
          regularhours: [{ open: "", close: "" }],
          specialhours: [{ open: "", close: "" }],
          isOpen: false,
        },
        {
          name: "Friday",
          regularhours: [{ open: "", close: "" }],
          specialhours: [{ open: "", close: "" }],
          isOpen: false,
        },
        {
          name: "Saturday",
          regularhours: [{ open: "", close: "" }],
          specialhours: [{ open: "", close: "" }],
          isOpen: false,
        },
      ],
    };
  },
  computed: {
    filteredBranches: function () {
      return this.branches.filter((branch) => {
        return (
          branch.name.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
        );
      });
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.branches = faker[0].branches;
    },
    addBranch(data) {
      const lastitem =
        faker[0].branchDetails[faker[0].branchDetails.length - 1].id;
      this.branches.push({
        id: lastitem + 1,
        hours: this.initialHours,
        ...data,
      });
      faker[0].branchDetails.push({
        id: lastitem + 1,
        hours: this.initialHours,
        ...data,
      });
    },
    redirect(id) {
      let obj1 = faker[0].branchDetails.filter((data) => data.id === id)[0];
      let branchData = { ...obj1 };
      this.$router.push({
        name: "side-menu-branch-overview",
        params: { id: JSON.stringify(branchData.id) }
      });
    },
  },
};
</script>
