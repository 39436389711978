<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Appointments</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link to="appointment-template"
          ><button class="btn btn-primary shadow-md mr-2">
            Appointment Templates
          </button></router-link
        >
        <button class="btn btn-primary shadow-md mr-2">
          Print Schedule
        </button>
        <div class="dropdown ml-auto sm:ml-0">
          <button
            class="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300"
            aria-expanded="false"
          >
            <span class="w-5 h-5 flex items-center justify-center">
              <PlusIcon class="w-4 h-4" />
            </span>
          </button>
          <div class="dropdown-menu w-40">
            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
              <a
                href=""
                class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              >
                <Share2Icon class="w-4 h-4 mr-2" /> Share
              </a>
              <a
                href=""
                class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              >
                <SettingsIcon class="w-4 h-4 mr-2" /> Settings
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-56 relative text-gray-700 dark:text-gray-300 m-auto">
      <div class="search hidden sm:block">
        <input
          type="text"
          class="search__input form-control dark:bg-dark-1 border-transparent placeholder-theme-8"
          placeholder="Search..."
          v-model="mainSearch"
        />
        <SearchIcon class="search__icon dark:text-gray-300" />
      </div>
    </div>
    <div class="grid grid-cols-12 gap-5 mt-5">
      <!-- BEGIN: Calendar Side Menu -->
      <div class="col-span-12 xl:col-span-4 xxl:col-span-3">
        <div class="box p-5 intro-y">
          <button
            type="button"
            class="btn btn-primary w-full mt-2"
            @click="$vfm.show('appointment')"
          >
            <Edit3Icon class="w-4 h-4 mr-2" />
            Add New Appointment
          </button>
          <!-- <FullCalendarDraggable
            id="calendar-events"
            :options="dragableOptions"
            class="border-t border-b border-gray-200 dark:border-dark-5 mt-6 mb-5 py-3"
          >
            <div
              v-for="(entry, key) in appointment"
              :key="key"
              class="relative"
            >
              <div
                class="event p-3 -mx-3 cursor-pointer transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-dark-1 rounded-md flex items-center"
              >
                <div class="w-2 h-2 bg-theme-22 rounded-full mr-3"></div>
                <div class="pr-10">
                  <div class="event__title truncate">{{ entry.title }}</div>
                  <div class="text-gray-600 text-xs mt-0.5">
                    <span class="event__days">{{ entry.days }}</span> Days
                    <span class="mx-1">•</span> {{ entry.time }}
                  </div>
                </div>
              </div>
              <a
                class="flex items-center absolute top-0 bottom-0 my-auto right-0"
                href=""
              >
                <EditIcon class="w-4 h-4 text-gray-600" />
              </a>
            </div>
            <div
              id="calendar-no-events"
              class="text-gray-600 p-3 text-center hidden"
            >
              No events yet
            </div>
          </FullCalendarDraggable> -->
        </div>
        <div class="box p-2 mt-5 intro-y">
          <div class="pos__tabs nav nav-tabs justify-center" role="tablist">
            <a
              class="flex-1 py-2 rounded-md text-center"
              :class="[nav === 'doctor' ? 'bg-blue-900 text-white' : '']"
              href="javascript:;"
              @click="nav = 'doctor'"
            >
              <UsersIcon /> Doctors</a
            >
            <a
              class="flex-1 py-2 rounded-md text-center"
              :class="[nav === 'room' ? 'bg-blue-900 text-white' : '']"
              href="javascript:;"
              @click="nav = 'room'"
              ><PieChartIcon />Rooms</a
            >
            <a
              class="flex-1 py-2 rounded-md text-center"
              :class="[nav === 'equipment' ? 'bg-blue-900 text-white' : '']"
              href="javascript:;"
              @click="nav = 'equipment'"
              ><ToolIcon />Equipment</a
            >
          </div>
        </div>
        <div class="box p-2 mt-5 intro-y">
          <input
            type="text"
            v-model="search"
            class="form-control mb-5"
            placeholder="Filter by doctors..."
          />
          <ol>
            <li v-for="(todo, key) in filtered" :key="key">
              <div class="flex justify-between">
                <div>
                  <input
                    v-model="todo.done"
                    type="checkbox"
                    class="mr-5"
                    @click="
                      names.has(todo.text)
                        ? names.delete(todo.text)
                        : names.add(todo.text)
                    "
                  />

                  <span v-if="todo.done" class="font-bold">
                    {{ todo.text }}
                  </span>
                  <span v-else>
                    {{ todo.text }}
                  </span>
                </div>
                <div>{{ todo.count }}</div>
              </div>
            </li>
          </ol>
        </div>
        <div class="box p-5 intro-y mt-5">
          <div class="flex">
            <ChevronLeftIcon class="w-5 h-5 text-gray-600" />
            <div class="font-medium text-base mx-auto">April</div>
            <ChevronRightIcon class="w-5 h-5 text-gray-600" />
          </div>
          <div class="grid grid-cols-7 gap-4 mt-5 text-center">
            <div class="font-medium">Su</div>
            <div class="font-medium">Mo</div>
            <div class="font-medium">Tu</div>
            <div class="font-medium">We</div>
            <div class="font-medium">Th</div>
            <div class="font-medium">Fr</div>
            <div class="font-medium">Sa</div>
            <div class="py-0.5 rounded relative text-gray-600">29</div>
            <div class="py-0.5 rounded relative text-gray-600">30</div>
            <div class="py-0.5 rounded relative text-gray-600">31</div>
            <div class="py-0.5 rounded relative">1</div>
            <div class="py-0.5 rounded relative">2</div>
            <div class="py-0.5 rounded relative">3</div>
            <div class="py-0.5 rounded relative">4</div>
            <div class="py-0.5 rounded relative">5</div>
            <div class="py-0.5 bg-theme-29 dark:bg-theme-10 rounded relative">
              6
            </div>
            <div class="py-0.5 rounded relative">7</div>
            <div
              class="py-0.5 bg-theme-26 dark:bg-theme-17 text-white rounded relative"
            >
              8
            </div>
            <div class="py-0.5 rounded relative">9</div>
            <div class="py-0.5 rounded relative">10</div>
            <div class="py-0.5 rounded relative">11</div>
            <div class="py-0.5 rounded relative">12</div>
            <div class="py-0.5 rounded relative">13</div>
            <div class="py-0.5 rounded relative">14</div>
            <div class="py-0.5 rounded relative">15</div>
            <div class="py-0.5 rounded relative">16</div>
            <div class="py-0.5 rounded relative">17</div>
            <div class="py-0.5 rounded relative">18</div>
            <div class="py-0.5 rounded relative">19</div>
            <div class="py-0.5 rounded relative">20</div>
            <div class="py-0.5 rounded relative">21</div>
            <div class="py-0.5 rounded relative">22</div>
            <div class="py-0.5 bg-theme-30 dark:bg-theme-22 rounded relative">
              23
            </div>
            <div class="py-0.5 rounded relative">24</div>
            <div class="py-0.5 rounded relative">25</div>
            <div class="py-0.5 rounded relative">26</div>
            <div class="py-0.5 bg-theme-31 dark:bg-theme-23 rounded relative">
              27
            </div>
            <div class="py-0.5 rounded relative">28</div>
            <div class="py-0.5 rounded relative">29</div>
            <div class="py-0.5 rounded relative">30</div>
            <div class="py-0.5 rounded relative text-gray-600">1</div>
            <div class="py-0.5 rounded relative text-gray-600">2</div>
            <div class="py-0.5 rounded relative text-gray-600">3</div>
            <div class="py-0.5 rounded relative text-gray-600">4</div>
            <div class="py-0.5 rounded relative text-gray-600">5</div>
            <div class="py-0.5 rounded relative text-gray-600">6</div>
            <div class="py-0.5 rounded relative text-gray-600">7</div>
            <div class="py-0.5 rounded relative text-gray-600">8</div>
            <div class="py-0.5 rounded relative text-gray-600">9</div>
          </div>
          <div class="border-t border-gray-200 dark:border-dark-5 pt-5 mt-5">
            <div class="flex items-center">
              <div class="w-2 h-2 bg-theme-22 rounded-full mr-3"></div>
              <span class="truncate">Patient 4</span>
              <div
                class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
              ></div>
              <span class="font-medium xl:ml-auto">23th</span>
            </div>
            <div class="flex items-center mt-4">
              <div
                class="w-2 h-2 bg-theme-26 dark:bg-theme-10 rounded-full mr-3"
              ></div>
              <span class="truncate">Patient 5</span>
              <div
                class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
              ></div>
              <span class="font-medium xl:ml-auto">10th</span>
            </div>
            <div class="flex items-center mt-4">
              <div class="w-2 h-2 bg-theme-23 rounded-full mr-3"></div>
              <span class="truncate">Patient 6</span>
              <div
                class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
              ></div>
              <span class="font-medium xl:ml-auto">31th</span>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Calendar Side Menu -->
      <!-- BEGIN: Calendar Content -->
      <div class="col-span-12 xl:col-span-8 xxl:col-span-9">
        <div class="box p-5">
          <Calendar
            :events="finalEvents"
            :key="counter"
            @add-appointment="addAppointment"
          />
        </div>
      </div>
      <!-- END: Calendar Content -->
    </div>
  </div>
  <vue-final-modal
    v-model="modal"
    name="appointment"
    classes="flex justify-center items-center max-h-screen overflow-y-scroll"
    :key="counter"
    content-class="w-500 max-h-screen m-10"
  >
    <ModalCalender @add-appointment="addAppointment" @toggle="toggle" />
  </vue-final-modal>
</template>

<script>
import { defineComponent } from "vue";
import Calendar from "@/components/calendar/Main.vue";
import ModalCalender from "./ModalCalender.vue";
import { faker } from "../../utils/faker";
import { inject } from "vue";

let data = [{}, {}, {}, {}, {}];
data = data.map(records => {
  return {
    patient:
      faker[0].users[Math.floor(Math.random() * faker[0].users.length)].name,
    doctor:
      faker[0].doctors[Math.floor(Math.random() * faker[0].doctors.length)].name
  };
});

const w2 = [
  {
    title: data[0].patient + "/" + data[0].doctor,
    start: "2021-01-05",
    end: "2021-01-08",
    extendedProps: {
      time: "01:08 PM",
      patient: data[0].patient,
      room:
        faker[0].rooms[Math.floor(Math.random() * faker[0].rooms.length)].name,
      doctor: data[0].doctor.substring(4),
      equipment:
        faker[0].equipment[
          Math.floor(Math.random() * faker[0].equipment.length)
        ].name,
      reason: "Consultation",
      comments: "FPS payment"
    }
  },
  {
    title: "Patient 7",
    start: "2021-01-11",
    end: "2021-01-15",
    extendedProps: {
      time: "01:08 PM",
      patient: data[1].patient,
      doctor: data[1].doctor.substring(4),
      equipment:
        faker[0].equipment[
          Math.floor(Math.random() * faker[0].equipment.length)
        ].name,
      room:
        faker[0].rooms[Math.floor(Math.random() * faker[0].rooms.length)].name,
      reason: "Consultation",
      comments: "FPS payment"
    }
  },
  {
    title: data[2].patient + "/" + data[2].doctor,
    start: "2021-01-17",
    end: "2021-01-21",
    extendedProps: {
      time: "01:08 PM",
      patient: data[2].patient,
      room:
        faker[0].rooms[Math.floor(Math.random() * faker[0].rooms.length)].name,
      doctor: data[2].doctor.substring(4),
      equipment:
        faker[0].equipment[
          Math.floor(Math.random() * faker[0].equipment.length)
        ].name,
      reason: "Consultation",
      comments: "FPS payment"
    }
  },
  {
    title: "Patient 9",
    start: "2021-01-21",
    end: "2021-01-24",
    extendedProps: {
      time: "01:08 PM",
      patient:
        faker[0].users[Math.floor(Math.random() * faker[0].users.length)].name,
      room:
        faker[0].rooms[Math.floor(Math.random() * faker[0].rooms.length)].name,
      doctor: faker[0].doctors[
        Math.floor(Math.random() * faker[0].doctors.length)
      ].name.substring(4),
      equipment:
        faker[0].equipment[
          Math.floor(Math.random() * faker[0].equipment.length)
        ].name,
      reason: "Consultation",
      comments: "FPS payment"
    }
  },
  {
    title: data[4].patient + "/" + data[4].doctor,
    start: "2021-01-24",
    end: "2021-01-27",
    extendedProps: {
      time: "01:08 PM",
      patient: data[4].patient,
      room:
        faker[0].rooms[Math.floor(Math.random() * faker[0].rooms.length)].name,
      doctor: data[4].doctor.substring(4),
      equipment:
        faker[0].equipment[
          Math.floor(Math.random() * faker[0].equipment.length)
        ].name,
      reason: "Consultation",
      comments: "FPS payment"
    }
  }
];
let obj = {};
let uniq = {};
w2.forEach(data => {
  if (data.extendedProps.doctor in obj) {
    obj[data.extendedProps.doctor] += 1;
  } else {
    obj[data.extendedProps.doctor] = 1;
  }
});
uniq = Object.keys(obj).map(data => {
  return { done: false, text: data, count: obj[data] };
});

console.log(faker);
export default defineComponent({
  components: {
    Calendar,
    ModalCalender
  },
  data() {
    return {
      nav: "doctor",
      todos: uniq,
      search: "",
      mainSearch: "",
      modal: false,
      counter: 0,
      events: w2,
      names: new Set()
    };
  },
  watch: {
    nav: function(newVal, oldVal) {
      this.names = new Set();
      let obj = {};
      this.events.forEach(data => {
        if (!data.extendedProps[newVal]) {
          return;
        }
        if (data.extendedProps[newVal] in obj) {
          obj[data.extendedProps[newVal]] += 1;
        } else {
          obj[data.extendedProps[newVal]] = 1;
        }
      });
      this.todos = Object.keys(obj).map(data => {
        return { done: false, text: data, count: obj[data] };
      });
    }
  },
  methods: {
    count(list, target) {
      let count = 1;
      list.some((data, index) => {
        if (data.text === target) {
          count = data.count + 1;
          this.todos[index] = { ...data, count: count };
          return true;
        }
        return false;
      });
      if (count === 1) {
        this.todos.push({ done: false, text: target, count: 1 });
      }
    },
    addAppointment(data) {
      this.events.push(data);
      this.count(this.todos, data.extendedProps[this.nav]);
      this.$vfm.hide("appointment");
      this.counter += 1;
    },
    toggle() {
      this.$vfm.hide("appointment");
      this.counter += 1;
    }
  },

  computed: {
    filtered() {
      return this.todos.filter(todo => {
        return todo.text.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    finalEvents() {
      this.counter += 1;
      let temp = [];
      if (this.mainSearch) {
        this.events.forEach(data => {
          if (
            data.title.toLowerCase().includes(this.mainSearch.toLowerCase()) ||
            data.extendedProps.patient
              .toLowerCase()
              .includes(this.mainSearch.toLowerCase()) ||
            data.extendedProps.doctor
              .toLowerCase()
              .includes(this.mainSearch.toLowerCase())
          ) {
            temp.push(data);
          }
        });
      } else {
        temp = this.events;
      }
      this.names.forEach(filter => {
        temp = temp.filter(data => {
          if (this.nav === "users" && data.extendedProps.doctor === filter) {
            return true;
          } else if (
            this.nav === "rooms" &&
            data.extendedProps.room === filter
          ) {
            return true;
          } else if (
            this.nav === "equipment" &&
            data.extendedProps.equipment === filter
          ) {
            return true;
          }
          return false;
        });
      });
      return temp;
    }
  },
  setup() {
    const $vfm = inject("$vfm");

    const dragableOptions = {
      itemSelector: ".event",
      eventData(eventEl) {
        return {
          title: cash(eventEl)
            .find(".event__title")
            .html(),
          duration: {
            days: parseInt(
              cash(eventEl)
                .find(".event__days")
                .text()
            )
          }
        };
      }
    };

    return {
      dragableOptions
    };
  }
});
</script>
