<template>
  <div
    v-if="!patientCompleted"
    class="w-full relative text-gray-700 dark:text-gray-300 sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0"
  >
    <SearchIcon
      style="color: grey; margin-left: 10px"
      class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
    />
    <Field
      name="patient"
      autocomplete="off"
      class="form-control"
      v-model="patientName"
      @input="onChange"
      rules="lol"
      placeholder="Full Name"
    />
    <ul v-show="isOpen" class="autocomplete-results">
      <li
        v-for="(patient, i) in patients"
        :key="i"
        @click="setPatient(patient)"
        class="autocomplete-result"
      >
        {{ patient.name + " - " + patient.dob }}
      </li>
    </ul>
    <ErrorMessage name="patient" as="span" class="text-red-500"
      >Please enter full name!</ErrorMessage
    >
  </div>
  <div v-else class="border-2 border-black dark:border-white relative">
    <XCircleIcon
      class="block right-0 absolute pb-1 cursor-pointer"
      @click="$emit('clear', 'patient')"
    />
    <div class="p-4">
      <div class="flex justify-between">
        <div>Patient Name</div>
        <div>{{ patient.name }}</div>
      </div>
      <div class="flex justify-between">
        <div>D.O.B</div>
        <div>{{ patient.dob }}</div>
      </div>
      <div class="flex justify-between">
        <div>Patient ID</div>
        <div>94052</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { faker } from "@/utils/faker";

defineRule("lol", value => {
  if (value === null || value === undefined) {
    return true;
  } else if (!value || !value.length) {
    return "This field is required";
  }

  return true;
});

export default {
  props: ["patientCompleted", "patient"],
  data() {
    return {
      patients: [],
      patientName: "",
      isOpen: false
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  components: {
    Field,
    ErrorMessage
  },
  methods: {
    filterResults() {
      this.patients = faker[0].users.filter(
        item =>
          item.name.toLowerCase().indexOf(this.patientName.toLowerCase()) > -1
      );
    },
    onChange(event) {
      if (event.target.value == "") {
        this.isOpen = false;
        this.patients = [];
        return;
      }
      this.filterResults();
      if (this.patients.length === 0) {
        this.isOpen = false;
      } else {
        this.isOpen = true;
      }
    },
    setPatient(patient) {
      this.patientName = patient.name;
      this.isOpen = false;
      this.$emit("set-patient", patient);
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    }
  }
};
</script>
