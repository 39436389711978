<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="intro-y text-lg font-medium mr-auto">Add New Template</h2>
    </div>
    <!-- BEGIN: Wizard Layout -->
    <div class="intro-y box py-10 sm:py-10 mt-5">
      <div class="wizard lg:flex flex-row justify-center px-5 sm:px-20">
        <div
          class="intro-x lg:text-center flex items-center lg:block flex-1 z-10"
        >
          <button
            class="w-10 h-10 rounded-full btn"
            :class="
              nav === 'home'
                ? 'btn btn-primary'
                : 'text-gray-600 bg-gray-200 dark:bg-dark-1'
            "
          >
            1
          </button>
          <div
            class="lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto"
            :class="nav === 'home' && 'font-medium'"
          >
            Select Fields
          </div>
        </div>
        <div
          class="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10"
        >
          <button
            class="w-10 h-10 rounded-full btn"
            :class="
              nav === 'next'
                ? 'btn btn-primary'
                : 'text-gray-600 bg-gray-200 dark:bg-dark-1'
            "
          >
            2
          </button>
          <div
            class="lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto text-gray-700 dark:text-gray-600"
            :class="nav === 'next' && 'font-medium'"
          >
            Prepopulate Fields
          </div>
        </div>
        <div
          class="wizard__line hidden lg:block w-1/3 bg-gray-200 dark:bg-dark-1 absolute mt-5"
        ></div>
      </div>
      <div
        class="px-5 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5"
        v-show="nav === 'home'"
      >
        <Form @submit="nextWizard">
          <span>Select Template Name: </span>
          <Field
            name="name"
            autocomplete="off"
            class="form-control"
            v-model="name"
            rules="required"
            placeholder="Enter Name..."
          />
          <ErrorMessage name="name" as="span" class="text-red-500"
            >Please enter a valid template name!</ErrorMessage
          >
          <br />
          <br />
          <span>Select Template Description (Optional): </span>
          <Field
            name="description"
            class="form-control"
            v-model="description"
            placeholder="Enter Description..."
          />
          <br />
          <br />
          <div class="font-medium text-base">Select Fields to prepopulate</div>
          <div>
            <div
              class="form-check mt-4"
              v-for="(field, key) in Object.keys(fields)"
              :key="key"
            >
              <input
                class="form-check-input"
                type="checkbox"
                v-model="fields[field]"
              />
              <div class="flex justify-between w-full">
                <div
                  class="form-check-label capitalize mr-10"
                  for="checkbox-switch-1"
                >
                  {{ field }}
                </div>
                <div class="text-gray-600">
                  {{ checkboxDescription(field) }}
                </div>
              </div>
            </div>
          </div>
          <div v-show="check === true" class="text-red-500 text-center mt-5">
            Please select at least one field to create a valid template
          </div>
          <div
            class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-10 px-5"
            v-show="nav === 'home'"
          >
            <button class="btn btn-primary w-24 ml-2" type="submit">
              Next
            </button>
          </div>
        </Form>
      </div>
      <div
        v-show="nav === 'next'"
        class="px-5 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5"
      >
        <div class="font-medium text-base mb-10">
          Prepopulate the following fields:
        </div>
        <Form @submit="submit" autocomplete="off">
          <div v-if="fields.title === true">
            <span>Select Title: </span>
            <Field
              name="title"
              class="form-control"
              v-model="title"
              rules="lol"
              placeholder="Enter Title..."
            />
            <ErrorMessage name="title" as="span" class="text-red-500"
              >Please enter a valid title!</ErrorMessage
            >
            <br />
            <br />
          </div>
          <div v-if="fields.doctor === true">
            <span>Select Doctor: </span>
            <DoctorAutocomplete
              @set-doctor="setDoctor"
              :doctor="doctor"
              :doctorCompleted="doctorCompleted"
            />
            <br />
            <br />
          </div>
          <div v-if="fields.room === true">
            <span>Select Room: </span>
            <RoomAutocomplete
              @set-room="setRoom"
              :room="room"
              :error="true"
              :roomCompleted="roomCompleted"
            />
            <br />
            <br />
          </div>
          <div v-if="fields.equipment === true">
            <span>Select Equipment: </span>
            <EquipmentAutocomplete
              @set-equipment="setEquipment"
              :equipment="equipment"
              :equipmentCompleted="equipmentCompleted"
              :error="true"
            />
            <br />
            <br />
          </div>
          <div v-if="fields.date === true">
            <span>Select Start Date: </span>
            <Litepicker
              v-model="date"
              :options="{
                autoApply: false,
                showWeekNumbers: true,
                dropdowns: {
                  minYear: 1990,
                  maxYear: null,
                  months: true,
                  years: true
                }
              }"
              class="form-control w-full block"
            />
            <br />
            <br />
          </div>

          <div v-if="fields.time === true">
            <span>Select Time: </span>
            <div
              class="w-full relative text-gray-700 dark:text-gray-300 sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0"
            >
              <Field
                name="time"
                class="form-control"
                type="time"
                v-model="time"
                placeholder="Time"
                rules="required"
              />
              <ErrorMessage name="time" as="span" class="text-red-500"
                >Please enter a valid time!</ErrorMessage
              >
            </div>
            <br />
            <br />
          </div>

          <div v-if="fields.reason === true">
            <span>Reason For Visit:</span><br />
            <input
              style="margin-right: 10px"
              class="form-control w-full"
              type="text"
              placeholder="E.g. Chest Pains, Fever, etc"
              v-model="reason"
            />
            <br />
            <br />
          </div>

          <div v-if="fields.comment === true">
            <span>Additional Comments:</span><br />
            <textarea
              overflow-y:scroll
              style="margin-right: 10px"
              class="form-control w-full"
              placeholder="E.g. Chest Pains, Fever, etc"
              v-model="comment"
            ></textarea>
          </div>
          <div>Add Fields:</div>
          <div class="dropdown ml-auto sm:ml-0">
            <div
              class="dropdown-toggle btn px-2 bg-blue-800 box text-white dark:bg-black mr-5"
              aria-expanded="false"
            >
              <span class="w-5 h-5 flex items-center justify-center">
                <PlusIcon class="w-4 h-4" />
              </span>
            </div>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <div
                  class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer"
                  @click="addCustomField('text')"
                >
                  TextField
                </div>
                <div
                  class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer"
                  @click="addCustomField('checkbox')"
                >
                  Checkbox
                </div>
              </div>
            </div>
          </div>
          <div v-for="(field, key) in customFields" :key="key">
            <div class="flex  items-center  mt-5">
              <Field
                :name="String(key)"
                class="form-control block flex-1 mr-5"
                type="text"
                rules="required"
                v-model="field.label"
                :placeholder="
                  field.type === 'text'
                    ? 'Enter Textfield label'
                    : 'Enter Checkbox label'
                "
              />
              <DeleteIcon
                class="text-red-500 cursor-pointer"
                @click="customFields.splice(key, 1)"
              />
            </div>
            <ErrorMessage :name="String(key)" as="span" class="text-red-500"
              >Please enter a name for your custom field!</ErrorMessage
            >
          </div>
          <input
            type="submit"
            class="btn btn-primary mt-5 block m-auto"
            value="Confirm"
          />
        </Form>
      </div>
    </div>
    <!-- END: Wizard Layout -->
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import PatientAutocomplete from "../calendar/PatientAutocomplete.vue";
import DoctorAutocomplete from "../calendar/DoctorAutocomplete.vue";
import RoomAutocomplete from "../calendar/RoomAutocomplete.vue";
import EquipmentAutocomplete from "../calendar/EquipmentAutocomplete.vue";
import { faker } from "@/utils/faker";

defineRule("lol", value => {
  if (value === null) {
    return true;
  } else if (!value || !value.length) {
    return "This field is required";
  }
  return true;
});

export default {
  data() {
    return {
      fields: {
        title: false,
        doctor: false,
        room: false,
        equipment: false,
        reason: false,
        comment: false
      },
      name: "",
      description: "",
      title: "",
      doctor: "",
      room: "",
      equipment: "",
      reason: "",
      comment: "",
      nav: "home",
      check: false,
      customFields: [],
      doctorCompleted: false,
      equipmentCompleted: false,
      roomCompleted: false
    };
  },
  components: {
    RoomAutocomplete,
    EquipmentAutocomplete,
    Form,
    Field,
    ErrorMessage,
    PatientAutocomplete,
    DoctorAutocomplete
  },
  methods: {
    checkboxDescription(text) {
      switch (text) {
        case "title":
          return "Title of appointment";
        case "patient":
          return "Information of a particular patient";
        case "doctor":
          return "Information of a particular doctor";
        case "room":
          return "Information of a particular room";
        case "equipment":
          return "Information of a particular equipment";
        case "date":
          return "Date of appointment";
        case "time":
          return "Time of appointment";
        case "reason":
          return "Reason for appointment";
        case "comment":
          return "Comments about the appointment";
        default:
          break;
      }
    },
    nextWizard() {
      let check = Object.keys(this.fields).every(k => !this.fields[k]);
      if (check === true) {
        this.check = true;
      } else {
        this.check = false;
        this.nav = "next";
      }
    },
    setPatient(data) {
      this.patient = data;
      this.patientCompleted = true;
    },
    setDoctor(data) {
      this.doctor = data;
      this.doctorCompleted = true;
    },
    setRoom(data) {
      this.room = data;
      this.roomCompleted = true;
    },
    setEquipment(data) {
      this.equipment = data;
      this.equipmentCompleted = true;
    },
    cross() {
      this.patientCompleted = false;
    },
    addCustomField(type) {
      this.customFields.push({ label: "", type: type });
    },
    removeCustomField() {},
    submit() {
      const fields = {
        name: this.name,
        description: this.description,
        doctor: this.doctor,
        title: this.title,
        equipment: this.equipment,
        room: this.room,
        reason: this.reason,
        comment: this.comment
      };
      let nonEmptyFields = Object.fromEntries(
        Object.entries(fields).filter(([_, v]) => v !== "")
      );
      if (this.customFields.length > 0) {
        nonEmptyFields["customFields"] = this.customFields;
      }
      faker[0].templates.push(nonEmptyFields);
      this.$emit("add-template", nonEmptyFields);
    }
  }
};
</script>

<style scoped>
input + span,
select + span {
  display: block;
  color: red;
}
</style>
