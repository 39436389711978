<template>
  <div class="modal" id="exampleModalCenter7" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            New Medical History
          </h5>
        </div>
        <div class="p-12">
          <Form @submit="submit">
            <span>Disease: </span>
            <Field
              name="disease"
              rules="required"
              placeholder="Enter disease name"
              class="form-control"
            />
            <ErrorMessage name="disease" as="span"
              >Please enter a disease!</ErrorMessage
            >
            <br />
            <span>Vaccine Name: </span>
            <Field
              name="type"
              rules="required"
              placeholder="Enter vaccine name"
              class="form-control"
            />
            <ErrorMessage name="type" as="span"
              >Please enter a vaccine!</ErrorMessage
            >
            <br />
            <span>Date of immunization: </span>
            <Litepicker
              format="DD-MM-YYYY"
              v-model="date"
              :options="{
                autoApply: false,
                dropdowns: {
                  minYear: 1990,
                  maxYear: null,
                  months: true,
                  years: true
                }
              }"
              class="form-control"
            />
            <div class="grid grid-cols-4 gap-4 mt-3">
              <input
                type="submit"
                class="btn btn-primary col-start-2 col-span-1"
                value="Submit"
                data-dismiss="modal"
              />
              <input
                class="btn btn-secondary col-start-3 col-span-1"
                value="Close"
                data-dismiss="modal"
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { required, email, numeric } from "@vee-validate/rules";

defineRule("required", required);
defineRule("email", email);
defineRule("numeric", numeric);

export default {
  data() {
    return {
      date: ""
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage
  },
  methods: {
    submit(value) {
      const data = {
        disease: value.disease,
        type: value.type,
        date: this.date.split("-")[1] + " " + this.date.split("-")[2]
      };
      this.$emit("add-immunization-record", data);
    }
  }
};
</script>

<style scoped>
input + span,
select + span {
  display: block;
  color: red;
}
</style>
