<template>
  <div class="box shadow rounded px-3 pt-3 pb-5 border">
    <div class="flex justify-between">
      <p class="font-semibold font-sans tracking-wide text-sm">
        {{ task.title }}
      </p>

      <img
        class="w-6 h-6 rounded-full ml-3"
        src="https://pickaface.net/gallery/avatar/unr_sample_161118_2054_ynlrg.png"
        alt="Avatar"
      />
    </div>
    <div class="flex mt-4 justify-between items-center">
      <span class="text-sm">{{ task.date }}</span>
      <Badge v-if="task.type" :color="badgeColor">{{ task.type }}</Badge>
    </div>
  </div>
</template>
<script>
import Badge from "./Badge.vue";
export default {
  components: {
    Badge
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    badgeColor() {
      const mappings = {
        "Type 1": "purple",
        "Type 2": "orange",
        "Type 3": "blue",
        "Type 4": "green",
        default: "teal"
      };
      return mappings[this.task.type] || mappings.default;
    }
  }
};
</script>
