<template>
  <div>
    <div class=" intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Rooms</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <a
          type="button"
          class="btn btn-primary shadow-md mr-2"
          data-toggle="modal"
          data-target="#exampleModalCenter5"
          >Add New Rooms</a
        >
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class=" intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="flex mt-5 sm:mt-0">
          <button
            id="tabulator-print"
            class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2" /> Print
          </button>
          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-csv"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onExportCsv"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                </a>
                <a
                  id="tabulator-export-json"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onExportJson"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                </a>
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                </a>
                <a
                  id="tabulator-export-html"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onExportHtml"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export HTML
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="table"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <AddRoomModal @add-room="addRoom" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import xlsx from "xlsx";
import Tabulator from "tabulator-tables";
import { faker } from "../../utils/faker";
import AddRoomModal from "./AddRoomModal.vue";

export default defineComponent({
  components: {
    AddRoomModal,
  },
  props: ["deleted"],
  data: function () {
    return {
      tabulator: null, //variable to hold your table
      tableData: [], //data for table to display
      roomId: "",
    };
  },
  watch: {
    // update table if data changes
    location: {
      handler: function (newData) {
        this.tabulator.replaceData(newData);
      },
      deep: true,
    },
  },
  created() {
    this.tableData = faker[0].rooms;
  },
  mounted() {
    var self = this;
    //instantiate Tabulator when element is mounted
    this.tabulator = new Tabulator(this.$refs.table, {
      data: this.tableData, //link data to table
      reactiveData: false, //enable data reactivity
      printAsHtml: true,
      printStyled: true,
      // pagination: "remote",
      pagination: "local",
      paginationSize: 20,
      paginationSizeSelector: [10, 20, 30, 40],
      layout: "fitColumns",
      responsiveLayout: "collapse",
      placeholder: "No matching records found",
      columns: [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: true,
          headerSort: false,
        },
        // For HTML table
        {
          title: "Room Number",
          minWidth: 100,
          responsive: 0,
          field: "name",
          vertAlign: "middle",
          print: false,
          download: false,
          headerFilter: true,
        },
        {
          title: "Category",
          minWidth: 100,
          field: "category",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerFilter: true,
        },
        {
          title: "Branch",
          minWidth: 150,
          field: "branch.name",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerFilter: true,
        },
        {
          title: "Capactiy",
          minWidth: 50,
          field: "capacity",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
        },
        {
          title: "Status",
          minWidth: 50,
          field: "status",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            return `<div class="flex items-center lg:justify-center ${
              cell.getData().status ? "text-theme-10" : "text-theme-24"
            }">
                <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                  cell.getData().status ? "Active" : "Inactive"
                }
              </div>`;
          },
        },
        // For print format
        {
          title: "ROOM NUMBER",
          field: "name",
          visible: false,
          print: true,
          download: true,
        },
        {
          title: "CATEGORY",
          field: "category",
          visible: false,
          print: true,
          download: true,
        },
        {
          title: "CAPACITY",
          field: "capacity",
          visible: false,
          print: true,
          download: true,
        },
        {
          title: "STATUS",
          field: "status",
          visible: false,
          print: true,
          download: true,
          formatterPrint(cell) {
            return cell.getValue() ? "Active" : "Inactive";
          },
        },
        {
          title: "IMAGE 1",
          field: "images",
          visible: false,
          print: true,
          download: true,
          formatterPrint(cell) {
            return cell.getValue()[0];
          },
        },
        {
          title: "IMAGE 2",
          field: "images",
          visible: false,
          print: true,
          download: true,
          formatterPrint(cell) {
            return cell.getValue()[1];
          },
        },
        {
          title: "IMAGE 3",
          field: "images",
          visible: false,
          print: true,
          download: true,
          formatterPrint(cell) {
            return cell.getValue()[2];
          },
        },
      ],
      rowClick: function (e, row) {
        let data = row.getData();
        console.log(data);
        self.$router.push({
          name: "side-menu-room-overview",
          params: { room: JSON.stringify(data), roomid: data.id },
        });
      },
    });
  },
  methods: {
    addRoom(data) {
      const lastitem = faker[0].rooms[faker[0].rooms.length - 1].id;
      const last = lastitem + 1;
      faker[0].rooms.push({
        id: last,
        image: "food-beverage-1.jpg",
        ...data,
      });
      const newRoom = faker[0].rooms.filter((data) => data.id === last);
      this.$router.push({
        name: "side-menu-room-overview",
        params: { room: JSON.stringify(newRoom[0]), roomid: last }
      });
    },
    onExportCsv() {
      this.tabulator.download("csv", "data.csv");
    },

    onExportJson() {
      this.tabulator.download("json", "data.json");
    },

    onExportXlsx() {
      const win = window;
      win.XLSX = xlsx;
      this.tabulator.download("xlsx", "data.xlsx", {
        sheetName: "Products",
      });
    },

    onExportHtml() {
      this.tabulator.download("html", "data.html", {
        style: true,
      });
    },

    // Print
    onPrint() {
      this.tabulator.print();
    },
  },
});
</script>
