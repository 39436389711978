<template>
  <!-- Modal -->
  <div class="modal fade" id="exampleModalCenter2">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Profile</h5>
        </div>
        <div class="modal-body">
          <ul>
            <li>Name: {{ modalData.name }}</li>
            <li>Gender: {{ modalData.dob }}</li>
            <li>Email address: {{ modalData.email }}</li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["modalData"]
};
</script>
