<template>
  <div class="modal" id="exampleModalCenter4" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            New Doctor Consultation
          </h5>
        </div>
        <div class="p-12">
          <Form @submit="submit">
            <span>Name of Doctor Consulted: </span>
            <Field
              name="name"
              rules="required"
              placeholder="Enter name of doctor consulted"
              class="form-control"
              style="margin-top: 10px"
            />
            <ErrorMessage name="name" />
            <br />
            <br />
            <span>Specialization of Doctor: </span>
            <Field
              name="specialization"
              rules="required"
              placeholder="Enter specialization of doctor consulted"
              class="form-control"
              style="margin-top: 10px"
            />
            <ErrorMessage name="specialization" />
            <br />
            <br />
            <label for="date">Date of consultation: </label>
            <Field
              name="date"
              rules="required"
              style="margin-top: 10px"
              type="month"
              class="form-control"
            />
            <ErrorMessage name="date" />
            <br />
            <br />
            <span>Reason for consultation: </span>
            <Field
              name="reason"
              rules="required"
              placeholder="Enter reason for consultation"
              class="form-control"
              style="margin-top: 10px"
            />
            <ErrorMessage name="reason" />
            <br />
            <br />
            <span>Medicine prescribed: </span>
            <Field
              name="medicine"
              rules="required"
              placeholder="Enter name of medicine prescribed"
              class="form-control"
              style="margin-top: 10px"
            />
            <ErrorMessage name="medicine" />
            <div class="grid grid-cols-4 gap-4 mt-3">
              <input
                type="submit"
                class="btn btn-primary col-start-2 col-span-1"
                value="Submit"
                data-dismiss="modal"
              />
              <input
                class="btn btn-secondary col-start-3 col-span-1"
                value="Close"
                data-dismiss="modal"
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { required, email, numeric } from "@vee-validate/rules";

defineRule("required", required);
defineRule("email", email);
defineRule("numeric", numeric);
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
export default {
  components: {
    Form,
    Field,
    ErrorMessage
  },
  methods: {
    submit(value) {
      const d = new Date(value.date);
      const data = {
        doc: value.name,
        spec: value.specialization,
        date: monthNames[d.getMonth()] + " " + d.getFullYear(),
        reason: value.reason,
        medicine: value.medicine
      };
      this.$emit("add-doctor-consultations", data);
    }
  }
};
</script>

<style scoped>
input + span,
select + span {
  display: block;
  color: red;
}
</style>
