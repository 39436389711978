<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Manage Location</h2>
    </div>
    <!-- BEGIN: Profile Info -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        class="
          flex flex-col
          lg:flex-row
          border-b border-gray-200
          dark:border-dark-5
          pb-5
          -mx-5
        "
      >
        <div
          class="flex flex-1 px-5 items-center justify-center lg:justify-start"
        >
          <div
            class="
              w-20
              h-20
              sm:w-24 sm:h-24
              flex-none
              lg:w-32 lg:h-32
              image-fit
              relative
            "
          >
            <img
              alt="Mazecare Admin Demo 1"
              class="rounded-full"
              :src="require(`@/assets/images/${$f()[0].photos[0]}`)"
            />
            <div
              class="
                absolute
                mb-1
                mr-1
                flex
                items-center
                justify-center
                bottom-0
                right-0
                bg-theme-17
                rounded-full
                p-2
              "
            >
              <CameraIcon class="w-4 h-4 text-white" />
            </div>
          </div>
          <div class="ml-5">
            <div
              class="
                w-24
                sm:w-40
                truncate
                sm:whitespace-normal
                font-medium
                text-lg
              "
            >
              {{ branchInfo.name }}
            </div>
            <div class="text-gray-600">Branch ID: {{ branchName }}</div>
          </div>
        </div>
        <div
          class="
            mt-6
            lg:mt-0
            flex-1
            dark:text-gray-300
            px-5
            border-l border-r border-gray-200
            dark:border-dark-5
            border-t
            lg:border-t-0
            pt-5
            lg:pt-0
          "
        >
          <div class="font-medium text-center lg:text-left lg:mt-3">
            Contact Details
          </div>
          <div
            class="
              flex flex-col
              justify-center
              items-center
              lg:items-start
              mt-4
            "
          >
            <div class="truncate sm:whitespace-normal flex items-center">
              <MailIcon class="w-4 h-4 mr-2" />
              {{ branchInfo.email }}
            </div>
            <div class="truncate sm:whitespace-normal flex items-center">
              <MailIcon class="w-4 h-4 mr-2" />
              {{
                branchInfo.address.address1 +
                "," +
                branchInfo.address.address2 +
                "," +
                branchInfo.address.city
              }}
            </div>
            <div class="truncate sm:whitespace-normal flex items-center">
              <VoicemailIcon class="w-4 h-4 mr-2" />
              {{ branchInfo.contact }}
            </div>
            <div>
              <button
                class="btn btn-sm btn-primary w-24 mr-1 mb-2"
                @click="removeBranch(branchInfo)"
              >
                Delete
              </button>
              <a
                class="btn btn-sm btn-primary w-24 mr-1 mb-2"
                @click="editBranch(branchInfo)"
              >
                Edit
              </a>
            </div>
          </div>
        </div>
        <!-- <div
          class="
            mt-6
            lg:mt-0
            flex-1
            px-5
            border-t
            lg:border-0
            border-gray-200
            dark:border-dark-5
            pt-5
            lg:pt-0
            text-center
          "
        >
          <div class="font-medium lg:text-left lg:mt-3">
            Branch Stats
          </div>
          <br />
          <div class="truncate sm:whitespace-normal flex items-center">
            Total Patients:
          </div>
          <div class="truncate sm:whitespace-normal flex items-center">
            Revenue:
          </div>
          <div class="truncate sm:whitespace-normal flex items-center">

          </div>
          <div class="truncate sm:whitespace-normal flex items-center"></div>
        </div> -->
      </div>
      <div
        class="
          nav nav-tabs
          flex-col
          sm:flex-row
          justify-center
          lg:justify-start
        "
      >
        <div
          class="py-4 sm:mr-8 cursor-pointer nav-item nav-link"
          :class="{ active: anchor === 'overview' }"
          @click="setAnchor('overview')"
        >
          Overview
        </div>
        <div
          class="py-4 sm:mr-8 cursor-pointer nav-item nav-link"
          :class="{ active: anchor === 'rooms' }"
          @click="setAnchor('rooms')"
        >
          Rooms
        </div>
        <div
          class="py-4 sm:mr-8 cursor-pointer nav-item nav-link"
          :class="{ active: anchor === 'equipments' }"
          @click="setAnchor('equipments')"
        >
          Equipments
        </div>
        <div
          class="py-4 sm:mr-8 cursor-pointer nav-item nav-links"
          :class="{ active: anchor === 'inventory' }"
          @click="setAnchor('inventory')"
        >
          Inventory
        </div>
      </div>
    </div>
    <!-- END: Profile Info -->
    <div class="intro-y tab-content mt-5">
      <div v-if="anchor === 'overview'">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Top Categories -->
          <div class="intro-y box col-span-12 lg:col-span-6">
            <div
              class="
                flex
                items-center
                p-5
                border-b border-gray-200
                dark:border-dark-5
              "
            >
              <h2 class="font-medium text-base mr-auto">Practice Hours</h2>
              <div class="ml-auto">
                <a
                  class="btn btn-sm btn-primary w-24 mr-1 mb-2"
                  data-toggle="modal"
                  data-target="#exampleModalCenter1"
                >
                  Add / Edit
                </a>
              </div>
            </div>
            <div class="p-5">
              <div class="flex flex-col">
                <div class="flex flex-row justify-between">
                  <div class="font-medium text-center">Day</div>
                  <div class="font-medium text-center">Hours</div>
                </div>
                <div
                  v-for="(hour, index) in branchInfo.hours"
                  :key="index"
                  class="
                    flex flex-row
                    justify-between
                    border-b border-gray-200
                    dark:border-dark-5
                    items-center
                    py-2
                  "
                >
                  <div>{{ hour.name }}</div>
                  <div v-if="hour.isOpen">
                    <div v-if="hour.is24 === true" class="flex flex-col">
                      24 Hours
                    </div>
                    <div v-else class="flex flex-col">
                      <div v-for="regular in hour.regularhours" :key="regular">
                        <div>{{ regular.open }} until {{ regular.close }}</div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div>Closed</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                flex
                items-center
                p-5
                border-b border-gray-200
                dark:border-dark-5
              "
            >
              <h2 class="font-medium text-base mr-auto">Special Hours</h2>
              <div class="ml-auto">
                <a
                  class="btn btn-sm btn-primary w-24 mr-1 mb-2"
                  data-toggle="modal"
                  data-target="#exampleModalCenter15"
                >
                  Add / Edit
                </a>
              </div>
            </div>
            <div class="p-5">
              <div class="flex flex-col">
                <div class="flex flex-row justify-between">
                  <div class="font-medium text-center">Day</div>
                  <div class="font-medium text-center">Hours</div>
                </div>
                <div
                  v-for="(hour, index) in branchInfo.specialhours"
                  :key="index"
                  class="
                    flex flex-row
                    justify-between
                    border-b border-gray-200
                    dark:border-dark-5
                    items-center
                    py-2
                  "
                >
                  <div>{{ hour.day }}</div>
                  <div v-if="hour.isOpen">
                    <div v-if="hour.is24 === true" class="flex flex-col">
                      24 Hours
                    </div>
                    <div v-else class="flex flex-col">
                      <div v-for="special in hour.hours" :key="special">
                        <div>{{ special.open }} until {{ special.close }}</div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div>Closed</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-12 lg:col-span-6">
            <div class="intro-y box p-5">
              <div class="intro-y block sm:flex items-center h-10">
                <h2 class="text-lg font-medium truncate mr-5">
                  Branch Locator
                </h2>
              </div>
              <ReportMap class="report-maps mt-5 bg-gray-200 rounded-md" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="anchor === 'rooms'">
        <BranchRooms :branch-data="branchName" />
      </div>
      <div v-if="anchor === 'equipments'">
        <BranchEquipments />
      </div>
      <div v-if="anchor === 'inventory'">
        <BranchInventory />
      </div>
    </div>

    <EditBranchModal :branch-data="branch" @update-branch="updateBranch" />
    <EditBranchHoursModal
      :branch-info="branchInfo"
      @update-branchhours="updateBranchHours"
    />
    <EditSpecialHoursModal
      :branch-info="branchInfo"
      @update-branchspecialhours="updateBranchSpecialHours"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { faker } from "@/utils/faker";
import { defineRule } from "vee-validate";
import { required, email, numeric } from "@vee-validate/rules";
import EditBranchModal from "./EditBranchModal.vue";
import EditBranchHoursModal from "./EditBranchHoursModal.vue";
import BranchRooms from "./BranchRooms.vue";
import BranchEquipments from "./BranchEquipments.vue";
import BranchInventory from "./BranchInventory.vue";
import ReportMap from "@/components/report-map/Main.vue";
import EditSpecialHoursModal from "./EditSpecialHoursModal.vue";

defineRule("required", required);
defineRule("email", email);
defineRule("numeric", numeric);

export default defineComponent({
  components: {
    EditBranchModal,
    BranchRooms,
    BranchEquipments,
    BranchInventory,
    ReportMap,
    EditBranchHoursModal,
    EditSpecialHoursModal,
  },
  props: ["id"],
  data() {
    return {
      anchor: "overview",
      branches: [],
      branchName: [],
      branchInfo: [
        {
          name: "",
          email: "",
          contact: "",
          address: {
            address1: "",
            address2: "",
            city: "",
          },
          hours: [],
          specialhours: [],
        },
      ],
      branch: {
        name: null,
        email: null,
        address: {
          address1: null,
          address2: null,
          city: null,
        },
        contact: null,
        hours: null,
      },
      data: [],
      search: "",
      selectedBranch: null,
      isEditing: false,
    };
  },
  watch: {
    getUpdatedInfo() {
      this.branchInfo = this.branchInfo = faker[0].branchDetails.filter(
        (data) => data.id === this.branchName
      )[0];
    },
  },

  created() {
    if (this.id !== undefined) {
      this.branchName = JSON.parse(this.id);
      this.branchInfo = faker[0].branchDetails.filter(
        (data) => data.id === this.branchName
      )[0];
    } else {
      this.branchInfo = undefined;
    }
  },
  methods: {
    setAnchor(value) {
      this.anchor = value;
    },
    getHeight(h) {
      this.height = 3.2 * h;
      document.getElementById("height here").innerHTML = this.height;
    },
    removeBranch(branchInfo) {
      let obj = faker[0].branches.findIndex(
        (data) => data.name === branchInfo.name
      );
      faker[0].branchDetails.splice(obj, 1);
      faker[0].branches.splice(obj, 1);
      this.$router.push({
        name: "side-menu-branch",
      });
    },
    editBranch(branchInfo) {
      this.branch = branchInfo;
      cash("#exampleModalCenter3").modal("show");
      this.selectedBranch = branchInfo;
      this.isEditing = true;
    },
    updateBranch(data) {
      let obj = faker[0].branchDetails.findIndex(
        (data) => data.name === this.branchInfo.name
      );
      this.branchInfo.name = data.name;
      this.branchInfo.email = data.email;
      this.branchInfo.address = data.address;
      this.branchInfo.contact = data.contact;
      faker[0].branchDetails[obj].address = data.address;
      faker[0].branchDetails[obj].name = data.name;
      faker[0].branchDetails[obj].email = data.email;
      faker[0].branchDetails[obj].contact = data.contact;
      faker[0].branches[obj].name = data.name;
      faker[0].branches[obj].email = data.email;
      this.isEditing = false;
    },
    updateBranchHours(data) {
      let obj = faker[0].branchDetails.findIndex(
        (data) => data.name === this.branchInfo.name
      );
      this.branchInfo.hours = data;
      faker[0].branchDetails[obj].hours = data;
    },
    updateBranchSpecialHours(data) {
      let obj = faker[0].branchDetails.findIndex(
        (data) => data.name === this.branchInfo.name
      );
      this.branchInfo.specialhours.push(data);
    },
  },
});
</script>

<style scoped>
input + span,
select + span {
  display: block;
  color: red;
}
</style>
