<template>
  <!-- Modal -->
  <div id="exampleModalCenter6" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLongTitle" class="modal-title">Add Equpiment</h5>
        </div>
        <div class="p-6">
          <Form @submit="onSubmit">
            <span>Equipment Name: </span>
            <Field
              v-model="name"
              name="name"
              rules="lol"
              placeholder="enter equipment name"
              class="form-control"
            />
            <ErrorMessage name="name" />
            <br />
            <span>Stock: </span>
            <Field
              v-model="remaining_stock"
              name="remaining_stock"
              rules="lol"
              type="number"
              placeholder="enter stock"
              class="form-control"
            />
            <ErrorMessage name="remaining_stock" />
            <br>
            <span>Category: </span>
            <Field
              v-model="category"
              name="category"
              rules="lol"
              placeholder="enter category"
              class="form-control"
            />
            <ErrorMessage name="category" />
            <br />
            <span>Images: </span>
            <Field
              v-model="images"
              name="images"
              rules="lol"
              placeholder="enter images"
              class="form-control"
            />
            <ErrorMessage name="images" />
            <br />
            <span>Status </span>
            <Field
              v-model="status"
              name="status"
              type="number"
              rules="lol"
              placeholder="enter status"
              class="form-control"
            />
            <ErrorMessage name="status" />
            <br />
            <input
              style="
                width: 75px;
                margin-left: 115px;
                margin-right: 10px;
                margin-top: 15px;
              "
              type="submit"
              class="btn btn-primary"
              data-dismiss="modal"
              value="Submit"
            />
            <input
              style="width: 75px; margin-top: 15px"
              class="btn btn-secondary"
              value="Close"
              data-dismiss="modal"
              @click="close"
            />
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { numeric } from "@vee-validate/rules";

defineRule("lol", (value) => {
  if (value === null || value === undefined) {
    return true;
  } else if (!value || !value.length) {
    return false;
  }

  return true;
});
defineRule("numeric", numeric);

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      name: "",
      images: "",
      category: "",
      capacity: "",
      remaining_stock: "",
      status: ""
    };
  },
  methods: {
    close() {
      this.name = null;
      this.images = null;
      this.category = null;
      this.status = null;
      this.remaining_stock = null;
    },
    onSubmit() {
      let data = {
        name: this.name,
        images: [this.images, this.images, this.images],
        status: this.status,
        category: this.category,
        remaining_stock: this.remaining_stock
      };
      this.close();
      this.$emit("add-equipment", data);
    },
  },
};
</script>

<style scoped>
input + span,
select + span {
  display: block;
  color: red;
}
</style>
