<template>
  <!-- Modal -->
  <div class="modal fade" id="tagsModalCenter">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">DICOM Tags</h5>
        </div>
          <table class="table mt-5 ">
            <tbody>
              <tr v-for="item in modalData" :key="item.name">
                <th class="border">{{ item.name }}</th>
                <td class="border">{{ item.value }}</td>
              </tr>
            </tbody>
          </table>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["modalData"]
};
</script>
<style scoped>
/* .modal .modal-dialog .modal-content {
    width: max-content;
} */
table {
    width: 100%;
}

th {
    text-align: right;
    width: 30%;
    min-width:133px; /* change the value for whatever fits you better */
    max-width: 300px;
    word-wrap:break-word    
}td {
    text-align: left;
    width:70%;
    min-width:133px; /* change the value for whatever fits you better */
    max-width: 300px;
    word-break: break-all;
}
</style>
